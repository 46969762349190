import React, { useEffect, useRef, useState, MouseEvent, TouchEvent } from 'react';
import { useTranslation } from 'react-i18next';
import FocusTrap from "focus-trap-react";
import { faEraser, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextPopUp from "../../helpers/textPopUp";
import { styleButton1, stylePopUp } from '../../helpers/stylesComponents';

function Caligraphie(props: any): JSX.Element {

  const { t } = useTranslation();
  const content = props;

  const valueXConvert = (window.innerWidth / 100) * 39;
  const valueYConvert = (window.innerHeight / 100) * 51.5;

  const canvasRef = useRef<any>(null);
  const ctx = useRef<any>(null);

  const [popup, setPopup] = useState<boolean>(false);

  const [coords, setCoords] = useState({ x: 0, y: 0 });

  const [mouseDown, setMouseDown] = useState(false);

  const [touchDown, setTouchDown] = useState(false);

  const fakeX = coords.x + Math.floor(Math.random() * 20 - 10);
  const fakeY = coords.y + Math.floor(Math.random() * 20 - 10);

  const handleMouseMove = (e: any) => {
    setCoords({
      x: e.clientX - e.target.offsetLeft,
      y: e.clientY - e.target.offsetTop,
    });
  };

  const handleTouchMove = (e: any) => {
    setCoords({
      x: e.touches[0].pageX - e.target.offsetLeft,
      y: e.touches[0].pageY - e.target.offsetTop,
    });
  };

  useEffect(() => {
    if (canvasRef.current) {
      ctx.current = canvasRef.current.getContext('2d');
    }
  }, []);

  const draw = (x: number, y: number, e: MouseEvent | TouchEvent) => {
    if (mouseDown) {
      ctx.current.beginPath();
      ctx.current.strokeStyle = "black";
      ctx.current.lineWidth = 10;
      ctx.current.moveTo(coords.x, coords.y)
      ctx.current.lineTo(fakeX, fakeY);
      ctx.current.lineJoin = 'round';
      ctx.current.closePath();
      ctx.current.stroke();
    }
  }

  const draw2 = (e: TouchEvent) => {
    if (touchDown) {
      ctx.current.beginPath();
      ctx.current.strokeStyle = "black";
      ctx.current.lineWidth = 10;
      ctx.current.moveTo(coords.x, coords.y)
      ctx.current.lineTo(fakeX, fakeY);
      ctx.current.lineJoin = 'round';
      ctx.current.closePath();
      ctx.current.stroke();
    }
  }

  const clear = () => {
    ctx.current.clearRect(0, 0, ctx.current.canvas.width, ctx.current.canvas.height);
  }

  const onMouseDown = (e: MouseEvent): void => {
    setMouseDown(true);
  }

  const onMouseUp = (e: MouseEvent): void => {
    setMouseDown(false);
  }

  const onMouseMove = (e: MouseEvent): void => {
    draw(coords.x, coords.y, e);
    handleMouseMove(e);
  }

  const onTouchUp = (e: TouchEvent): void => {
    setTouchDown(false);
  }

  const onTouchMove = (e: TouchEvent): void => {
    setTouchDown(true);
    draw2(e);
    handleTouchMove(e);
  }

  const handleClick = (): void => {
    setPopup(true);
  }

  const description = t("room_four.level_two.exercice_calligraphy.description");

  return (
    <FocusTrap>
      <div className="caligraphie-container">
        <div className="caligraphie-top-container">
          {
            popup === false &&
            <div className="divRadius-caligraphie" style={{...stylePopUp,borderRadius:"100px"}}>
              <h1 className="size2" style={{marginTop: 0}}>{TextPopUp.room4[3].title}</h1>
              <p className="size4" style={{marginBottom: 0}}>{TextPopUp.room4[3].content}</p>
            </div>
          }
          {
            popup &&
            <FocusTrap>
              <div className="caligraphie-popup" style={{ width: valueXConvert, height: valueYConvert }} >
                <p className="caligraphie-popup-text size4" role="alertdialog" style={{marginTop: 0}}>{description}</p>
                <button
                  type="button"
                  tabIndex={0}
                  className="size3"
                  style={styleButton1}
                  onClick={() => {
                    setPopup(false);
                    content.handleClose();
                    const div: any = document.querySelector("canvas");
                    div.classList.add("hide");
                    const boutonGomme: any = document.querySelector("#gomme");
                    boutonGomme.classList.add('hide');
                    const arrow: any = document.querySelector("#arrow-right");
                    arrow.classList.add('hide');
                  }}
                  aria-label={description}>
                  OK
                </button>
              </div>
            </FocusTrap>
          }
        </div>


        <canvas
          style={{
            border: "1px solid #000",
            position: "absolute",
            bottom: "4%",
            left: "30.5%"
          }}
          width={valueXConvert}
          height={valueYConvert}
          ref={canvasRef}
          onMouseDown={(e: MouseEvent<HTMLCanvasElement>) => onMouseDown(e)}
          onMouseUp={(e: MouseEvent<HTMLCanvasElement>) => onMouseUp(e)}
          onMouseLeave={(e: MouseEvent<HTMLCanvasElement>) => onMouseUp(e)}
          onMouseMove={(e: MouseEvent<HTMLCanvasElement>) => onMouseMove(e)}
          onTouchMove={(e: TouchEvent<HTMLCanvasElement>) => onTouchMove(e)}
          onTouchCancel={(e: TouchEvent<HTMLCanvasElement>) => onTouchUp(e)}

        />
        <br />
        <div
          role="button"
          tabIndex={-1}
          aria-hidden="true"
          onClick={clear}
          id="gomme"
        ><FontAwesomeIcon icon={faEraser} className="size1" style={{ color: "black" }} /></div>

        <button
          id="arrow-right"
          type="button"
          aria-label="la calligraphie des personnes atteintes de parkinson est altérée, cliquez ici pour obtenir les explications"
          tabIndex={0}
          aria-hidden="true"
          onClick={handleClick}
        >
          <FontAwesomeIcon
            icon={faArrowRight} className="size1"
            style={{ color: "black"}}
          />
        </button>
      </div>
    </FocusTrap>
  );
}

export default Caligraphie;
