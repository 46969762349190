import React from "react";
import i18next from "i18next";
import GoldenRulesImg from "../../assets/images/room9/GoldenRules.png";
import GoldenRulesEn from "../../assets/images/room9/GoldenRulesEn.png";
import ScreenSize from "../../utils/ScreenSize";

export default function GoldenRules() {
    const getCurrentLanguage = i18next.language;

    const screenW: number = ScreenSize().width;
    const screenH: number = ScreenSize().height;

    return (
        <img
            src={getCurrentLanguage === "fr" ? GoldenRulesImg : GoldenRulesEn}
            alt="golden rules"
            style={{
                width: `${screenW * 0.6}px`,
                height: `${screenH * 0.7}px`,
            }}
        />
    );
}
