import React from "react";
import { v4 as uuidv4 } from "uuid";
import { PopUpProps } from "../../types/PopUp-types";

const parse = require('html-react-parser');

function BasicPopUp(props: PopUpProps): JSX.Element {

  const content: PopUpProps = props;
  const stylePopUp: object = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "transparent"
  };

  const styleContainer: object = {
    background: "rgba(255,255,255, 0.5) 0% 0% no-repeat padding-box",
    border: "3px solid rgb(255,255,255, 1)",
    textAlign: "center",
    backdropFilter: "blur(50px)",
    boxShadow: "2px 2px 3px rgba(0, 0, 0, 0.6)",
    color: "black",
    marginBottom: "15px",
    padding: "20px 15px",

  }

  return (
    <div className="popup"
      style={{ ...stylePopUp, ...content.style }}
      role="alertdialog"
      aria-modal="true"
    >

      <div className="container-text" role="group" style={{ ...content.style, ...styleContainer }}>
        {
          content.img && (
            <img src={content.img} alt=""

            />
          )
        }
        {content.title && <h2 id="title1" className="size2" style={{ margin: content.content || content.contents ? "3px 0 10px 0" : "0", fontWeight: "bold" }}>{parse(content.title)}</h2>}
        {
          content.contents ? (
            content.contents.map((elt, i) => <p key={uuidv4()} id={`dialog${i + 1}Desc`} className="size3" >{parse(elt)}</p>)
          ) : (content.content && <p id="dialog1Desc" className="size3" >{parse(content.content)}</p>)
        }

      </div>
    </div>
  );
}

export default BasicPopUp;
