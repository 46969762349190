import React from "react";
import { useTranslation } from "react-i18next";
import FocusTrap from "focus-trap-react";
import { useNavigate } from 'react-router-dom';
import PopUpWithButton from "../../../components/popups/PopUpWithButton";
import { styleButton2 } from "../../../helpers/stylesComponents";

export default function Level2Suite() {
    const { t } = useTranslation();

    const navigate = useNavigate()

    const handleClick = (): void => {
        navigate("/chapitre1/room2/level3");
    };

    const title = t("room_two.level_two.sublevel_one.title");
    const firstText = t("room_two.level_two.sublevel_one.first_text");
    const btn = t("room_two.level_two.sublevel_one.btn");

    return (
        <FocusTrap>
            <div className="container2-2">
                <PopUpWithButton
                    title={title}
                    content={firstText}
                    click={handleClick}
                    button={btn}
                    styleBtn={styleButton2}
                />
            </div>
        </FocusTrap>
    )
}
