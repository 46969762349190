import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { DraggableProps } from "../../types/Draggable-type"
import ScreenSize from "../../utils/ScreenSize";
import { getPosition, getPourcentObj, sizeObj } from "../../utils/funcUtils";
import { TypeObject } from "../../utils/typesUtils";

/**
 * Draggable component that can be used in every situations, whether you want to drag an image or a simple div
 * @param startX : x position of the component in percentage (0-100)
 * @param startY : y position of the component in percentage (0-100)
 * @param destinations : array of destinations in percentage (0-100)
 * @param imagePath : path of the image to drag, can be null
 * @param sizeDestinationX : size of the image in pixels, can be null
 * @param sizeDestinationY : size of the image in pixels, can be null
 * @param dragConstraints constraints of the draggable, can be null
 * @param content : content of the draggable, can be null
 * @param heightImg : height of the image in pixels, can be null
 * @param onFinish : function called when the component is in the right location
 * @param onChangeDestination : function called when the component is in the right location
 * @returns draggable component
 */

const pathJsonBack = { "width": 1920, "height": 1080 };
function DraggableGeneric(props: DraggableProps): JSX.Element {

    const draggableProps = props;
    // Variable to get screen size
    const screenW: number = ScreenSize().width;
    const screenH: number = ScreenSize().height;
    const [pourcent, setPourcent] = useState<Array<number>>(getPourcentObj(screenW, screenH, pathJsonBack));
    const [placement, setPlacement] = useState<TypeObject | null>(draggableProps.pJson ? {
        position: getPosition(screenW, screenH, draggableProps.pJson),
        size: sizeObj(screenW, screenH, pathJsonBack, draggableProps.pJson)
    } : null)
    const style = draggableProps.pJson && placement ? {
        left: `calc(${placement.position[0] * pourcent[0] / 100}% - ${(placement.size[0] / 2)}px)`,
        top: `calc(${placement.position[1] * pourcent[1] / 100}% - ${placement.size[1] / 2}px )`,
        width: `${placement.size[0]}px`,
        height: `${placement.size[1]}px`,
    } : null;

    const [element, setElement] = useState({
        x: draggableProps.startX,
        y: draggableProps.startY,
        styleX: "0%",
        styleY: "0%",
        draggable: true,
    });

    type TypeDestinationsConvert = {
        x: number;
        y: number;
    }

    const [destinationsConvert, setDestinationConvert] = useState<Array<TypeDestinationsConvert>>([]);




    useEffect(() => {
        const arr: Array<TypeDestinationsConvert> = [];
        let i = 0;
        while (i < draggableProps.destinations.length) {
            const inter: TypeDestinationsConvert = {
                x: (draggableProps.destinations[i].x / 100) * screenW,
                y: (draggableProps.destinations[i].y / 100) * screenH
            };
            arr.push(inter);
            i += 1;

        }
        setDestinationConvert([...arr]);
        setPourcent(getPourcentObj(screenW, screenH, pathJsonBack));
        setPlacement(p => p && { ...p, size: sizeObj(screenW, screenH, pathJsonBack, draggableProps.pJson) });
        setPlacement(p => p && { ...p, position: getPosition(screenW, screenH, draggableProps.pJson) });

    }, [draggableProps.destinations, draggableProps.pJson, screenH, screenW]);

    // concatenate the x and y values into a percentage for the style
    const startX: string = `${draggableProps.startX}%`;
    const startY: string = `${draggableProps.startY}%`;



    /**
     * This function is called when the user has moved the component.
     * When the user has moved the component in the right location, the component is no longer draggable.
     * @param _x the current X location of the component in pixels
     * @param y the current Y location of the component in pixels
     */
    const updatePosition = (_x: number | string, y: number | string) => {
        let i = 0;
        while (i < destinationsConvert.length) {
            // Checks if there is an image
            if (draggableProps.sizeDestinationX && draggableProps.sizeDestinationY) {
                // Checks if the component is in the right location.
                if (_x > destinationsConvert[i].x - draggableProps.sizeDestinationX / 2 &&
                    y > destinationsConvert[i].y - draggableProps.sizeDestinationY / 2 &&
                    _x <= destinationsConvert[i].x + draggableProps.sizeDestinationX / 2 &&
                    y <= destinationsConvert[i].y + draggableProps.sizeDestinationY / 2 &&
                    !draggableProps.destinations[i].occuped) {
                    setElement({
                        x: destinationsConvert[i].x,
                        y: destinationsConvert[i].y,
                        styleX: `${draggableProps.destinations[i].x}%`,
                        styleY: `${draggableProps.destinations[i].y}%`,
                        draggable: false,
                    });
                    // If a function is needed, add it to the draggable interface
                    if (draggableProps.onChangeDestination) draggableProps.onChangeDestination(draggableProps.destinations[i]);
                    if (draggableProps.onFinish) draggableProps.onFinish();

                }
                // Checks if the component is in the right location.
            } else if (_x > destinationsConvert[i].x - 25 * (pourcent[0] / 100 === 0 ? 1 : pourcent[0] / 100) && y > destinationsConvert[i].y - 15 * (pourcent[1] / 100 === 0 ? 1 : pourcent[1] / 100) &&
                _x < destinationsConvert[i].x + 38 * (pourcent[0] / 100 === 0 ? 1 : pourcent[0] / 100) && y < destinationsConvert[i].y + 38 * (pourcent[1] / 100 === 0 ? 1 : pourcent[1] / 100) &&
                !draggableProps.destinations[i].occuped) {
                // If a function is needed, add it to the draggable interface
                if (draggableProps.onChangeDestination) draggableProps.onChangeDestination(draggableProps.destinations[i]);
                if (draggableProps.onFinish) draggableProps.onFinish();
                setElement({
                    x: destinationsConvert[i].x,
                    y: destinationsConvert[i].y,
                    styleX: `${draggableProps.destinations[i].x}%`,
                    styleY: `${draggableProps.destinations[i].y}%`,
                    draggable: false,
                });
            }
            i += 1;
        }
    };

    if (draggableProps.imagePath) {
        const styleE = style || {
            top: startY,
            left: startX,
            width: draggableProps.widthImg ? (draggableProps.widthImg) : ("2.8%"),
            height: draggableProps.heightImg ? (draggableProps.heightImg) : ("2.8%"),
        }

        const styleDragEnd = style && {
            display: "none"
        };

        return (
            <div>
                {/* Ternary condition to make sure the element is still draggable */}
                {/* This return component is a test, so it would be changed many times */}
                {element.draggable ? (
                    <div>
                        <motion.img
                            aria-hidden
                            src={draggableProps.imagePath}
                            style={{ position: 'absolute', opacity: 1, ...styleE, zIndex: 4, cursor: "pointer"}}
                            drag
                            onDragEnd={(e, info) => {
                                updatePosition(info.point.x, info.point.y);
                            }}
                            dragTransition={{ bounceStiffness: 100, bounceDamping: 10 }}
                            dragElastic={0.6}
                            dragMomentum={false}
                            dragConstraints={draggableProps.dragConstraints}
                        />

                    </div>)

                    : <div style={{
                        ...styleDragEnd,
                        zIndex: 2,
                        width: '3.5%',
                        height: draggableProps.heightImg ? draggableProps.heightImg : "3.5%",
                        position: 'absolute',
                        top: element.styleY,
                        left: element.styleX,
                        margin: '0',
                        backgroundImage: `url(${draggableProps.imagePath})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: 'contain',
                    }} aria-hidden />
                }
            </div>
        );
    }

    return (
        <div>
            {element.draggable ? (
                <motion.div className="classMotion"
                    initial={{ position: 'absolute', top: startY, left: startX, opacity: 1, }}
                    drag
                    onDragEnd={(e, info) => {
                        updatePosition(info.point.x, info.point.y);
                    }}
                    dragElastic={0}
                    dragMomentum={false}
                    dragConstraints={draggableProps.dragConstraints}
                    aria-hidden
                >
                    {draggableProps.content ? (<p>{draggableProps.content}</p>) : null}
                </motion.div>
            ) :
                <div
                    className="classMotion"
                    style={{
                        position: 'absolute',
                        top: element.styleY,
                        left: element.styleX,

                    }}
                    aria-hidden
                >
                    {draggableProps.content ? (<p>{draggableProps.content}</p>) : null}
                </div>
            }
        </div>
    );
}
export default DraggableGeneric;
