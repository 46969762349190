import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import FocusTrap from "focus-trap-react";
import BasicButton from "../../../components/buttons/BasicButton";
import brain from "../../../assets/images/room3/Brain.png";
import panel from "../../../assets/images/room3/Panel.png";
import winner from "../../../assets/images/room3/Winner.png";
import path from "../../../assets/images/room3/Path.png";
import chessPlayer from "../../../assets/images/room3/Chess_Player.png";
import Bushes from "../../../assets/images/room3/Bushes.png";
import Bush from "../../../assets/images/room3/Bush01.png";
import WalkerCharacter from "../../../assets/images/room3/WalkerCharacter.png";
import brainConnexion from "../../../assets/images/room3/Brain-connexion/Web_Pink.png";
import brainConnexion2 from "../../../assets/images/room3/Brain-connexion/Web_Blue.png";
import brainConnexion3 from "../../../assets/images/room3/Brain-connexion/Web_Purple.png";
import { styleButton1, stylePopUp } from "../../../helpers/stylesComponents";
import myTextPopUp from "../../../helpers/textPopUp";


const parse = require('html-react-parser');

function Room3Brain(): JSX.Element {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [popupPurple, setPopupPurple] = useState<boolean>(false);
    const [popupBlue, setPopupBlue] = useState<boolean>(false);
    const [popupRed, setPopupRed] = useState<boolean>(false);

    const [isPurpleDone, setPurple] = useState<boolean>(false);
    const [isBlueDone, setBlue] = useState<boolean>(false);
    const [isRedDone, setRed] = useState<boolean>(false);

    const [nextbutton, setNextButton] = useState<boolean>(false);

    const inputBrainRed = useRef<HTMLInputElement>(null);
    const inputBrainBlue = useRef<HTMLInputElement>(null);
    const inputBrainPurple = useRef<HTMLInputElement>(null);


    const hiddenElement = useCallback(() => {
        if (inputBrainPurple.current && inputBrainRed.current && inputBrainBlue.current) {
            if (popupRed) {
                inputBrainBlue.current.classList.toggle("hide");
                inputBrainPurple.current.classList.toggle("hide");
            } else if (popupBlue) {
                inputBrainRed.current.classList.toggle("hide");
                inputBrainPurple.current.classList.toggle("hide");
            } else if (popupPurple) {
                inputBrainRed.current.classList.toggle("hide");
                inputBrainBlue.current.classList.toggle("hide");
            }
        }
    }, [popupBlue, popupPurple, popupRed])

    const handleClick = (): void => {
        
        hiddenElement();
        if (popupPurple) {
            setPopupPurple(false)
            setPurple(true)
        } else if (popupBlue) {
            setPopupBlue(false)
            setBlue(true)
        } else if (popupRed) {
            setPopupRed(false)
            setRed(true)
        }
    }

    useEffect(() => {
        hiddenElement();
    }, [hiddenElement])



    const redirection = (): void => {
        navigate("/chapitre1/room3/level1.3");
    }

    useEffect(() => {
        if (isRedDone && isPurpleDone && isBlueDone) {
            setNextButton(true);
        }
    }, [isRedDone, isPurpleDone, isBlueDone])

    const inspectText = t("room_three.level_one.sublevel_one.title");
    const nextBtn = t("room_three.level_one.sublevel_one.btn_next");

    return (
        <div className="room3ContainerColor" id="row">
            <div className="surbrillance" style={(popupRed || popupPurple || popupBlue) ? { backgroundColor: "rgba(0,0,0,0.6)" } : { backgroundColor: "rgba(0,0,0,0)" }} />
            <div className="leftSide">
                <div className="panel-container">
                    <img className="panel" src={panel} alt="" />
                    <div className="div-brain-container">
                        <div className="brain-container2" >

                            <img className="brain" src={brain} alt="" />

                            <input
                                type="image"
                                ref={inputBrainRed}
                                src={brainConnexion}
                                className="brainConnexionRed"
                                onClick={() => { setPopupRed(true) }}
                                alt="Connexion rouge"
                            />

                            <input
                                type="image"
                                ref={inputBrainBlue}
                                src={brainConnexion2}
                                className="brainConnexionBlue"
                                onClick={(): void => { setPopupBlue(true) }}
                                alt="Connexion bleue"
                            />

                            <input
                                type="image"
                                ref={inputBrainPurple}
                                src={brainConnexion3}
                                className="brainConnexionPurple"
                                onClick={(): void => { setPopupPurple(true) }}
                                alt="Connexion violette"
                            />

                        </div>
                    </div>
                    <p style={{ color: "black" }} className="size3">{inspectText}</p>
                </div>
            </div>

            <div className="popupContainer">
                {popupRed &&
                <>
                    <div className="popUpVoies" style={stylePopUp}>
                        <h2 className="size2">{parse(myTextPopUp.room3[1].title)}</h2>
                        <p className="size3">{parse(myTextPopUp.room3[1].content)}</p>
                    </div>
                    <BasicButton 
                        text={myTextPopUp.room3[1].button}
                        click={handleClick}
                    />
                </>
                    
                }

                {
                    popupBlue && 
                    <>
                        <div className="popUpVoies" style={stylePopUp}>
                            <h2 className="size2">{parse(myTextPopUp.room3[2].title)}</h2>
                            <p className="size3">{parse(myTextPopUp.room3[2].content)}</p>
                        </div>
                        <BasicButton 
                            text={myTextPopUp.room3[2].button}
                            click={handleClick}
                        />
                    </>
                    
                    
                }

                {
                    popupPurple && 
                    <>
                        <div className="popUpVoies" style={stylePopUp}>
                            <h2 className="size2">{parse(myTextPopUp.room3[3].title)}</h2>
                            <p className="size3">{parse(myTextPopUp.room3[3].content)}</p>
                        </div>
                        <BasicButton 
                            text={myTextPopUp.room3[3].button}
                            click={handleClick}
                        />
                    </>
                    
                }

                {nextbutton &&

                    <FocusTrap>
                        <div id="boutonSuite" role="button" tabIndex={0}>
                            <BasicButton
                                click={redirection}
                                text={nextBtn}
                                styleB={{ ...styleButton1, padding: "30px 15px", borderRadius: "30px" }}
                            />
                        </div>
                    </FocusTrap>
                }



                <img src={chessPlayer}
                    tabIndex={-1}
                    className="chessPlayerBack"
                    alt="Des personnes qui jouent aux échecs"
                    style={(!popupPurple && !popupBlue) ? { filter: "brightness(100%)" } : { filter: "brightness(40%)" }} />

                <img src={winner}
                    tabIndex={-1}
                    className="winnerBack"
                    alt="Des personnes qui viennent de gagner un trophée"
                    style={(!popupRed && !popupPurple) ? { filter: "brightness(100%)" } : { filter: "brightness(40%)" }} />

                <img src={WalkerCharacter}
                    tabIndex={-1}
                    className="walkerCharacter"
                    alt="Une personne qui marche"
                    style={(!popupRed && !popupBlue) ? { filter: "brightness(100%)" } : { filter: "brightness(40%)" }} />

                <img src={Bush}
                    tabIndex={-1}
                    className="Bush"
                    alt=""
                    style={(popupRed || popupPurple || popupBlue) ? { filter: "brightness(40%)" } : { filter: "brightness(100%)" }} />

            </div>

            <div className="containerPath" >
                <img src={path} tabIndex={-1} alt="" style={(popupRed || popupPurple || popupBlue) ? { filter: "brightness(40%)" } : { filter: "brightness(100%)" }} />
            </div>
            <div className="containerBushes">
                <img src={Bushes} tabIndex={-1} alt="" style={(popupRed || popupPurple || popupBlue) ? { filter: "brightness(40%)" } : { filter: "brightness(100%)" }} />
            </div>

        </div>

    );
}

export default Room3Brain;



