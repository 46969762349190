import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion, Variants } from "framer-motion";
import FocusTrap from "focus-trap-react";
import ScreenSize from "../../utils/ScreenSize";
import { getPosition, getPourcentObj, sizeObj, delay } from "../../utils/funcUtils";
import { placements, styleElement } from "../../utils/utilsPlacement";
import { TypeObject } from "../../utils/typesUtils";
import TypeBodyRoom from "../../types/BodyRoom-type";
import myTextAria from "../../helpers/textAria";

interface Variant {
    initial: string;
    ventre: string;
    tete: string;
}

const pathJsonBack: object = { "width": 1920, "height": 1080 };

function BodyRoom(props: TypeBodyRoom) {
    const content: TypeBodyRoom = props;
    const navigate = useNavigate()
    const screenW: number = ScreenSize().width;
    const screenH: number = ScreenSize().height;

    const [pourcent, setPourcent] = useState<Array<number>>(getPourcentObj(screenW, screenH, pathJsonBack));
    const [placement, setPlacement] = useState<Array<TypeObject>>(placements(screenW, screenH, content.pathJson, pathJsonBack))

    const style = styleElement(placement, pourcent);
    const variants: Variants = {
        initial: {
            backgroundSize: "auto 170vh",
        },
        ventre: {
            backgroundSize: "auto 380vh",
        },
        tete: {
            backgroundSize: "auto 420vh",
        },
    }
    const nameVariants: Variant = {
        initial: "initial",
        ventre: "ventre",
        tete: "tete"
    };

    const [animCourante, setAnimCourante] = useState<string>(nameVariants.initial);
    const [object, setObject] = useState<boolean>(false);
    const [gelule, setGelule] = useState<boolean>(true);
    const [digestif, setDigestif] = useState<boolean>(true);
    const [digestif1, setDigestif1] = useState<boolean>(false);
    const [digestif2, setDigestif2] = useState<boolean>(false);
    const [digestif3, setDigestif3] = useState<boolean>(false);

    // Change position objects if a parameter changes
    // Change size objects if a parameter changes
    useEffect(() => {
        setPourcent(getPourcentObj(screenW, screenH, pathJsonBack));
        setPlacement(current =>
            current.map((obj, index) => {
                return { ...obj, size: sizeObj(screenW, screenH, pathJsonBack, content.pathJson[index]) };
            })
        );
        setPlacement(current =>
            current.map((obj, index) => {
                return { ...obj, position: getPosition(screenW, screenH, content.pathJson[index]) };
            })
        );
    }, [screenW, screenH, content.pathJson]);

    const styleBackground = (): string => ["initial", "tete"].includes(animCourante) ? "top" : "center";
    const getText = (): string => {
        if (digestif2) return content.textBody[1] ? content.textBody[1] : "";
        if (digestif3) return content.textBody[2] ? content.textBody[2] : "";
        return content.textBody[0] ? content.textBody[0] : "";
    }

    const getTextAria = (): string => {
        type TypeTextAria = {
            p1: string;
            p2: string;
            p3: string;
        };

        let r: TypeTextAria | undefined = myTextAria.animations[2].inhibiteur;
        if (r) {
            if (content.nameMed === "Lévodopa") {
                r = myTextAria.animations[0].levodopa;
            } else if (content.nameMed === "Dopaminergique") {
                r = myTextAria.animations[1].agoniste;
            }
        }
        if (digestif1 && r) return r.p1;
        if (digestif2 && r) return r.p2;
        if (digestif3 && r) return r.p3;
        return "";
    }

    const getDiapo = (): JSX.Element => {
        return (
            <div
                className="Digestif" >
                <p className="textBody" tabIndex={-1}>{getText()}</p>
            </div>
        );
    }

    useEffect(() => {
        const d: number = 2200;
        delay(d).then(() => {
            if (digestif) {
                setDigestif(false);
                setDigestif1(true);
            } else if (digestif1) {
                setDigestif1(false);
                setDigestif2(true);
                setObject(true);
                setGelule(false);
                setAnimCourante(nameVariants.ventre);
            } else if (digestif2) {
                setDigestif2(false);
                setDigestif3(true);
                setAnimCourante(nameVariants.tete);
            } else {
                navigate(content.nextPage, {
                    state: {
                        choiceMed: content.nameMed
                    }
                });
            }
        })
    }, [content.nameMed, content.nextPage, digestif, digestif1, digestif2, nameVariants.tete, nameVariants.ventre, navigate]);

    return (
        <FocusTrap>
            <motion.div className="room5level2-container"
                variants={variants}
                style={{ backgroundPosition: styleBackground() }}
                animate={animCourante}>
                {
                    gelule && (
                        <motion.div
                            className="container-gelule"
                            animate={{
                                left: [style[0].left, style[1].left, style[2].left, style[3].left],
                                top: [style[0].top, style[1].top, style[2].top, style[3].top],
                                rotate: [0, 0, 90, 90, 0],
                                width: [style[0].width, style[1].width, style[2].width, style[3].width],
                                height: [style[0].height, style[1].height, style[2].height, style[3].height],
                            }}
                            transition={{ times: [0, 0.35, 0.7, 1], duration: 3 }}
                        >
                            <img src={content.pathGelule} tabIndex={-1} alt="gellule" />
                        </motion.div>
                    )
                }

                {
                    object && (
                        <div className="container-obj" style={style[digestif2 ? 4 : 5]}>
                            <img src={content.pathObj} tabIndex={-1} alt="dopamine" />
                        </div>
                    )
                }

                {
                    getDiapo()
                }
                <div
                    role="button"
                    tabIndex={0}
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "20px",
                        height: "20px"
                    }}
                    aria-label={getTextAria()}
                />
            </motion.div>
        </FocusTrap>
    )
}

export default BodyRoom;