import React, { useState, useEffect, useRef } from "react";
import { SpinePlayer } from "@esotericsoftware/spine-player";
import "@esotericsoftware/spine-player/dist/spine-player.css";
import ScreenSize from "../../utils/ScreenSize";
import { getPosition, getPourcentObj, sizeObj, delay } from "../../utils/funcUtils";
import { placements, styleElement } from "../../utils/utilsPlacement";
import PopUpWithButton from "../../components/popups/PopUpWithButton";
import AccrobrancheProps from "../../types/Accrobranche-type";
import { styleButton2, stylePopUp } from "../../helpers/stylesComponents";
import TextPopUp from "../../helpers/textPopUp";
import { resolve } from "node:path/win32";

const pathJson: Array<object> = [
  { "x": 960, "y": 380, "width": 1920, "height": 400 },
  { "x": 960, "y": 807, "width": 1920, "height": 400 },

];


const pathJsonRef: object = { "width": 1920, "height": 1080 };

function Accrobranche(props: AccrobrancheProps): JSX.Element {
  const accroProps = props;
  // Variable to get screen size
  const screenW: number = ScreenSize().width;
  const screenH: number = ScreenSize().height;
  type TypeObject = {
    position: Array<number>;
    size: Array<number>;
  };

  // Variable for character in animation
  const playerTopRef = useRef<SpinePlayer | null>();
  const playerBottomRef = useRef<SpinePlayer | null>();

  const atlasUrlTop = "/Accrobranche_animations/TopCharacter/Accrobranche_TopCharacter_StartRemoved.atlas";
  const atlasUrlBottom = "/Accrobranche_animations/BottomCharacter/Accrobranche_BottomCharacter_StartRemoved.atlas";

  const binaryUrlTop = "/Accrobranche_animations/TopCharacter/Top_Character_skeleton.skel";
  const binaryUrlBottom = "/Accrobranche_animations/BottomCharacter/Bottom_Character_skeleton.skel";

  // Variables for responsive
  const [pourcent, setPourcent] = useState<Array<number>>(getPourcentObj(screenW, screenH, pathJsonRef));
  const [placement, setPlacement] = useState<Array<TypeObject>>(placements(screenW, screenH, pathJson, pathJsonRef))
  const style = styleElement(placement, pourcent);

  // Variables for popUp
  const [popUptitle, setPopUptitle] = useState<boolean>(true)
  const [firstPopUp, setFirstPopUp] = useState<boolean>(true);
  const [lastPopUp, setLastPopUp] = useState<boolean>(false);

  const [init, setInit] = useState<boolean>(false);
  const [tempsMax, setTempsMax] = useState<number>(0);

  // Create and call a function initSpine() that renders the animation in 'player-container', then destroys it to avoid rendering it twice with dispose()
  // Change the pourcent if a parameter change
  useEffect(() => {
    setPourcent(getPourcentObj(screenW, screenH, pathJsonRef));
    setPlacement(current =>
      current.map((obj, index) => {
        return { ...obj, size: sizeObj(screenW, screenH, pathJsonRef, pathJson[index]) };
      })
    );
    setPlacement(current =>
      current.map((obj, index) => {
        return { ...obj, position: getPosition(screenW, screenH, pathJson[index]) };
      })
    );
  }, [screenW, screenH]);

  async function initAnimation() : Promise<boolean> {
    let essai = await 0;
    let success = await false;
    playerTopRef.current = await new SpinePlayer("containerTopCharacter", {
      atlasUrl: atlasUrlTop,
      binaryUrl: binaryUrlTop,
      premultipliedAlpha: false,
      preserveDrawingBuffer: true,
      showControls: false,
      skin: "Character01",
      backgroundColor: '#00000000',
      alpha: true,
      animation: "Climb_StartRemoved",
      defaultMix: 0.25,
      viewport: {
        debugRender: false,
        x: -130,
        y: -50,
        width: 536,
        height: 770,
        padTop: "0%",
        padBottom: "0%",
        padLeft: "0%",
        padRight: "0%",
      }
    });

    playerBottomRef.current = await new SpinePlayer("containerBottomCharacter", {
      atlasUrl: atlasUrlBottom,
      binaryUrl: binaryUrlBottom,
      premultipliedAlpha: false,
      preserveDrawingBuffer: true,
      showControls: false,
      backgroundColor: '#00000000',
      alpha: true,
      defaultMix: 0,
      animation: "Climb(IkConstraint)",
      viewport: {
        debugRender: false,
        x: -240,
        y: -914,
        width: 532,
        height: 790,

        padTop: "0%",
        padBottom: "0%",
        padLeft: "0%",
        padRight: "0%",
      },
    });
    
   
    while(await essai < 5 && !success){
      const characterPaused = delay(100).then(() => {
        if (playerBottomRef.current && playerTopRef.current) {
          
          playerBottomRef.current.paused = firstPopUp;
          playerTopRef.current.paused = firstPopUp;
          
          if (playerTopRef.current.animationState && playerTopRef.current.animationState.tracks[0]) {
            setTempsMax(playerTopRef.current.animationState.tracks[0].animationEnd / 1.8);
            success = true;
            return true;
          }
        }
      })
      if (await characterPaused) break;;
      essai += 1;
    }
    return await success;
  }

  useEffect(() => {

    if (!init) { 
      setInit(true);
      initAnimation();
    }
    if (!firstPopUp && playerBottomRef.current && playerTopRef.current) {
      playerBottomRef.current.addEventListener(playerBottomRef.current.parent, "click", animationCharacter);
    }

  }, [init, firstPopUp]);

  const animationCharacter = () => {
    if (playerBottomRef.current && playerTopRef.current) {
      playerBottomRef.current.paused = false;
      playerTopRef.current.paused = false;

      delay(1500).then(() => {
        if (playerBottomRef.current && playerTopRef.current) {
          playerBottomRef.current.paused = true;
          playerTopRef.current.paused = true;
        }
      });
    }

    if (playerTopRef.current && playerTopRef.current.animationState && playerTopRef.current.animationState.tracks[0] && playerTopRef.current.animationState.tracks[0].animationLast > tempsMax) {
      if (playerTopRef.current.animationState && playerBottomRef.current && playerBottomRef.current.animationState) {
        playerTopRef.current.animationState.tracks[0] = null;
        playerBottomRef.current.animationState.tracks[0] = null;
        setLastPopUp(!lastPopUp);
        setPopUptitle(!popUptitle);
      } else if (playerBottomRef.current) {
        playerTopRef.current.dispose();
        playerBottomRef.current.dispose();
      }
    }
  }

  const startCourse = () => {
    setFirstPopUp(!firstPopUp);
  }

  return (
    <div className="room4ContainerAccro">
      {
        popUptitle && (
          <div className="containerTitleAct" style={{ ...stylePopUp, borderRadius: "100px" }} role="alert">
            <h1 className="size2" style={{ marginTop: 0 }}>{TextPopUp.room4[0].title}</h1>
            <p className="size4" style={{ marginBottom: 0 }}>{TextPopUp.room4[0].content}</p>
          </div>
        )
      }
      {firstPopUp && <PopUpWithButton
        title={TextPopUp.room4[1].title}
        styleBtn={styleButton2}
        button={TextPopUp.room4[1].button}
        click={startCourse} />}

      {lastPopUp && <div className="container-messageFin">
        <PopUpWithButton
          styleBtn={styleButton2}
          title={TextPopUp.room4[2].title}
          content={TextPopUp.room4[2].content}
          button={TextPopUp.room4[2].button}
          click={accroProps.handleClickCloseAccro} />
      </div>}
      <div id="containerTopCharacter" style={style[0]} />
      <div id="containerBottomCharacter" style={style[1]} />

    </div>
  );
}

export default Accrobranche;
