import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { DraggableMultiPosProps } from "../../types/DraggableMultiPos-type";
import ScreenSize from "../../utils/ScreenSize";
import { getPourcentObj } from "../../utils/funcUtils";

const pathJsonBack = { "width": 1920, "height": 1080 };

/**
 * Draggable component to reach multiple positions
 * @param startX : x position of the component in percentage (0-100)
 * @param startY : y position of the component in percentage (0-100)
 * @param destinations : array of destinations in percentage (0-100)
 * @param imagePath : path of the image to drag, can be null
 * @param sizeDestinationX : size of the image in pixels, can be null
 * @param sizeDestinationY : size of the image in pixels, can be null
 * @param dragConstraints constraints of the draggable, can be null
 * @param heightImg : height of the image in pixels, can be null
 * @param onFinish : function called when the component is in the right location
 * @param onChangeDestination : function called when the component is in the right location
 * @returns draggable component
 */
export default function DraggableMultiPos(props: DraggableMultiPosProps) {

    const draggableProps: DraggableMultiPosProps = props;
    // concatenate the x and y values into a percentage for the style
    const startX: string = `${draggableProps.startX}%`;
    const startY: string = `${draggableProps.startY}%`;

    const screenW: number = ScreenSize().width;
    const screenH: number = ScreenSize().height;

    // pourcentage de réduction ou d'augmentation des objets %
    const [pourcent, setPourcent] = useState<Array<number>>(getPourcentObj(screenW, screenH, draggableProps.destinations));
    // Copie de la destination afin de pvr la modifier en fonction de l'écran
    type TypeDestinationsConvert = {
        x: number;
        y: number;
        width: number;
        height: number;
    }
    const [destinationsConvert, setDestinationConvert] = useState<Array<TypeDestinationsConvert>>([...draggableProps.destinations]);

    /**
     * This function is called when the user has moved the component.
     * When the user has moved the component in the right location, the component is no longer draggable.
     * @param _x the current X location of the component in pixels
     * @param y the current Y location of the component in pixels
     */

    const updatePosition = (_x: number | string, y: number | string) => {
        let i = 0;
        while (i < destinationsConvert.length) {
            // Checks if there is an image
            if (draggableProps.sizeDestinationX && draggableProps.sizeDestinationY) {
                // Checks if the component is in the right location.
                if (_x > destinationsConvert[i].x - draggableProps.sizeDestinationX / 2 &&
                    y > destinationsConvert[i].y - draggableProps.sizeDestinationY / 2 &&
                    _x < destinationsConvert[i].x + draggableProps.sizeDestinationX / 2 &&
                    y < destinationsConvert[i].y + draggableProps.sizeDestinationY / 2 &&
                    !draggableProps.destinations[i].passed) {
                    // If a function is needed, add it to the draggable interface
                    if (draggableProps.onChangeDestination) draggableProps.onChangeDestination(draggableProps.destinations[i]);
                    if (draggableProps.onFinish) draggableProps.onFinish();

                }
                // Checks if the component is in the right location.
            } else if (_x > destinationsConvert[i].x - 50 && y > destinationsConvert[i].y - 50 &&
                _x < destinationsConvert[i].x + 50 && y < destinationsConvert[i].y + 50 &&
                !draggableProps.destinations[i].passed) {
                // If a function is needed, add it to the draggable interface
                if (draggableProps.onChangeDestination) draggableProps.onChangeDestination(draggableProps.destinations[i]);
                if (draggableProps.onFinish) draggableProps.onFinish();

            }
            i += 1;
        }

    };

    // ---------------------- USE EFFECT ------------------------------

    useEffect(() => {
        setPourcent(getPourcentObj(screenW, screenH, pathJsonBack));
    }, [screenW, screenH]);

    useEffect(() => {
        const arr: Array<TypeDestinationsConvert> = [];
        let i = 0;
        while (i < draggableProps.destinations.length) {
            const inter: TypeDestinationsConvert = {
                x: draggableProps.destinations[i].x * pourcent[0] / 100,
                y: draggableProps.destinations[i].y * pourcent[1] / 100,
                width: draggableProps.destinations[i].width * pourcent[0] / 100,
                height: draggableProps.destinations[i].height * pourcent[1] / 100,
            };
            arr.push(inter);
            i += 1;

        }
        setDestinationConvert([...arr]);
    }, [draggableProps.destinations, pourcent]);

    return (
        <div
            style={{
                position: "relative",
                width: "100%",
                height: "100vh",
            }}
        >
            <div style={{
                zIndex: 2,
            }}>
                <motion.img
                    src={draggableProps.imagePath}
                    initial={{ position: 'absolute', top: startX, left: startY, opacity: 1, width: "3.5%" }}
                    drag
                    onDragEnd={(e, info) => {
                        updatePosition(info.point.x, info.point.y);
                    }}
                    dragConstraints={draggableProps.dragConstraints}
                />

            </div>
        </div>
    );
};
