import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import BloodSystem from "../../../assets/images/room1/BloodSystem.png";
import DigestiveSystem from "../../../assets/images/room1/DigestiveSystem.png";
import Muscles from "../../../assets/images/room1/Muscles.png";
import BreathingSystem from "../../../assets/images/room1/BreathingSystem.png";
import NervousSystem from "../../../assets/images/room1/NervousSystem.png";
import Squelette from "../../../assets/images/room1/Squelette.png";
import SALLE1ExportScriptTest from "../../../assets/images/room1/SALLE1_FullHD.json";
import ScreenSize from "../../../utils/ScreenSize";
import ImagePopUp from "../../../components/popups/ImagePopUp";
import PopUpWithButton from "../../../components/popups/PopUpWithButton";

function Quizz(props: any): JSX.Element {
  const { t } = useTranslation();
  type TypeObject = {
    id: string;
    name: string;
    pathJson: object;
    selected: boolean;
    goodRep: boolean;
    srcImg: string;
  };
  const screenW : number = ScreenSize().width;
  const screenH : number = ScreenSize().height;
  const [btnImage] = useState<Array<TypeObject>>([
    {
      id: uuidv4(),
      name: t("room_one.level_one.human_body_system.digestive_system"),
      pathJson: SALLE1ExportScriptTest.skins.default.DigestiveSystem.DigestiveSystem,
      selected: false,
      goodRep: false,
      srcImg: DigestiveSystem,
    },
    {
      id: uuidv4(),
      name: t("room_one.level_one.human_body_system.skeletal_system"),
      pathJson: SALLE1ExportScriptTest.skins.default.Squelette.Squelette,
      selected: false,
      goodRep: false,
      srcImg: Squelette,
    },
    {
      id: uuidv4(),
      name: t("room_one.level_one.human_body_system.vascular_system"),
      pathJson: SALLE1ExportScriptTest.skins.default.BloodSystem.BloodSystem,
      selected: false,
      goodRep: false,
      srcImg: BloodSystem,
    },
    {
      id: uuidv4(),
      name: t("room_one.level_one.human_body_system.nervous_system"),
      pathJson: SALLE1ExportScriptTest.skins.default.NervousSystem.NervousSystem,
      selected: false,
      goodRep: true,
      srcImg: NervousSystem,
    },
    {
      id: uuidv4(),
      name: t("room_one.level_one.human_body_system.respiratory_system"),
      pathJson: SALLE1ExportScriptTest.skins.default.BreathingSystem.BreathingSystem,
      selected: false,
      goodRep: false,
      srcImg: BreathingSystem,
    },
    {
      id: uuidv4(),
      name: t("room_one.level_one.human_body_system.muscular_system"),
      pathJson: SALLE1ExportScriptTest.skins.default.Muscles.Muscles,
      selected: false,
      goodRep: false,
      srcImg: Muscles,
    },
  ]);


  const navigate = useNavigate();
  const [redirection, setRedirection] = useState<boolean>(false);
  const [posGoodRep, setPosGoodRep] = useState<Array<number>>([0,0])
  const refNav = useRef<HTMLDivElement>(null);
  const [navigationHeight, setNavigationHeight] = useState<number | null>(null);
  const [navigationWidth, setNavigationWidth] = useState<number | null>(null);
  // Pop-up for wrong answer
  const [visiblePopup, setVisiblePopup] = useState<boolean>(false);

// On first rendering, give the height and width of the correct answer
  useEffect(() => {
    setNavigationHeight(refNav.current ? refNav.current.offsetHeight : null);
    setNavigationWidth(refNav.current ? refNav.current.offsetWidth : null);
  }, []);

  // if redirection is true,redirect to next room
  useEffect(() => {
    if (redirection) {
      setNavigationHeight(refNav.current ? refNav.current.offsetHeight : null);
      setNavigationWidth(refNav.current ? refNav.current.offsetWidth : null);
      navigate("/chapitre1/room1/level3",
        {state : {
          posX : posGoodRep[0],
          posY : posGoodRep[1],
          h : navigationHeight,
          w : navigationWidth,
          wScreenBased : screenW,
          yScreenBased : screenH
        }} as any);
    }
  }, [navigate, navigationHeight, navigationWidth, posGoodRep, redirection, screenH, screenW]);

  // Function onClick that changes the position of the correct response
  // and gives permission for the redirect
  const handleClick = (e : React.MouseEvent<HTMLButtonElement>, elt: TypeObject): void => {
    const myIndex: number = btnImage.findIndex((btn) => btn.id === elt.id);
    if (myIndex !== -1 && btnImage[myIndex].goodRep){
      setPosGoodRep([e.pageX, e.pageY]);
      setRedirection(true);
    }
    else if(myIndex !== -1 && !btnImage[myIndex].goodRep){
      setVisiblePopup(!visiblePopup);
    }
  };

  const wrongAnswer = t("room_one.level_two.wrong_answer");
  const retryBtn = t("room_one.level_two.button_tryagain");

  return (
    <div className="quizz-container bg-room1">
      <div className="question">
        <div className="container_text" >
          <p role="alertdialog" className="size2">{t("room_one.level_two.question_systems")}</p>
        </div>
      </div>
      <div className="reponses">
        {btnImage.map((elt) => {
          return (
            <ImagePopUp ref = {elt.goodRep ? refNav : null} key={elt.id} path ={elt.srcImg} imageName={elt.name} click={(e) => handleClick(e,elt)}/>
          );
        })}
      </div>

      {visiblePopup && <div className="result">
          <PopUpWithButton title={wrongAnswer}
              button={retryBtn}
              styleBtn={{ backgroundColor: "#089293", color:"white" }}
              click={(e) => setVisiblePopup(!visiblePopup)}
            />
        </div>}
    </div>
  );
}
export default Quizz;
