/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import FocusTrap from "focus-trap-react";
import BasicButton from "../../components/buttons/BasicButton";
import textPopUp from "../../helpers/textPopUp";
import Small_ActivitesPsychocorporelles from "../../assets/images/room8/Small_ActivitesPsychocorporelles.png";
import Small_ActivitesPsychocorporellesEn from "../../assets/images/room8/Small_ActivitesPsychocorporellesEn.png";
import ParkiDanse from "../../assets/images/room8/parkidanse.jpeg";
import { selectChap, unLockChap4 } from '../../slices/chapitresSlice';
import { styleButton2, stylePopUp } from "../../helpers/stylesComponents";
import PopUpWithButton from "../../components/popups/PopUpWithButton";

import FLyerPDF from "../../assets/flyer_parkidanse.pdf";


const parse = require('html-react-parser');

function VideoTherapie(): JSX.Element {
    const { t } = useTranslation();
    type TypeScreen = {
        popupStart: boolean;
        accueil: boolean,
        video: boolean,
        flyer: boolean,
        end: boolean,
    }
    const dispatch = useDispatch();
    const chapUnlock = useSelector(selectChap);
    const navigate = useNavigate();
    const [screens, setScreens] = useState<TypeScreen>({
        popupStart: true,
        accueil: false,
        video: false,
        flyer: false,
        end: false,
    })


    // ------------------- Functions -----------------------

    const handleClick = (screenTrue: string): void => {
        switch (screenTrue) {
            case "accueil":
                setScreens({ ...screens, accueil: true, popupStart: false })
                break;
            case "video":
                setScreens({ ...screens, accueil: false, video: true });
                break;
            case "flyer":
                setScreens({ ...screens, flyer: true, video: false });
                break;
            case "end":
                setScreens({ ...screens, end: true, flyer: false });
                break;
            default:
                break;
        }
    }

    const btnNext = t("room_eight.level_two.btn")

    const getCurrentLanguage = i18next.language;

    return (
        <FocusTrap>
            <div className="container-Tablette">
                <div className="screenTablette">

                    {
                        screens.popupStart &&
                        <div
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                width: "80%",
                            }}
                        >
                            <PopUpWithButton
                                contents={textPopUp.room8[2].contents}
                                click={() => handleClick("accueil")}
                                icon
                                arrowBlack={false}
                            />
                        </div>
                    }

                    {
                        screens.accueil &&
                        <div
                            role="alertdialog"
                        >
                            <p className="size2 p_popup1" style={{ ...stylePopUp, borderRadius: "15px" }}>{parse(textPopUp.room8[0].title)}</p>
                            <p className="size3 p_popup1" style={{ ...stylePopUp, borderRadius: "15px" }}>&laquo;{parse(textPopUp.room8[0].content)}&raquo;</p>
                            <input
                                type="image"
                                src={getCurrentLanguage === "fr" ? Small_ActivitesPsychocorporelles : Small_ActivitesPsychocorporellesEn}
                                alt=""
                                onClick={() => handleClick("video")} />
                        </div>
                    }

                    {
                        screens.video &&
                        <div
                            aria-label="video de danse"
                        >
                            <video controls>
                                <source src="https://res.cloudinary.com/dt6k2cynj/video/upload/v1669634595/motivpark/Chor%C3%A9graphie_bollywood_pour_escape_Game_q2dfy9.mp4" />
                            </video>
                            <div style={{
                                width: "70%",
                                height: "10%",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}>
                                <img src={ParkiDanse} alt="Parkidanse Logo" style={{height: "100%", borderRadius: "0.6rem"}} />
                                <BasicButton
                                    text={getCurrentLanguage === "fr" ? "Suivant" : btnNext}
                                    click={() => handleClick("flyer")}
                                />
                            </div>
                        </div>
                    }
                    
                    {
                        screens.flyer &&
                        <div
                            aria-label="flyer parkidanse"
                        >
                            <div style={{
                                width: "70%",
                                height: "10%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                marginBottom: "5rem",
                            }}>
                                <h2 style={{
                                    width: "100%",
                                    padding: "2rem 3rem",
                                    textAlign: "center",
                                    color: "#055C5C",
                                    backgroundColor: "#F2F2F2",
                                    borderRadius: "2rem",
                                }}>
                                    {t("room_eight.level_two.text_flyer")}
                                </h2>
                                <a href={FLyerPDF} target="_blank" rel="noreferrer" style={{
                                    padding: "1rem 2rem",
                                    textAlign: "center",
                                    fontWeight: "500",
                                    color: "#f2f2f2",
                                    backgroundColor: "#055C5C",
                                    textDecoration: "none",
                                    borderRadius: "6rem",
                                }}>{t("room_eight.level_two.download_flyer")}</a>
                            </div>
                            <div style={{
                                width: "70%",
                                height: "10%",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}>
                                <img src={ParkiDanse} alt="Parkidanse Logo" style={{height: "100%", borderRadius: "0.6rem"}} />
                                <BasicButton
                                    text={getCurrentLanguage === "fr" ? "Suivant" : btnNext}
                                    click={() => handleClick("end")}
                                />
                            </div>
                        </div>
                    }
                    
                    {
                        screens.end &&
                        <div
                            role="alertdialog"
                        >
                            <p className="size2 p_popup2">{parse(textPopUp.room8[1].title)}</p>
                            <BasicButton icon
                                styleB={styleButton2}
                                click={
                                    () => {
                                        if (!chapUnlock.chap4) dispatch(unLockChap4(true));
                                        navigate("/home");
                                    }
                                }
                                arrowV />
                        </div>
                    }

                </div>
            </div>
        </FocusTrap>
    )
}

export default VideoTherapie;