import React from "react";
import { useNavigate } from "react-router-dom";
import FocusTrap from "focus-trap-react";
import BasicButton from "../../../components/buttons/BasicButton";
import myTextPopUp from "../../../helpers/textPopUp";



function Room6level5() {

    const navigate = useNavigate();
    const handleClick = (): void => {
        navigate("/chapitre2/room7/level1");
    };

    return (
        <FocusTrap>
            <div className="background-room6">
                <div className="pop-up-room6">
                    <div className="message-room6" role="alertdialog">
                        <h1>{myTextPopUp.room6[4].title}</h1>
                        <p
                            style={{
                                fontWeight: "500",
                            }}
                        >{myTextPopUp.room6[4].p1}</p>
                        <p>{myTextPopUp.room6[4].p2}</p>
                    </div>
                    <BasicButton
                        text={myTextPopUp.room6[4].button}
                        click={handleClick}
                        styleB={{
                            backgroundColor: "#055C5C",
                            color: "#fff",
                            border: "none",
                            padding: "15px 50px",
                            borderRadius: "50px",
                            fontSize: "2.5rem"
                        }}
                    />
                </div>
            </div>
        </FocusTrap>

    )
}

export default Room6level5
