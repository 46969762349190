import React, { useEffect, useState } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import PopUpWithButton from "../../../components/popups/PopUpWithButton";
import ScreenSize from "../../../utils/ScreenSize";
import { getPosition, getPourcentObj, sizeObj } from "../../../utils/funcUtils";
import door from "../../../assets/images/room1/Door_Rebus/Rebus.json";
import SpaceButtons from "../../../components/buttons/SpaceButtons";
import TextPopUp from "../../../helpers/textPopUp";
import { pathJsonPad, placements, styleElement } from "../../../utils/utilsPlacement"
import BasicButton from "../../../components/buttons/BasicButton";
import BackgroundFR from "../../../assets/images/room1/Rebus.png";
import BackgroundEN from "../../../assets/images/room1/Rebus2.png";
// json for : pad, btn valider, espace deslettres et le monitor
const pathJson: Array<object> = pathJsonPad;
const parse = require('html-react-parser');

function Pad() {
  const { t } = useTranslation();
  const screenW: number = ScreenSize().width;
  const screenH: number = ScreenSize().height;
  const navigate = useNavigate();
  const pathJsonBack: object = door.skins.default.Door.Door;
  type TypeObject = {
    position: Array<number>;
    size: Array<number>;
  };
  const [pourcent, setPourcent] = useState<Array<number>>(getPourcentObj(screenW, screenH, pathJsonBack));
  const [placement, setPlacement] = useState<Array<TypeObject>>(placements(screenW, screenH, pathJson, pathJsonBack))
  const style = styleElement(placement, pourcent);
  const [letters, setLetters] = useState("");
  const [correctPopUp, setCorrectPopUp] = useState(false);
  const [wrongPopUp, setWrongPopUp] = useState(false);
  const [suitePopUp, setSuitePopUp] = useState(false);


  // Change the pourcent if a parameter change
  useEffect(() => {
    setPourcent(getPourcentObj(screenW, screenH, pathJsonBack));
  }, [screenW, screenH, pathJsonBack]);

  // Change size objects if a parameter change
  useEffect(() => {
    setPlacement(current =>
      current.map((obj, index) => {
        return { ...obj, size: sizeObj(screenW, screenH, pathJsonBack, pathJson[index]) };
      })
    );
  }, [pathJsonBack, screenH, screenW])

  // Change position objects if a parameter change
  useEffect(() => {
    setPlacement(current =>
      current.map((obj, index) => {
        return { ...obj, position: getPosition(screenW, screenH, pathJson[index]) };
      })
    );
  }, [screenW, screenH]);


  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (letters.length < 8 && e.code === "Backspace") {setLetters(letters.slice(0,-1));}
  }

  const handleClick = (letter: string) => {
    if (letters.length < 8) {
      setLetters(letters + letter);
    }
  };
  const handleSuitePopUp = () => {
    setCorrectPopUp(false);
    setSuitePopUp(true);
  }

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setLetters(e.currentTarget.value.toUpperCase());
  }

  /**
   * When pressing "Valider", checks if the answer is correct (i.e answer = DOPAMINE).
   */
  function checkMonitorValue() {
    if (letters === "DOPAMINE") {
      setCorrectPopUp(true);
      // We set the "Wrong !" pop-up to false to avoid having both pop-ups at the same time
      setWrongPopUp(false);
    }
    else {
      setWrongPopUp(true);
      // We set the "Correct !" pop-up to false to avoid having both pop-ups at the same time
      setCorrectPopUp(false);
    }
    setLetters("");
  }

  const wrongAnswers: string = t("room_one.level_five.wrong_answer");
  const retryBtn: string = t("room_one.level_five.try_again_btn");

  const getCurrentLanguage = i18next.language;

  return (
    <div className="pad" style={{
        position: "relative",
        background:  getCurrentLanguage === "fr" ? `url(${BackgroundFR})` : `url(${BackgroundEN})` ,
        backgroundSize:' 100% 100%',
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundPosition: 'center',
        height: '100vh',
        width: '100vw' 
      }}>
      {
        (correctPopUp || wrongPopUp || suitePopUp) && <div className="affichePopUp">
          {correctPopUp ? (
            <>
              <div className="popUpCorrect">
                <h2 className="size2">{parse(TextPopUp.room1[4].title)}</h2>
                <p className="size3">{parse(TextPopUp.room1[4].content)}</p>
              </div>
              <BasicButton
              click={handleSuitePopUp}
              text={TextPopUp.room1[4].button}/>
            </>
          ) : null}
          {wrongPopUp ? (
            <PopUpWithButton content={wrongAnswers}
            button={retryBtn}
            click={
              () => {
                setWrongPopUp(false);
                setLetters("");
              }
            } />
          ) : null}

          {suitePopUp ? (
            <PopUpWithButton title={TextPopUp.room1[5].title}
              style={{borderRadius:"50px"}}
              button={TextPopUp.room1[5].button}
              click={() => {
                navigate("/chapitre1/room2/level1");
              }}
            />
          ) : null}
        </div>
      }

      <div className="pad-elements"  role="button" onKeyDown={handleKeyDown} tabIndex={0} style={style[0]}>
        <div className="monitor" style={style[3]}>
          {letters !== "" && (
            <p>{letters}</p>)}
            <form className="screenreader-input" onSubmit={(e) => {
              e.preventDefault();
              checkMonitorValue();
            }}>
              <label htmlFor="input-id">
                Trouvez le mot en 8 lettres avec ces indices : personne de dos, traces de pas, mine de crayon
                <input id="input-id" type="text" onChange={handleChange} tabIndex={0}/>
              </label>
            </form>
        </div>
        <div className="pad-buttons" style={style[2]}>
          <SpaceButtons start={0} end={26} handleClick={handleClick} />
        </div>
        <motion.input
          type="submit"
          value=""
          className="validate-button"
          aria-label="valider"
          style={style[1]}
          onClick={(e) => {
            checkMonitorValue();
          }}
          whileTap={{ opacity: 1 }}
          transition={{ type: "spring", stiffness: 200, damping: 10 }}
          tabIndex={-1}
        />
      </div>
    </div >
  );
}

export default Pad;
