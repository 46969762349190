import React, { forwardRef } from "react";
import { ImagePopUpProps } from "../../types/ImagePopUp-types";

const ImagePopUp = forwardRef<HTMLDivElement, ImagePopUpProps>((props, refNav): JSX.Element => {
  const styleContainer : object = {
    background: "rgba(255,255,255, 0.7) 0% 0% no-repeat padding-box",
    backdropFilter: "blur(50px)",
    boxShadow: "2px 2px 3px rgba(0, 0, 0, 0.6)",
  }

  return (
    <div ref={refNav} className="image-popup" style={{ ...props.style}}>
      {
        props.ariaDisabled ? (
          <button type="submit" style={styleContainer} className="bg-img-popup" aria-disabled="true" aria-hidden="true">
            <img src={props.path} className="image-popup-border transparent" alt="" />
          </button>
        ) : (
          <button type="submit" style={styleContainer} className="bg-img-popup" onClick={props.click} aria-label={props.imageName} tabIndex={0}>
            <img src={props.path} className="image-popup-border transparent" alt={props.alternative ? (props.imageName) : ("")} />
          </button>
        )
      }
      <p className="image-popup-text size3">{props.imageName}</p>
    </div>
  );
});

export default ImagePopUp;
