import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from './store';


type TypeParchemin = {
    "parcheminTab": string[],
}

const initialState : TypeParchemin = {
    parcheminTab : ["Lévodopa", "Dopaminergique", "Inhibiteur"]
}

export const parcheminSlice = createSlice({
    name: "parchemins",
    initialState,
    reducers:{
        changeTab: (state,action: PayloadAction<string[]>) => {
            state.parcheminTab = action.payload;
        }
    }
});

export const { changeTab } = parcheminSlice.actions;
export const selectParchemin = (state : RootState) => state.parchemins;
export default parcheminSlice.reducer;