import React from "react";
import i18n from "../i18n";
import Option1 from "../assets/images/room9/options/_1.png";
import Option2 from "../assets/images/room9/options/_2.png";
import Option3 from "../assets/images/room9/options/_3.png";
import Option4 from "../assets/images/room9/options/_4.png";
import Option5 from "../assets/images/room9/options/_5.png";
import Option6 from "../assets/images/room9/options/_6.png";
import Option7 from "../assets/images/room9/options/_7.png";
import Option8 from "../assets/images/room9/options/_8.png";
import Option9 from "../assets/images/room9/options/_9.png";

interface OptionInfo {
    id: number;
    text: () => JSX.Element;
    img: string;
}

const { t } = i18n;


const optionsInfo: OptionInfo[] = [
    {
        id: 1,
        text: () => (
            <p style={{fontSize: "14px", lineHeight: "1.4", margin: 0}}>
                {t("room_nine.level_two.options.one.description")}
            </p>
        ),
        img: Option1,
    },
    {
        id: 2,
        text: () => (
            <p style={{fontSize: "14px", lineHeight: "1.4", margin: 0}}>
                {t("room_nine.level_two.options.two.description")}
            </p>
        ),
        img: Option6,
    },
    {
        id: 3,
        text: () => (
            <p style={{fontSize: "14px", lineHeight: "1.4", margin: 0}}>   
                {t("room_nine.level_two.options.three.description")}
            </p>
        ),
        img: Option8,
    },
    {
        id: 4,
        text: () => (
            <p style={{fontSize: "14px", lineHeight: "1.4", margin: 0}}>
                {t("room_nine.level_two.options.four.description")}
            </p>
        ),
        img: Option9,
    },
    {
        id: 5,
        text: () => (
            <p style={{fontSize: "14px", lineHeight: "1.4", margin: 0}}>
                {t("room_nine.level_two.options.five.description")}
            </p>
        ),
        img: Option3,
    },
    
];

const optionsInfo2: OptionInfo[] = [
    {
        id: 1,
        text: () => (
            <p style={{fontSize: "14px", lineHeight: "1.4", margin: 0}}>
                {t("room_nine.excursion_two.option_one")}
            </p>
        ),
        img: Option1,
    },
    {
        id: 2,
        text: () => (
            <p style={{fontSize: "14px", lineHeight: "1.4", margin: 0}}>
                {t("room_nine.excursion_two.option_two")}
            </p>
        ),
        img: Option2,
    },
    {
        id: 3,
        text: () => (
            <p style={{fontSize: "14px", lineHeight: "1.4", margin: 0}}>
                {t("room_nine.excursion_two.option_three")}
            </p>
        ),
        img: Option7,
    },
    {
        id: 4,
        text: () => (
            <p style={{fontSize: "14px", lineHeight: "1.4", margin: 0}}>
                {t("room_nine.excursion_two.option_four")}
            </p>
        ),
        img: Option4,
    },
    {
        id: 5,
        text: () => (
            <p style={{fontSize: "14px", lineHeight: "1.4", margin: 0}}>
                {t("room_nine.excursion_two.option_five")}
            </p>
        ),
        img: Option5,
    },
];

const optionsText = {
    'fr': [
        {
            content: `<em>${t("room_nine.level_two.options.one.wrong")}</em>`,
        },
        {
            content: `<em>${t("room_nine.level_two.options.two.wrong")}</em>`,
        },
        {
            content: `<em>${t("room_nine.level_two.options.three.correct")}</em>`,
        },
        {
            content: `<em>${t("room_nine.level_two.options.four.wrong")}</em>`,
        },
        {
            content: `<em>${t("room_nine.level_two.options.five.wrong")}</em>`,
        }
    ],
};

const optionsText2 = {
    'fr': [
        {
            content: `<em>${t("room_nine.excursion_two.answers.option_one")}</em>`,
        },
        {
            content: `<em>${t("room_nine.excursion_two.answers.option_two")}</em>`,
        },
        {
            content: `<em>${t("room_nine.excursion_two.answers.option_three")}</em>`,
        },
        {
            content: `<em>${t("room_nine.excursion_two.answers.option_four")}</em>`,
        },
        {
            content: `<em>${t("room_nine.excursion_two.answers.option_five")}</em>`,
        }
    ],
};

export { optionsText, optionsInfo, optionsText2, optionsInfo2};