import i18n from "../i18n";
import { getPosition, sizeObj } from "./funcUtils";
import { TypeAlphabet, TypeImgArray, TypeJsonRoom7, TypeObject, TypeObjectLevel1, TypePathJsonObjet } from "./typesUtils";
import SALLE1ExportScriptTest from "../assets/images/room1/SALLE1_FullHD.json";
import JsonRoom7 from "../assets/images/room7/Room_07_FullHD.json";
import BoiteMed from "../assets/images/room7/BoitesMedoc.png";
import ValiseL from "../assets/images/room7/Valise.png";
import Prescription from "../assets/images/room7/Prescription.png";
import Maillot from "../assets/images/room7/Maillot.png";

import Creme from "../assets/images/room7/Creme solaire.png";
import LunetteSoleil from "../assets/images/room7/LunetteSoleil.png"

import EssuiesBG from "../assets/images/room7/EssuiesBG.png"
import Essuies from "../assets/images/room7/Essuies.png"
import Smartphone from "../assets/images/room7/Smartphone.png"
import Flipflap from "../assets/images/room7/Flipflap.png"
import Pilulier from "../assets/images/room7/Pilulier.png"
import Valise2 from "../assets/images/room7/Valise2.0.png"


const systemDigestif = i18n.t("room_one.level_one.human_body_system.digestive_system");
const systemSquelette = i18n.t("room_one.level_one.human_body_system.skeletal_system");
const systemVasculaire = i18n.t("room_one.level_one.human_body_system.vascular_system");
const systemNerveux = i18n.t("room_one.level_one.human_body_system.nervous_system");
const systemRespiratoire = i18n.t("room_one.level_one.human_body_system.respiratory_system");
const systemMusculaire = i18n.t("room_one.level_one.human_body_system.muscular_system");


const humanBodySystem: Array<string> = [
  systemNerveux,
  systemVasculaire,
  systemSquelette,
  systemRespiratoire,
  systemMusculaire,
  systemDigestif
]

// Functions utiles
const getValueY = (v: number) => {
  if (v < 6 && v > -1) return -58;
    if (v >= 6 && v <= 12) return 190;
    if (v > 12 && v < 19) return 438;
    return 686;
}

const getValueX = (v: number) => {
    if (v < 6 && v > -1) return 352 + (v * 248);
    if (v >= 6 && v <= 12) return 220 + ((v - 6) * 248);
    if (v > 12 && v < 19) return 352 + ((v - 13) * 248);
    return 220 + ((v - 19) * 248);
}

// Variables json

// For level1 => room1 level1
export const pathJsonLevel1: Array<object> = [
    SALLE1ExportScriptTest.skins.default.PortraitPicture.PortraitPicture,
    SALLE1ExportScriptTest.skins.default.Plante.Plante,
    { "x": 805, "y": 515, "width": 292, "height": 584 },
    { "x": 1560, "y": 330, "width": 440, "height": 300 },
    { "x": 1620, "y": 840, "width": 360, "height": 380 },
    { "x": 1120, "y": 570, "width": 100, "height": 100 },
    { "x": 1055, "y": 620, "width": 80, "height": 160 },
    { "x": 960, "y": 540, "width": 762, "height": 542 },
];

// For pad access => room1 level4
export const pathJsonPadAccess: Array<object> = [
    SALLE1ExportScriptTest.skins.default.PortraitPicture.PortraitPicture,
    SALLE1ExportScriptTest.skins.default.Plante.Plante,
    SALLE1ExportScriptTest.skins.default.Medecin.Medecin,
]

// For pad => room1 level5
// Pad element, btn validate,
export const pathJsonPad: Array<object> = [
    { "x": 985, "y": 580, "width": 658, "height": 640 },
    { "x": 958, "y": 980, "width": 280, "height": 70 },
    { "x": 957, "y": 735, "width": 658, "height": 360 },
    { "x": 610, "y": 175, "width": 322, "height": 100 },
];

// For Pad Buttons => room1 level 5
export const pathJsonLetter = (): Array<TypeAlphabet> => {
    const res = [];
    let i = 0;
    while (i < 26) {
        res.push({
            "value": String.fromCodePoint(65 + i),
            "x": getValueX(i),
            "y": getValueY(i),
            "width": 75,
            "height": 75
        });
        i += 1;
    }
    return res;
}

// For object in Departure Vacation
// Position obj not try
export const pathJsonDepartureV = [
    { "x": 0, "y": 0, "width": 200, "height": 200 },
    { "x": 0, "y": 0, "width": 200, "height": 200 }
]

// Fonction de placements et de style

// Placement en absolute des objets
export const styleElement = (placement: Array<TypeObject>, pourcent: Array<number>) => {
    return placement.map((obj) => {
        return {
            left: `calc(${obj.position[0] * pourcent[0] / 100}% - ${(obj.size[0] / 2)}px)`,
            top: `calc(${obj.position[1] * pourcent[1] / 100}% - ${obj.size[1] / 2}px )`,
            width: `${obj.size[0]}px`,
            height: `${obj.size[1]}px`,
        };
    });
}

// Génère la position et la taille de l'objet dans la taille actuelle de l'écran
export const placements = (screenW: number, screenH: number,
    pathJson: Array<TypeAlphabet> | Array<object>, pathJsonRef: object) => {
    return pathJson.map((pJson) => {
        return {
            position: getPosition(screenW, screenH, pJson),
            size: sizeObj(screenW, screenH, pathJsonRef, pJson)
        };
    });
}

// Génère un fichier spécial pour le level qui reprend le concept de la fonction placements
export const placementLevel1 = (screenW: number, screenH: number,
    pathJsonRef: object, srcImg: Array<TypeImgArray>): Array<TypeObjectLevel1> => {
    return humanBodySystem.map((system, index) => {
        return {
            id: `obj${index}`,
            name: system,
            position: getPosition(screenW, screenH, pathJsonLevel1[index]),
            size: sizeObj(screenW, screenH, pathJsonRef, pathJsonLevel1[index]),
            srcImgObj: srcImg[index].srcImgObj,
            srcImgSecondaire: srcImg[index].srcImgSecondaire,
            srcImgPopUp: srcImg[index].srcImgPopUp,
            selected: false
        };
    });
}


// ROOM 7
const pathEltJson = JsonRoom7.skins.default;

  
  const getPos = (obj : TypeJsonRoom7) : number[] => {
    return [1920/2 + obj.x, 1080/2 - obj.y]
  }

export const getImg = (obj : string) : string =>  {
    switch(obj){
      case "Valise" :
        return ValiseL;
      case "BoitesMedoc" : 
        return BoiteMed;
      case "Prescription" : 
        return Prescription;
      case "Maillot" : 
        return Maillot;
      case "Creme solaire" : 
        return Creme;
      case "LunetteSoleil" : 
        return LunetteSoleil;
      case "EssuiesBG" : 
        return EssuiesBG;
      case "Essuies" : 
        return Essuies;
      case "Smartphone" : 
        return Smartphone;
      case "Flipflap" : 
        return Flipflap;
      case "Pilulier" : 
        return Pilulier;
      case "Valise2.0" : 
        return Valise2;
      default : 
        return ""
    }
  }
  
  
  // Génère la position et la taille des objets par rapport à une taille d'écran de 1920x1080
export const generationPathJson = (): TypePathJsonObjet[] => {
    const res : TypePathJsonObjet[] = [];
    if(Object.keys(pathEltJson).length > 1){
      for (const [, v] of Object.entries(pathEltJson).slice(1))
        for (const [key, value] of Object.entries(v)){
          const posObjCourant = getPos(value);
          res.push(
            {
              name : key, 
              img : getImg(key), 
              destValise1 : !["Prescription", "Smartphone", "Pilulier", "BoitesMedoc"].find(e => e === key),
              x : posObjCourant[0], 
              y : posObjCourant[1], 
              width : value.width, 
              height : value.height
            });
        }
    }
    return res;
  }
  
export const getObjectJson = (s : string, pathJson : TypePathJsonObjet[]) : TypePathJsonObjet | null => {
    if(pathJson.length > 0){
      const ob  : TypePathJsonObjet | undefined = pathJson.find(o => o.name === s && o);
      return ob || null;
    }
    return null;
  }
