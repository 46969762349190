import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import FocusTrap from "focus-trap-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import DraggableGeneric from "../../../components/animated/DraggableGeneric";
import { TypePosDraggable } from "../../../types/Draggable-type";
import BasicButton from "../../../components/buttons/BasicButton";
import myTextPopUp from "../../../helpers/textPopUp";
import ScreenSize from "../../../utils/ScreenSize";

import ImageCardCompulsive from "../../../assets/images/room6/Solitaire/Card_Picture_CompulsiveBuying.png";
import ImageCardCyberAddiction from "../../../assets/images/room6/Solitaire/Card_Picture_CyberAddiction.png";
import ImageCardGambling from "../../../assets/images/room6/Solitaire/Card_Picture_Gambling.png";
import ImageCardHyperCreativity from "../../../assets/images/room6/Solitaire/Card_Picture_HyperCreativity.png";
import ImageCardHypersexuality from "../../../assets/images/room6/Solitaire/Card_Picture_Hypersexuality.png";
import ImageCardNibbling from "../../../assets/images/room6/Solitaire/Card_Picture_Nibbling.png";
import ImageCardPunding from "../../../assets/images/room6/Solitaire/Card_Picture_Punding.png";
import ImageCardRisk from "../../../assets/images/room6/Solitaire/Card_Picture_Risk.png";

import TextCardCompulsive from "../../../assets/images/room6/Solitaire/Card_Text_CompulsiveBuying.png";
import TextCardCyberAddiction from "../../../assets/images/room6/Solitaire/Card_Text_CyberAddiction.png";
import TextCardGambling from "../../../assets/images/room6/Solitaire/Card_Text_Gambling.png";
import TextCardHyperCreativity from "../../../assets/images/room6/Solitaire/Card_Text_HyperCreativity.png";
import TextCardHypersexuality from "../../../assets/images/room6/Solitaire/Card_Text_Hypersexuality.png";
import TextCardNibbling from "../../../assets/images/room6/Solitaire/Card_Text_Nibbling.png";
import TextCardPunding from "../../../assets/images/room6/Solitaire/Card_Text_Punding.png";
import TextCardRisk from "../../../assets/images/room6/Solitaire/Card_Text_Risk.png";

import TextCardCompulsiveEN from "../../../assets/images/room6/Solitaire/Card_Text_CompulsiveBuyingEn.png";
import TextCardCyberAddictionEN from "../../../assets/images/room6/Solitaire/Card_Text_CyberAddictionEn.png";
import TextCardGamblingEN from "../../../assets/images/room6/Solitaire/Card_Text_GamblingEn.png";
import TextCardHyperCreativityEN from "../../../assets/images/room6/Solitaire/Card_Text_HyperCreativityEn.png";
import TextCardHypersexualityEN from "../../../assets/images/room6/Solitaire/Card_Text_HypersexualityEn.png";
import TextCardNibblingEN from "../../../assets/images/room6/Solitaire/Card_Text_NibblingEn.png";
import TextCardPundingEN from "../../../assets/images/room6/Solitaire/Card_Text_PundingEn.png";
import TextCardRiskEN from "../../../assets/images/room6/Solitaire/Card_Text_RiskEn.png";



type TextCard = {
    img: string;
    alt: string;
}

const Room6level4 = (): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleClick = (): void => {
        navigate("/chapitre2/room6/level5");
    };

    const screenW: number = ScreenSize().width;
    const screenH: number = ScreenSize().height;

    const getCurrentLanguage = i18next.language;

    const [destinations, setDestinations] = useState<TypePosDraggable[]>([
        { x: 60, y: 55, occuped: false, img: ImageCardCompulsive },
        { x: 75, y: 40, occuped: false, img: ImageCardCyberAddiction },
        { x: 75, y: 70, occuped: false, img: ImageCardGambling },
        { x: 90, y: 85, occuped: false, img: ImageCardHyperCreativity },
        { x: 90, y: 55, occuped: false, img: ImageCardHypersexuality },
        { x: 60, y: 85, occuped: false, img: ImageCardNibbling },
        { x: 45, y: 70, occuped: false, img: ImageCardPunding },
        { x: 45, y: 40, occuped: false, img: ImageCardRisk },
    ]);

    const [textCards, setTextCards] = useState<TextCard[]>([
        { img: getCurrentLanguage === "fr" ? TextCardCompulsive : TextCardCompulsiveEN, alt: "Achats compulsifs" },
        { img: getCurrentLanguage === "fr" ? TextCardCyberAddiction : TextCardCyberAddictionEN, alt: "Cyber Addiction" },
        { img: getCurrentLanguage === "fr" ? TextCardGambling : TextCardGamblingEN, alt: "Jeux d'argent" },
        { img: getCurrentLanguage === "fr" ? TextCardHyperCreativity : TextCardHyperCreativityEN, alt: "Hypercréativité" },
        { img: getCurrentLanguage === "fr" ? TextCardHypersexuality : TextCardHypersexualityEN, alt: "Hypersexualité" },
        { img: getCurrentLanguage === "fr" ? TextCardNibbling : TextCardNibblingEN, alt: "Grigmotage" },
        { img: getCurrentLanguage === "fr" ? TextCardPunding : TextCardPundingEN, alt: "Punding" },
        { img: getCurrentLanguage === "fr" ? TextCardRisk : TextCardRiskEN, alt: 'Le "punding" est un comportement répétitif et stéréotypé, souvent observé chez les personnes atteintes de la maladie de Parkinson, qui consiste à effectuer des tâches inutiles et sans but apparent, comme trier et recompter des objets.' },
    ]);

    const [popup, setPopup] = useState<boolean>(false);

    const [pundingDesc, setPundingDesc] = useState<boolean>(false);

    const start = { x: 10, y: 40 };

    const changeDestination = (destination: TypePosDraggable) => {
        const i = destinations.indexOf(destination);
        if (i > - 1 && !destinations[i].occuped) {
            const arr = [...destinations];
            arr[i].occuped = true;
            setDestinations(arr);
        }
    }

    useEffect(() => {
        let done = true;
        destinations.forEach((destination) => {
            if (!destination.occuped) {
                done = false;
            }
        });
        if (done) {
            setPopup(true);
        }
    }, [destinations]);

    const btnNext = t("room_six.level_four.btn_next");

    return (
        <div className="background-room6" >
            <FocusTrap
                focusTrapOptions={{
                    fallbackFocus: "#solitaire",
                    clickOutsideDeactivates: true,
                }}
            >
                <div
                    id="solitaire"
                    role="button"
                    tabIndex={0}
                    aria-label="next"
                    onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                            handleClick();
                        }
                    }}
                />
            </FocusTrap>
            <div className="instruction" role="alertdialog">
                <h1>{myTextPopUp.room6[3].title}</h1>
                <p>{myTextPopUp.room6[3].content}</p>
            </div>
            <>
                {
                    destinations.map((destination, id) => {
                        return (
                            <img
                                aria-hidden
                                alt=""
                                key={id}
                                className="solitaire-images"
                                src={destination.img}
                                style={{
                                    position: 'absolute',
                                    top: `${destination.y}%`,
                                    left: `${destination.x}%`,
                                    transform: `translate(-50%, -50%)`,
                                    width: "15%",
                                    height: "18%",
                                }}

                            />
                        )
                    })

                }

                {
                    textCards.map((textCard, id) => {
                        const dest = [];
                        dest.push(destinations[id]);
                        // checking if is punding because it is clickable
                        if (dest[0].occuped && textCard.alt === "Punding") {
                            return (
                                <div className="punding"
                                    onClick={() => {
                                        setPundingDesc(true);
                                    }}
                                >
                                    <img src={textCard.img} alt=""
                                        style={{
                                            position: 'absolute',
                                            top: dest[0].y + "%",
                                            left: dest[0].x + "%",
                                            transform: `translate(-50%, -50%)`,
                                            width: "15%",
                                            height: "18%",
                                        }}
                                    />
                                    <FontAwesomeIcon icon={faCircleInfo} style={{
                                        position: 'absolute',
                                        top: `64%`,
                                        left: '50.5%',
                                        transform: `translate(-50%, -50%)`,
                                        width: "2%",
                                        height: "3%",
                                    }} />
                                </div>

                            );
                        }
                        // checking if the draggable is at the right place 
                        else if (dest[0].occuped) {
                            return (
                                <img src={textCard.img} alt=""
                                    style={{
                                        position: 'absolute',
                                        top: dest[0].y + "%",
                                        left: dest[0].x + "%",
                                        transform: `translate(-50%, -50%)`,
                                        width: "15%",
                                        height: "18%",
                                    }}
                                />
                            )
                        }
                        // draggable image
                        return (
                            <div>
                                <DraggableGeneric
                                    key={id}
                                    startX={start.x}
                                    startY={start.y}
                                    destinations={dest}
                                    sizeDestinationX={15 / 100 * screenW}
                                    sizeDestinationY={10 / 100 * screenH}
                                    onChangeDestination={(dest: TypePosDraggable) => changeDestination(dest)}
                                    imagePath={textCard.img}
                                    heightImg="20%"
                                    widthImg="15%"
                                />
                            </div>
                        )
                    })
                }
            </>
            {
                popup && (
                    <BasicButton
                        text={btnNext}
                        click={handleClick}
                        styleB={{
                            fontSize: "3rem",
                            backgroundColor: "#055c5c",
                            color: "#ffffff",
                            borderRadius: "50px ",
                            position: "absolute",
                            top: "40%",
                            left: "20%",
                            transform: "translate(-50%, -50%)",
                        }}
                    />
                )
            }

            {
                pundingDesc && (
                    <FocusTrap
                        focusTrapOptions={{
                            fallbackFocus: "#description-punding",
                            clickOutsideDeactivates: false,
                        }}
                    >
                        <div className="pop-up-room6" id="description-punding">
                            <div className="message-room6" role="alertdialog">
                                <h1>Punding</h1>
                                <p>Le "punding" est un comportement répétitif et stéréotypé, souvent observé chez les personnes atteintes de la maladie de Parkinson, qui consiste à effectuer des tâches inutiles et sans but apparent, comme trier et recompter des objets.</p>
                            </div>
                            <BasicButton
                                text="Fermer"
                                click={() => {
                                    setPundingDesc(false);
                                }}
                                styleB={{
                                    backgroundColor: "#055C5C",
                                    color: "#fff",
                                    border: "none",
                                    padding: "15px 50px",
                                    borderRadius: "50px",
                                    fontSize: "2.5rem",
                                    boxShadow: "#000000 0px 0px 20px 0px"
                                }}
                            />
                        </div>
                    </FocusTrap>
                )
            }
        </div >
    )
}


export default Room6level4;
