import React from "react";
import { motion, Variants } from "framer-motion";
import Image from '../../assets/images/room2/Tableau_Dopamine/Icon_Dopamine.png';

function ButtonDopamine({ nameClass, src, pos_x, pos_y, click, alt, hide }: { nameClass: any, src: any, pos_x: any, pos_y: any, click: any, alt: string, hide: boolean }): JSX.Element {

  const animHidden: Variants = {
    visible: {
      opacity: 1,
      visibility: "visible"
    },
    hidden: {
      opacity: 0,
      transition: {
        duration: 1
      },
      transitionEnd: {
        visibility: "hidden"
      }
    }
  }

  const totop: string = `${pos_x}%`
  const toleft: string = `${pos_y}%`

  if (nameClass === "visible") {
    return (
      <motion.img
        className="btndopa"
        src={Image}
        initial={{ position: 'absolute', top: totop, left: toleft, opacity: 1 }}
        onClick={click}
        onKeyDown={(e: any) => {
          if (e.key === " " || e.key === "Enter") {
            click();
          }
        }}
        tabIndex={0}
        style={{
          outline: "none"
        }}
        alt={alt}
        aria-hidden={hide}
      />
    )
  };
  return (
    <motion.img
      src={Image}
      variants={animHidden}
      className="btndopa"
      initial="visible"
      animate="hidden"
      style={{
        outline: "none"
      }}
      alt=""
    />
  )
}

export default ButtonDopamine;
