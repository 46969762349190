import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import myTextPopUp from "../../../helpers/textPopUp";
import { selectParchemin } from "../../../slices/parcheminSlice";
import Heroe_InBody from "../../../assets/images/room5/Gellules_Medicaments_Enzymes/Heroe.png";
import PopUpWithButton from "../../../components/popups/PopUpWithButton";



function Room5level3v3() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const parcheminTab = useSelector(selectParchemin);

    const handleClick = (): void => {
        if (parcheminTab.parcheminTab.length > 0) navigate("/chapitre2/room5/levelParchemin");
        else navigate("/chapitre2/room6/level1");
    }

    
    const congratsOne = t("room_five.level_three.congrats.part");
    const congratsTwo = t("room_five.level_three.congrats.finally");

    return (
        <div className="containerNextRoom" style={{ backgroundColor: "#BEDDFA" }} >
            <div className="container-elements">
                <div className="container-img">
                    <img src={Heroe_InBody} alt="" />
                </div>
                <PopUpWithButton
                    title={`${myTextPopUp.room5[20].content}</br> ${(parcheminTab.parcheminTab.length > 0) ? congratsOne : congratsTwo}`}
                    style={{ borderRadius: "30px" }}
                    styleBtn={{ backgroundColor: "#055C5C", color: "white" }}
                    icon={!(parcheminTab.parcheminTab.length > 0)}
                    button={(parcheminTab.parcheminTab.length > 0) ? myTextPopUp.room5[12].button : undefined}
                    click={e => handleClick()}
                />
            </div>
        </div>
    )
}

export default Room5level3v3;
