import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { SpinePlayer } from "@esotericsoftware/spine-player";
import "@esotericsoftware/spine-player/dist/spine-player.css";

function Levelfive() {

  const navigate = useNavigate();
  const redirection = (): void => {
    navigate("/chapitre1/room3/level1");
  };

  const playerRef = useRef<SpinePlayer | null>(null);

  const atlasUrl = "/anim-perso/Salle02_Animation.atlas";
  const binaryUrl = "/anim-perso/Character_Climbstairs.skel";


  // Create and call a function initSpine() that renders the animation in 'player-container', then destroys it to avoid rendering it twice with dispose()
  useEffect(() => {
    function initSpine() {
      const player = new SpinePlayer("player-container", {
        atlasUrl,
        binaryUrl,
        premultipliedAlpha: true,
        preserveDrawingBuffer: true,
        showControls: false,
        backgroundColor: "#000000",
        alpha: true,
        animations: ["ClimbStairs"],
        viewport: {
          debugRender: false,
          x: -700,
          y: -650,
          width: 1400,
          height: 1200,
          padTop: "0%",
          padBottom: "0%",
          padLeft: "0%",
          padRight: "0%",
        },
      });

      playerRef.current = player;
    }

    initSpine();
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
      }
    };

  }, []);

  useEffect(() => {
    const timer = setTimeout(() => redirection(), 2200);
    return () => clearTimeout(timer);
  });

  return <div id="player-container" style={{ width: "100vw", height: "100vh" }} />;
};

export default Levelfive;
