import React, { Fragment, useEffect, useState } from "react";

import PadButton from "./PadButton";
import { PadButtonsRowProps } from "../../types/PadButtonsRow-types";
import { getPosition, getPourcentObj, sizeObj } from "../../utils/funcUtils";
import ScreenSize from "../../utils/ScreenSize";
import door from "../../assets/images/room1/Door_Rebus/Rebus.json";
import { pathJsonLetter, placements, styleElement } from "../../utils/utilsPlacement";
import { TypeAlphabet } from "../../utils/typesUtils";

// Position avec une certaine logique => à adapter en fonction
// Boucle ts les 13 en x, y+= 248 à chq lignes

const alphabet: Array<TypeAlphabet> = pathJsonLetter()


function SpaceButtons(props: any): JSX.Element {
    // Variable to get screen size
    const screenW: number = ScreenSize().width;
    const screenH: number = ScreenSize().height;
    const buttonsData: PadButtonsRowProps = props;
    const pathJsonBack: any = door.skins.default.Door.Door;

    type TypeObject = {
        position: Array<number>;
        size: Array<number>;
    };
    const [pourcent, setPourcent] = useState<Array<number>>(getPourcentObj(screenW, screenH, pathJsonBack));
    const [placement, setPlacement] = useState<Array<TypeObject>>(placements(screenW, screenH, alphabet, pathJsonBack));
    const style = styleElement(placement, pourcent);

    // Change the pourcent if a parameter change
    useEffect(() => {
        setPourcent(getPourcentObj(screenW, screenH, pathJsonBack));
    }, [screenW, screenH, pathJsonBack]);

    // Change size objects if a parameter change
    useEffect(() => {
        setPlacement(current =>
            current.map((obj, index) => {
                return { ...obj, size: sizeObj(screenW, screenH, pathJsonBack, alphabet[index]) };
            })
        );
    }, [pathJsonBack, screenH, screenW]);

    // Change position objects if a parameter change
    useEffect(() => {
        setPlacement(current =>
            current.map((obj, index) => {
                return { ...obj, position: getPosition(screenW, screenH, alphabet[index]) };
            })
        );
    }, [screenW, screenH]);
    return (
        <>
            {
                alphabet.slice(buttonsData.start, buttonsData.end).map((letter, index) => (
                    <PadButton
                        key={index.valueOf()}
                        letter={letter.value}
                        styleB={style[index]}
                        typing={(value: string) => {
                            buttonsData.handleClick(value);
                        }}
                    />
                ))
            }
        </>
    );
}

export default SpaceButtons;
