import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import FocusTrap from "focus-trap-react";
import PopUpWithButton from "../../../components/popups/PopUpWithButton";
import myTextPopUp from "../../../helpers/textPopUp";
import ScreenSize from "../../../utils/ScreenSize";
import { getPosition, getPourcentObj, sizeObj } from "../../../utils/funcUtils";
import SALLE1ExportScriptTest from "../../../assets/images/room1/SALLE1_FullHD.json";
import DamienHirst from "../../../assets/images/room1/Livre_DamienHirst/DamienHirst_Cover.png";
import NervousSystem from "../../../assets/images/room1/NervousSystem.png";
import BloodSystem from "../../../assets/images/room1/BloodSystem.png";
import Squelette from "../../../assets/images/room1/Squelette.png";
import BreathingSystem from "../../../assets/images/room1/BreathingSystem.png";
import Muscles from "../../../assets/images/room1/Muscles.png";
import DigestiveSystem from "../../../assets/images/room1/DigestiveSystem.png";
import Livre from "../../../assets/images/room1/Livre.png";
import ImagePopUp from "../../../components/popups/ImagePopUp";
import { pathJsonLevel1, placementLevel1, placements, styleElement } from "../../../utils/utilsPlacement";
import { TypeImgArray, TypeObjectLevel1 } from "../../../utils/typesUtils";



// Link json + position objects.
// In the following objects, there're in order:
// Portrait, Plant, Skeleton, window, training bike, an clickable object in the Book, Book
const pathJson: Array<object> = pathJsonLevel1;

function Level1(props: any): JSX.Element {
    // Variable for navigation
    const navigate = useNavigate();
    const [redirection, setRedirection] = useState<boolean>(false);
    // Variable allowing to make visible the differents response images
    const [visibleFirstPopUp, setVisibleFirstPopUp] = useState<boolean>(true);
    const [visibleResult, setVisibleResult] = useState<boolean>(false);
    const [visiblePopUpImg, setVisiblePopUpImg] = useState<boolean>(false);
    const [visiblePictureSec, setVisiblePictureSec] = useState<boolean>(false);

    // Variable to get screen size
    const screenW: number = ScreenSize().width;
    const screenH: number = ScreenSize().height;

    // Object with name and image for popUp
    type TypeObjectImgCurrent = {
        name: string;
        img: string;
    }
    const pathJsonBack: object = SALLE1ExportScriptTest.skins.default.Salle01_Background.Salle01_Background;
    const [pictureCurrent, setPictureCurrent] = useState<string>("");
    const [pictureSecCurrent, setPictureSecCurrent] = useState<string>("");
    const [popUpImgCurrent, setPopUpImgCurrent] = useState<TypeObjectImgCurrent>({ name: "", img: "" });
    const [systemFound, setSystemFound] = useState<Array<string>>(["", "", "", "", "", ""]);
    const [countSystemFound, setCountSystemFound] = useState<number>(0);
    const [popUpChangeDirection, setPopUpChangeDirection] = useState<boolean>(false);
    const [pourcent, setPourcent] = useState<Array<number>>(getPourcentObj(screenW, screenH, pathJsonBack));

    const srcImg: Array<TypeImgArray> = [
        {
            srcImgObj: "",
            srcImgSecondaire: "",
            srcImgPopUp: NervousSystem
        }, {
            srcImgObj: "",
            srcImgSecondaire: "",
            srcImgPopUp: BloodSystem,
        }, {
            srcImgObj: "",
            srcImgSecondaire: "",
            srcImgPopUp: Squelette,
        }, {
            srcImgObj: "",
            srcImgSecondaire: "",
            srcImgPopUp: BreathingSystem,
        }, {
            srcImgObj: "",
            srcImgSecondaire: "",
            srcImgPopUp: Muscles,
        }, {
            srcImgObj: DamienHirst,
            srcImgSecondaire: Livre,
            srcImgPopUp: DigestiveSystem,
        },
    ]

    // ------------------- PLACEMENT --------------------

    // This is the placement and styling of objects with all systems to find
    const [placementObj, setPlacementObj] = useState<Array<TypeObjectLevel1>>(placementLevel1(screenW, screenH, pathJsonBack, srcImg));
    const styles = styleElement(placementObj, pourcent);

    // This is the placement and styling of the book element and the book itself.
    const placementBook = placements(screenW, screenH, pathJson.slice(6, 8), pathJsonBack);
    const styleBook = styleElement(placementBook, pourcent);

    // -------------------  USE EFFECT --------------------
    // Change the pourcent if a parameter change
    useEffect(() => {
        setPourcent(getPourcentObj(screenW, screenH, pathJsonBack));
    }, [screenW, screenH, pathJsonBack]);

    // Change size objects if a parameter change
    useEffect(() => {
        setPlacementObj(current =>
            current.map((obj, index) => {
                return { ...obj, size: sizeObj(screenW, screenH, pathJsonBack, pathJson[index]) };
            })
        );
    }, [pathJsonBack, screenH, screenW]);

    // Change position objects if a parameter change
    useEffect(() => {
        setPlacementObj(current =>
            current.map((obj, index) => {
                return { ...obj, position: getPosition(screenW, screenH, pathJson[index]) };
            })
        );
    }, [screenW, screenH]);

    useEffect(() => {
        redirection && navigate("/chapitre1/room1/level2");
    }, [navigate, redirection])

    // --------------------- EVENT ---------------------

    // Action after click on First Popup

    const handleClickFirstPopUp = () => {
        setVisibleFirstPopUp(false);
    }


    const handleClickObject = (e: React.MouseEvent<HTMLInputElement>) => {
        // if the first pop up invisible,  we can click on the differents objects
        if (!visibleFirstPopUp) {
            // give the index of system object
            const i: number = getIndex(e.currentTarget.id);
            // if the object exists and has never been selected
            if (i > -1 && !placementObj[i].selected) {
                // Result visible + select all the images for the found object
                setVisibleResult(true);
                setPopUpImgCurrent({ name: placementObj[i].name, img: placementObj[i].srcImgPopUp });
                const myArr = [...placementObj];
                myArr[i].selected = true;
                setPlacementObj(myArr);
                if (placementObj[i].srcImgSecondaire) {
                    setPictureSecCurrent(placementObj[i].srcImgSecondaire);
                }
                if (placementObj[i].srcImgObj) {
                    setPictureCurrent(placementObj[i].srcImgObj);
                }
                else {
                    setVisiblePopUpImg(true);
                }
            }
        }
    }

    // Displays the second img after click on first img
    const handleClickResultImg = (e: React.MouseEvent<HTMLDivElement>) => {
        if (pictureSecCurrent && !visiblePictureSec) {
            setVisiblePictureSec(true);
        }
        else {
            setVisiblePopUpImg(true);
            if (pictureSecCurrent) {
                setVisiblePictureSec(false);
                setPictureSecCurrent("");
            }
        }
    }

    // After the last img, render result invisible
    const handleClickResultHidden = (e: React.MouseEvent<HTMLInputElement> | React.MouseEvent<HTMLDivElement>) => {
        setVisiblePopUpImg(false);
        setVisibleResult(false);
        setCountSystemFound(count => {
            const myArr = [...systemFound];
            if (popUpImgCurrent.name !== "Système digestif") myArr.splice(countSystemFound, 1, popUpImgCurrent.img);
            else myArr.splice(countSystemFound, 1, DigestiveSystem);

            setSystemFound(myArr);
            return count + 1;
        });
        // If all systems are found => display the pop up to change direction
        if (countSystemFound > 4) setPopUpChangeDirection(true);
    }

    // After clicking on the button of the pop up, change redirection value
    const handleClickRedirection = () => {
        if (popUpChangeDirection) {
            setRedirection(true);
        }
    }

    const getIndex = (idCurrent: string) => {
        return placementObj.findIndex((c) => c.id === idCurrent);
    }

    return (
        <div className="container-level1 bg-room1">
            {/* Affiche the first popup */}
            {
                visibleFirstPopUp && <PopUpWithButton
                    content={myTextPopUp.room1[0].content}
                    title={myTextPopUp.room1[0].title}
                    click={handleClickFirstPopUp}
                    button={myTextPopUp.room1[0].button} />
            }

            {/* Affiche popup to change direction */}
            {popUpChangeDirection && <PopUpWithButton
                title={myTextPopUp.room1[1].title}
                click={handleClickRedirection}
                icon />
            }

            <div className="container-objFound">
                {/* Generates 6 locations.
                if a system is found => add system image in the correct location
                */}
                {systemFound && systemFound.map((current) => {
                    return <div key={uuidv4()}> <img src={current !== null ? current : ""} alt="" /></div>
                })}
            </div>

            {/* Display management for each system found */}
            {visibleResult && <FocusTrap>
                <div className="result"
                    role="button"
                    tabIndex={0}
                    aria-hidden="true"
                    onClick={e => {
                        if (visiblePopUpImg) handleClickResultHidden(e);
                        else if (!visiblePictureSec) handleClickResultImg(e);
                    }}>
                    {/* else check the display of the secondary picture */}
                    {(() => {
                        if (visiblePictureSec) {
                            return (
                                <>
                                    <input type="button" className="container-images transparent cursorDefault"
                                        style={styleBook[0]} onClick={handleClickResultImg} tabIndex={0} />
                                    <input type="image" className="defaultCursor" style={styleBook[1]}
                                        src={pictureSecCurrent}
                                        alt="livre" />
                                </>
                            );
                        }
                        if (visiblePopUpImg) {
                            return (

                                <ImagePopUp path={popUpImgCurrent.img}
                                    imageName={popUpImgCurrent.name}
                                    alternative
                                />
                            );
                        }
                        return (
                            <input type="image"
                                className="firstImage"
                                src={pictureCurrent}
                                alt="Couverture du livre A B C par Damien Hirst" />);
                    })()}
                </div>
            </FocusTrap>
            }

            {/* Objects to find on the page */}
            {placementObj.map((obj, i) => {
                return (<input className="container-images transparent"
                    key={uuidv4()}
                    id={`${obj.id}`}
                    type="button"
                    aria-label={obj.name}
                    aria-hidden="true"
                    style={styles[i]}
                    onClick={e => handleClickObject(e)} />);
            })}
        </div>

    );
}

export default Level1;
