import React from "react";
import FocusTrap from "focus-trap-react";
import { useNavigate } from "react-router-dom";
import BasicButton from "../../../components/buttons/BasicButton";
import myTextPopUp from "../../../helpers/textPopUp";

function Room6level3() {

    const navigate = useNavigate();
    const handleClick = (): void => {
        navigate("/chapitre2/room6/level4");
    };

    return (
        <FocusTrap>
            <div className="background-room6">
                <div className="pop-up-room6">
                    <div className="message-room6" role="alertdialog">
                        <h1>{myTextPopUp.room6[2].title}</h1>
                        <p>{myTextPopUp.room6[2].content}</p>
                    </div>
                    <BasicButton
                        text={myTextPopUp.room6[2].button}
                        click={handleClick}
                        styleB={{
                            backgroundColor: "#055C5C",
                            color: "#fff",
                            border: "none",
                            padding: "15px 50px",
                            borderRadius: "50px",
                            fontSize: "3rem"
                        }}
                    />
                </div>
            </div>
        </FocusTrap>
    )
}

export default Room6level3
