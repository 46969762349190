import i18n from "../i18n";

const myTextPopUp = {
  room1: [
    {
      title: i18n.t("room_one.level_one.title"),
      content: i18n.t("room_one.level_one.subtitle"),
      button: i18n.t("room_one.level_one.button"),
    },
    {
      title: i18n.t("room_one.level_two.title"),
      content: "",
      button: "<FontAwesomeIcon icon={faArrowRight}/>",
    },
    {
      title: "Oh! Le tableau du système nerveux se décale...",
      content: "",
      button: "<FontAwesomeIcon icon={faArrowRight}/>",
    },
    {
      title: "Il semble que cette porte soit fermée par un code",
      content: "",
      button: "Regarder de plus près",
    },
    {
      title: i18n.t("room_one.level_five.title"),
      content: i18n.t("room_one.level_five.content"),
      button: i18n.t("room_one.level_five.next_btn"),
    },
    {
      title: i18n.t("room_one.level_five.door_unlocked"),
      content: "",
      button: i18n.t("room_one.level_five.open_door"),
    },
  ],
  room2: [
    {
      title: i18n.t("room_two.level_one.title"),
      content: i18n.t("room_two.level_one.content"),
      button: i18n.t("room_two.level_one.btn"),
    },
    {
      title: "Le fonctionnement de la dopamine",
      content: "Une information est transmise d’un neurone à un autre par un messager (ou neurotransmetteur) appelé dopamine. La dopamine produite par le neurone « émetteur » va se fixer sur un neurone « récepteur », et permettre de transmettre l’influx nerveux.",
      button: "Compris",
    },
    {
      title: "",
      content: "",
      button: "Passons à la pièce suivante",
    },
    {
      title: i18n.t("room_two.level_four.title"),
      content: i18n.t("room_two.level_four.first_text"),
      button: i18n.t("room_two.level_four.btn"),
    },
    {
      title: i18n.t("room_two.level_four.success_title"),
      content: i18n.t("room_two.level_four.success_text"),
      button:   i18n.t("room_two.level_four.success_btn"),
    },
  ],
  room3: [
    {
      title: i18n.t("room_three.level_one.title"),
      contents: [
        i18n.t("room_three.level_one.contents.first"),
        i18n.t("room_three.level_one.contents.second"),
        i18n.t("room_three.level_one.contents.third"),
        i18n.t("room_three.level_one.contents.fourth"),
      ],
      button: i18n.t("room_three.level_one.btn"),
    },
    {
      title: i18n.t("room_three.level_one.sublevel_one.mesocortical_pathway.title"),
      content: i18n.t("room_three.level_one.sublevel_one.mesocortical_pathway.description"),
      button: i18n.t("room_three.level_one.sublevel_one.btn_pathway"),
    },
    {
      title: i18n.t("room_three.level_one.sublevel_one.mesolimbic_pathway.title"),
      content: i18n.t("room_three.level_one.sublevel_one.mesolimbic_pathway.description"),
      button: i18n.t("room_three.level_one.sublevel_one.btn_pathway")
    },
    {
      title:  i18n.t("room_three.level_one.sublevel_one.nigrostriatal_pathway.title"),
      content: i18n.t("room_three.level_one.sublevel_one.nigrostriatal_pathway.description"),
      button:   i18n.t("room_three.level_one.sublevel_one.btn_pathway")
    }
  ],
  room4: [
    {
      title: i18n.t("room_four.level_two.exercice_climbing.title"),
      content: i18n.t("room_four.level_two.exercice_climbing.subtitle"),
    },
    {
      title: i18n.t("room_four.level_two.exercice_climbing.guideline"),
      button: i18n.t("room_four.level_two.exercice_climbing.btn_start"),
    },
    {
      title: i18n.t("room_four.level_two.exercice_climbing.results.slow"),
      content: i18n.t("room_four.level_two.exercice_climbing.results.slow_desc"),
      button: i18n.t("room_four.level_two.exercice_climbing.btn"),
    }, 
    {
      title: i18n.t("room_four.level_two.exercice_calligraphy.title"),
      content: i18n.t("room_four.level_two.exercice_calligraphy.subtitle"),
    },
    {
      title: i18n.t("room_four.level_three.congrats"),
      content: i18n.t("room_four.level_three.desc"),
      button: i18n.t("room_four.level_three.btn_next"),
    },
  ],
  room5: [
    {
      title: i18n.t("room_five.level_one.title"),
      contents: [i18n.t("room_five.level_one.description.first"),
        `<strong>${i18n.t("room_five.level_one.description.second")}</strong>`],
      button: i18n.t("room_five.level_one.btn"),
    },
    {
      title: "",
      contents: [i18n.t("room_five.level_one.guideline.first"),
      `<strong>${i18n.t("room_five.level_one.guideline.second")}</strong>`],
      button: i18n.t("room_five.level_one.btn_start"),
    },
    {
      title: "Légende paracétamol",
      content: i18n.t("room_five.level_one.paracetamol"),
      button: i18n.t("room_five.level_one.btn_search"),
    },
    {
      title: i18n.t("room_five.level_one.first_drug.name"),
      content: i18n.t("room_five.level_one.first_drug.content"),
      button: i18n.t("room_five.level_one.btn_search"),
    },
    {
      title: i18n.t("room_five.level_one.second_drug.name"),
      content: i18n.t("room_five.level_one.second_drug.content"),
      button: i18n.t("room_five.level_one.btn_search"),
    },
    {
      title: i18n.t("room_five.level_one.third_drug.name"),
      content:  i18n.t("room_five.level_one.third_drug.content"),
      button: i18n.t("room_five.level_one.btn_search"),
    },
    {
      title: "Le pharmacien vous échange un parchemin contre un comprimé / gélule. Lequel lui donnez-vous ?",
      content: "",
      button: "",
    },
    {
      title: i18n.t("room_five.level_parchemin.scrolls_available.first_scroll.name"),
      content: i18n.t("room_five.level_parchemin.scrolls_available.first_scroll.description"),
      list: i18n.t("room_five.level_parchemin.scrolls_available.first_scroll.list"),
      element1: i18n.t("room_five.level_parchemin.scrolls_available.first_scroll.element1"),
      element2: i18n.t("room_five.level_parchemin.scrolls_available.first_scroll.element2"),
      element3: i18n.t("room_five.level_parchemin.scrolls_available.first_scroll.element3"),
      button: "",
    },
    {
      title: "",
      content: i18n.t("room_five.level_parchemin.popup_text"),
      button: i18n.t("room_five.level_parchemin.btn"),
    },
    {
      title: "",
      content: i18n.t("room_five.level_three.mode_action.step_one"),
      button: "",
    },
    {
      title: "",
      content: i18n.t("room_five.level_three.mode_action.step_two"),
      button: "",
    },
    {
      title: "",
      content: i18n.t("room_five.level_three.mode_action.step_three"),
      button: "",
    },
    {
      title: "",
      content: i18n.t("room_five.level_three.congrats_one"),
      button: i18n.t("room_five.level_three.return_btn"),
    },
    {
      title: "",
      content: "Le pharmacien vous échange un parchemin contre un comprimé / gélule. Lequel lui donnez-vous ?",
      button: "",
    },
    {
      title: i18n.t("room_five.level_parchemin.scrolls_available.third_scroll.name"),
      content: i18n.t("room_five.level_parchemin.scrolls_available.third_scroll.description"),
      list: i18n.t("room_five.level_parchemin.scrolls_available.third_scroll.list"),
      element1: i18n.t("room_five.level_parchemin.scrolls_available.third_scroll.element1"),
      element2: i18n.t("room_five.level_parchemin.scrolls_available.third_scroll.element2"),
      element3: i18n.t("room_five.level_parchemin.scrolls_available.third_scroll.element3"),
      element4: i18n.t("room_five.level_parchemin.scrolls_available.third_scroll.element4"),
      page2: i18n.t("room_five.level_parchemin.scrolls_available.third_scroll.page2"),
      button: "",
    },
    {
      title: "Vous arrivez à la jonction entre deux neurones. Amenez les agonistes dopaminergiques au neurone récepteur",
      content: "Vous connaissez désormais le mode de fonctionnement des agonistes dopaminergiques. Découvrez celui des autres médicaments !",
      button: "Retour à la pharmacie",
    },
    {
      title: "",
      content: "Donnez le dernier parchemin au pharmacien, il vous l'échangera contre un comprimé / gélule.",
      button: "",
    },
    {
      title: i18n.t("room_five.level_parchemin.scrolls_available.second_scroll.name"),
      content: i18n.t("room_five.level_parchemin.scrolls_available.second_scroll.description"),
      list: i18n.t("room_five.level_parchemin.scrolls_available.second_scroll.list"),
      sublist1: i18n.t("room_five.level_parchemin.scrolls_available.second_scroll.sublist1"),
      element1: i18n.t("room_five.level_parchemin.scrolls_available.second_scroll.element1"),
      element2: i18n.t("room_five.level_parchemin.scrolls_available.second_scroll.element2"),
      sublist2: i18n.t("room_five.level_parchemin.scrolls_available.second_scroll.sublist2"),
      element3: i18n.t("room_five.level_parchemin.scrolls_available.second_scroll.element3"),
      element4: i18n.t("room_five.level_parchemin.scrolls_available.second_scroll.element4"),
      button: "",
    },
    {
      title: "",
      content: "Le pharmacien vous donne une gélule. Vous passez à la prochaine étape. ",
      button: "Découvrir le mode d'action de ce médicament",
    },
    {
      title: "Le pharmacien vous donne un comprimé / gélule. Vous passez à la prochaine étape.",
      content: "Vous connaissez désormais le mode de fonctionnement des inhibiteurs de la dégradation de la dopamine. Bravo ! Vous avez réussi cette partie",
      button: "",
    },
    {
      title: "",
      content: i18n.t("room_five.level_three.congrats_three"),
      button: i18n.t("room_five.level_three.return_btn"),
    },
    {
      title: "",
      content: i18n.t("room_five.level_three.congrats_two"),
      button: i18n.t("room_four.level_three.btn_next"),
    },
    {
      title: i18n.t("room_five.level_one.drugs_acts.title"),
      list: [`<strong>${i18n.t("room_five.level_one.drugs_acts.first_way")}</strong>`, `<strong>${i18n.t("room_five.level_one.drugs_acts.second_way")}</strong>`,`<strong>${i18n.t("room_five.level_one.drugs_acts.third_way")}</strong>`],
      bottomText: i18n.t("room_five.level_one.drugs_acts.text"),
    },
  ],

  room6: [
    {
      title: "",
      p1: i18n.t("room_six.level_one.p_one"),
      p2: i18n.t("room_six.level_one.p_two"),
      p3: i18n.t("room_six.level_one.p_three"),
      button: i18n.t("room_six.level_one.button"),
    },
    {
      title: i18n.t("room_six.level_two.name"),
      content: i18n.t("room_six.level_two.instructions"),
      button: "",
    },
    {
      title: i18n.t("room_six.level_three.congratulations.first"),
      content: i18n.t("room_six.level_three.congratulations.second"),
      button: i18n.t("room_six.level_three.btn"),
    },
    {
      title: i18n.t("room_six.level_four.name"),
      content: i18n.t("room_six.level_four.instructions"),
      button: "",
    },
    {
      title: i18n.t("room_six.level_five.congratulations.first"),
      p1: i18n.t("room_six.level_five.congratulations.second"),
      p2: i18n.t("room_six.level_five.congratulations.third"),
      button: i18n.t("room_six.level_five.btn"),
    },
    {
      title: i18n.t("room_six.level_six.title"),
      content: "",
      button: i18n.t("room_six.level_six.button"),
    },
  ],
  room7: [
    {
      title: `${i18n.t("room_seven.level_one.title")} ${i18n.t("room_seven.level_one.subtitle")}`,
      content: "",
      button: i18n.t("room_seven.level_one.btn"),
    },
    {
      title: i18n.t("room_seven.level_one.popup_tip_three.text"),
      content: "",
      button: i18n.t("room_seven.level_one.popup_tip_three.btn"),
    },
    {
      title: i18n.t("room_seven.level_one.popup_tip_one.text"),
      content: "",
      button: i18n.t("room_seven.level_one.popup_tip_one.btn"),
    },
    {
      title: i18n.t("room_seven.level_one.popup_tip_two.text"),
      content: "",
      button: i18n.t("room_seven.level_one.popup_tip_two.btn"),
    },
    {
      title: i18n.t("room_seven.level_one.popup_tip_four.text"),
      content: "",
      button: i18n.t("room_seven.level_one.popup_tip_four.btn"),
    },
    {
      title: "Bravo ! Vous êtes fin prêt pour vous envoler",
      content: "",
      button: "C'est parti !",
    },

  ],
  room8: [{
    title: i18n.t("room_eight.level_two.dance_text"),
    content: `<strong>${i18n.t("room_eight.level_two.dance_subtitle")}</strong>`,
    button: "",
  }, {
    title: i18n.t("room_eight.level_two.finally"),
    button: "<FontAwesomeIcon icon={faArrowRight}/>",
  },
  {
    contents: [i18n.t("room_eight.level_two.text.first"),
      i18n.t("room_eight.level_two.text.second"),
    ]
  }
  ],
  room9: [
    // Popups containing buttons

    // 0
    {
      title: i18n.t("room_nine.level_one.popup_text"),
      content: "",
      button: i18n.t("room_nine.level_one.popup_btn"),
    },
    // 1 
    {
      title: i18n.t("room_nine.level_one.question"),
      content: "",
      button: "",
    },
    // 2
    {
      title: i18n.t("room_nine.level_one.answers.first"),
      content: i18n.t("room_nine.level_one.answers.second"),
    },
    // 3 
    {
      title: i18n.t("room_nine.level_one.answers.third"),
      content: i18n.t("room_nine.level_one.answers.fourth"),
    },
    // 4 
    {
      title: i18n.t("room_nine.level_one.excursion_one"),
      content: "",
      button: i18n.t("room_nine.level_one.btn_start"),
    },
    // 5 
    {
      title: i18n.t("room_nine.level_two.options.one.title"),
      content: i18n.t("room_nine.level_two.options.one.description"),
      button: "",
    },
    // 6 
    {
      title: "Option 2",
      content: "Je <strong>décale toutes mes prises,</strong> pour m’assurer d’être en forme pour la soirée, en veillant au respect de l’intervalle horaire entre deux prises tel que défini sur mon ordonnance.",
      button: "",
    },
    // 7 
    {
      title: "Option 3",
      content: "Je <strong>respecte la prescription médicale</strong> et si besoin, j’ajoute une prise supplémentaire de Lévodopa le soir, en veillant au respect de l’intervalle horaire tel que défini sur mon ordonnance.",
      button: "",
    },
    // 8 
    {
      title: "Option 4",
      content: "Je <strong>respecte la prescription médicale</strong> et si besoin, j’ajoute une <strong>prise supplémentaire d’agoniste dopaminergique</strong> le soir, en veillant au respect de l’intervalle horaire tel que défini sur mon ordonnance.",
      button: "",
    },
    // 9
    {
      title: "Option 5",
      content: "Je <strong>respecte la prescription médicale</strong> et si besoin, j’ajoute une <strong>prise supplémentaire d’inhibiteur de la dégradation de la Dopamine</strong> le soir, en veillant au respect de l’intervalle horaire tel que défini sur mon ordonnance.",
      button: "",
    },
    // 10 
    {
      title: "Bravo, vous mettez les chances de votre côté pour passer une magnifique journée! Mes symptômes sont améliorés rapidement pour bien débuter ma journée et je suis aussi amélioré le soir grâce à une prise supplémentaire de Lévodopa.",
      content: "",
      button: "<FontAwesomeIcon icon={faArrowRight}/>",
    },
    // 11 
    {
      title: "Bravo ! Vous avez bien adapté votre ordonnance Voici quelques règles d’or utiles lorsque vous devez adapter votre ordonnance",
      content: "",
      button: "",
    },
    // 12 
    {
      title: i18n.t("room_nine.level_three.text"),
      content: "",
      button: "<FontAwesomeIcon icon={faArrowRight}/>",
    },
    // 13
    {
      title: "Je me réveille plus tôt que d’habitude pour l’excursion dans les plantations de thé. Cette situation est également valable pour d’autres situations de la vie courante dans lesquelles je dois me lever plus tôt que d’habitude.",
      content: "",
      button: "C'est parti",
    },
    // 14
    {
      title: i18n.t("room_nine.level_three.second_text"),
      content: "",
      button: "<FontAwesomeIcon icon={faArrowRight}/>",
    },
    // 15
    {
      title:  i18n.t("room_nine.level_three.third_text"),
      content: "",
      button: "<FontAwesomeIcon icon={faArrowRight}/>",
    },
    // 16
    {
      title: "",
      content: "",
      button: "<FontAwesomeIcon icon={faArrowRight}/>",
    },
    // 17
    {
      title: `${i18n.t("room_nine.level_three.fourth_text")} <a href="https://diampark.io/" target="_blank" style="color: #055C5C; text-decoration: none; border-bottom: 3px solid #055C5C;">DiamPark</a> ${i18n.t("room_nine.level_three.fifth_text")}`,
      content: "",
    },
    // 18
    {
      title: i18n.t("room_nine.level_three.other_pdt"),
      content: "",
    },
    // 19

    // popups without buttons
    {
      title: "",
      content: "Deux activités vous sont proposées par l’hôtel, pour découvrir le pays. Elles conduiront à adapter votre traitement. <p><strong>Laquelle voulez-vous réaliser ?</strong></p>",
    },
    // 20
    {
      title: i18n.t("room_nine.level_two.question"),
      content: "",
    },
    // 21
    {
      title: i18n.t("room_nine.level_one.excursion_two"),
      content: "",
      button: "C’est parti",
    },
    // 22
    {
      title: i18n.t("room_nine.excursion_two.question"),
      content: "",
    },
  ],
};

export default myTextPopUp;
