import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OptionProps } from "../../types/OptionPopUp-type";
import BasicButton from "../buttons/BasicButton";
import ScreenSize from "../../utils/ScreenSize";
import {optionsText, optionsInfo, optionsInfo2, optionsText2} from "../../helpers/optionsText";

const parse = require("html-react-parser");

export default function OptionPopUp(props: OptionProps): JSX.Element {
    const { t } = useTranslation();
    const screenH: number = ScreenSize().height;


    const options: OptionProps = props;

    const [reRender, setReRender] = useState<boolean>(false);
    const [vacation, setVacation] = useState<string | null>("");

    useEffect(() => {
       // get "_vacation" from local storage
        const option = localStorage.getItem("_vacation");
        setVacation(option);
    }, []);

    const btnTryAgain = t("room_one.level_two.button_tryagain");

    return (
        <div>
            {
                !reRender ? (
                    <div>
                        {
                            options.imageNumber && options.imageNumber < 6 && (
                                <div
                                    role="button"
                                    tabIndex={0}
                                    className="basicButton"
                                    onClick={() => {
                                        if (options.clickable) {
                                            options.click && options.click();
                                            setReRender(true);
                                        }
                                    }}
                                    onKeyDown={(e) => {
                                        if (options.clickable && e.key === "Enter" || e.key === " ") {
                                            setReRender(true);
                                        }
                                    }}
                                    style={{
                                        cursor: options.clickable ? "pointer" : "default",
                                        width: "86%",
                                        height: `${screenH * 0.15}px`,
                                        backgroundColor: "#fff",
                                        borderRadius: "50px",
                                        marginBottom: "5px",
                                        marginLeft: "7rem",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        gap: "16px",
                                        boxSizing: "border-box",
                                        padding: "20px 25px",
                                        color: "#111111"
                                    }}
                                >
                                    <div style={{display: "flex", flexDirection: "column", gap: "6px"}}>
                                        <h3 style={{fontSize: "16px", whiteSpace: "nowrap", margin: 0}}>Option {optionsInfo[options.imageNumber - 1].id}</h3>

                                        {vacation === "elephants" ? optionsInfo2[options.imageNumber - 1].text(): optionsInfo[options.imageNumber - 1].text()}
                                    </div>
                                    <img src={vacation === "elephants" ? optionsInfo2[options.imageNumber - 1].img : optionsInfo[options.imageNumber - 1].img} alt="option" style={{ width: "30%"}}/>
                                </div>
                            )
                        }
                    </div>
                ) : (
                    <div>
                        {
                            options.imageNumber && options.imageNumber < 6 && (
                                <div>
                                    {
                                        options.imageNumber === 3 ? (
                                            <div style={{
                                                maxWidth: "90%",
                                                width: "86%",
                                                height: `${screenH * 0.15}px`,
                                                marginLeft: "7rem",                            
                                                backgroundColor: "#71de9f",
                                                borderRadius: "50px",
                                                marginBottom: "5px",
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "16px",
                                                boxSizing: "border-box",
                                                padding: "20px 20px",
                                                color: "#111111"
                                            }}>
                                                    {vacation === "elephants" ? (
                                                <p style={{fontSize: "12px", lineHeight: "1.4", width: "83%"}}>                                                        
                                                    <em>{t("room_nine.excursion_two.answers.option_three")}</em>
                                                </p>
                                                    ) : (
                                                <p style={{fontSize: "12px", lineHeight: "1.4", width: "95%"}}>                                   
                                                    <em>{t("room_nine.level_two.options.three.correct")}</em>
                                                </p>

                                                    )}
                                                <BasicButton
                                                    icon
                                                    arrowBlack
                                                    styleB={{
                                                        backgroundColor: "white",
                                                        color: "black",
                                                    }}
                                                    click={options.validate ? options.validate : () => { }}
                                                />
                                            </div>
                                        ) : (
                                            <div
                                                className="option-button"
                                                style={{
                                                    width: "86%",
                                                    height: `${screenH * 0.15}px`,
                                                    marginLeft: "7rem",
                                                    backgroundColor: "#FE8383",
                                                    borderRadius: "50px",
                                                    marginBottom: "5px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "16px",
                                                    boxSizing: "border-box",
                                                    padding: "20px 25px",
                                                    color: "#111111",
                                                }}
                                            >
                                                <p
                                                    style={{fontSize: "12px", lineHeight: "1.4", width: "75%"}}
                                                >
                                                    {vacation === "elephants" ? parse(optionsText2.fr[options.imageNumber - 1].content) :parse(optionsText.fr[options.imageNumber - 1].content)}
                                                </p>
                                                <BasicButton
                                                    text={btnTryAgain}
                                                    styleB={{
                                                        backgroundColor: "white",
                                                        color: "black",
                                                    }}
                                                    click={() => {
                                                        options.click && options.click();
                                                        setReRender(false);
                                                    }}
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                            )
                        }
                    </div>
                )
            }
        </div >
    );
}
