import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import fr from "./translations/fr/fr.json";
import en from "./translations/en/en.json";

const resources = {
  fr: {
    translation: fr,
  },
  en: {
    translation: en,
  },
};

const LANGUAGE_STORAGE_KEY = "selectedLanguage";

const getSavedLanguage = () => {
  const selectedLanguage = localStorage.getItem(LANGUAGE_STORAGE_KEY);
  return selectedLanguage || "fr"; // Valeur par défaut si aucune langue n'a été enregistrée
};

const setLanguage = (lng: string) => {
  localStorage.setItem(LANGUAGE_STORAGE_KEY, lng);
};

i18n.use(initReactI18next).init({
  resources,
  lng: getSavedLanguage(),
  interpolation: {
    escapeValue: false,
  },
});

export { setLanguage };
export default i18n;
