// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import FocusTrap from "focus-trap-react";
import Arrow from "../../../assets/images/room2/Tableau_Dopamine/Arrow 9 1.png";
import JonctionNeurone from "../../../components/animated/JonctionNeurone";
import Heroe_InBody from "../../../assets/images/room5/TableauSalle05/Heroe_InBody_copy_2.png";
import DraggableGeneric from "../../../components/animated/DraggableGeneric";
import { TypePosDraggable } from "../../../types/Draggable-type";
import ScreenSize from "../../../utils/ScreenSize";
import myTextAria from "../../../helpers/textAria";
import { sizeObj } from "../../../utils/funcUtils";


function Room5level3v2() {
    const { t } = useTranslation();
    const location = useLocation();
    const screenW: number = ScreenSize().width;
    const screenH: number = ScreenSize().height;
    const navigate = useNavigate();
    const handleClick = useCallback(() => {
        navigate("/chapitre2/room5/level3.3");
    }, [navigate])

    const arrowRef = useRef<HTMLDivElement>(null);
    const [leftArrow, setLeftArrow] = useState<number>(15);
    const [compte, setCompte] = useState<number>(0);
    const [destinations, setDestinations] = useState<TypePosDraggable[]>([
        { x: 61.5, y: 38.7, occuped: false },
        { x: 59.8, y: 46.1, occuped: false },
        { x: 58.8, y: 53.8, occuped: false },
        { x: 58.8, y: 61.5, occuped: false },
        { x: 59.9, y: 69.2, occuped: false },
        { x: 61.6, y: 76.8, occuped: false },
    ]);

    const [sizeDestination, setSizeDestination] = useState<number[]>(
        sizeObj(screenW, screenH,{width : 1920, height : 1080}, {width : 200, height : 75})
    );

    const start = ([
        { x: 45, y: 40 },
        { x: 42, y: 48 },
        { x: 44, y: 56 },
        { x: 48, y: 59 },
        { x: 47, y: 68 },
        { x: 46, y: 71 },
    ])


    useEffect(() => {
        if (compte === 6) {
            setLeftArrow(77);
            if (arrowRef.current) {
                arrowRef.current.focus();
                arrowRef.current.addEventListener("click", handleClick);
                arrowRef.current.addEventListener("keydown", (e: KeyboardEventInit): void => {
                    if (e.key === "Enter") { handleClick(); }
                });
            }
        }
    }, [compte, handleClick]);

    useEffect(() => {
        setSizeDestination(sizeObj(screenW, screenH,{width : 1920, height : 1080}, {width : 200, height : 75}));
    }, [screenH, screenW])


    
    const changeDestination = (destination: TypePosDraggable) => {
        const i = destinations.indexOf(destination);
        if (i > - 1 && !destinations[i].occuped) {
            const arr = [...destinations];
            arr[i].occuped = true;
            setDestinations(arr);
        }
    }

    const getTextAria = (): string => {
        const [levodopa, agoniste, inhibiteur] = myTextAria.draggable;
        let r = "";
        if (myTextAria.draggable) {
            if (location.state.choiceMed === "Lévodopa") {
                r = levodopa;
            } else if (location.state.choiceMed === "Dopaminergique") {
                r = agoniste;
            }
            else {
                r = inhibiteur;
            }
        }
        return r;
    }


    return (
        <FocusTrap>
            <div id="roomHeroe">

                <div
                    role="button"
                    tabIndex={0}
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "20px",
                        height: "20px"
                    }}
                    onKeyDown={(e) => {
                        e.preventDefault();
                        if (e.key === "Enter" || e.key === " ") {
                            handleClick();
                        }
                    }}
                    onClick={() => handleClick()}
                    aria-label={getTextAria()}
                />


                <JonctionNeurone
                    paragraphe={`${t("room_five.level_three.third_title.first")} <span style="color :#24B5CA">${t("room_five.level_three.third_title.second")}</span> ${t("room_five.level_three.third_title.third")}`}
                    elementJonction={
                        <>
                            <div
                                role="button"
                                aria-hidden
                                tabIndex={-1}
                                ref={arrowRef}
                            >
                                <img src={Arrow} aria-hidden id="arrowBis"
                                    aria-label="appuies pour aller au niveau suivant"
                                    style={{ left: `${leftArrow}%` }}
                                    alt="arrow" />
                            </div>
                            <div
                                style={{
                                    zIndex: "3"
                                }}
                                className="agonistes-draggable"
                            >
                                {
                                    start.map((elt, i) =>
                                            <DraggableGeneric
                                                dragConstraints={{ right: screenW/2, left: -screenW/3, top: -screenH/3, bottom: screenH/2}}
                                                startX={elt.x}
                                                startY={elt.y}
                                                destinations={destinations}
                                                imagePath={Heroe_InBody}
                                                sizeDestinationX = {sizeDestination[0]}
                                                sizeDestinationY = {sizeDestination[1]}
                                                heightImg="6%"
                                                widthImg="2.5%"
                                                onChangeDestination={(dest: TypePosDraggable) => changeDestination(dest)}
                                                onFinish={() => setCompte(compte + 1)} />
                                    )
                                }

                            </div>
                        </>
                    }
                    useBackground
                />
            </div>

           
           
        </FocusTrap>
    )
}

export default Room5level3v2;
