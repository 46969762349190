import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from "uuid";
import FocusTrap from "focus-trap-react";
import Gellule01Big from "../../assets/images/room5/Gelule01_Big.png";
import Gellule02Big from "../../assets/images/room5/Gelule02_Big.png";
import Gellule03Big from "../../assets/images/room5/Gelule03_Big.png";
import myTextPopUp from "../../helpers/textPopUp";
import Document_Hide from "../../assets/images/room5/Document_Hide.png";
import PopUpWithButton from "../popups/PopUpWithButton";
import { selectParchemin, changeTab } from "../../slices/parcheminSlice";

const parse = require('html-react-parser');

function ChoixParchemin() {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const parcheminTab = useSelector(selectParchemin);
    const dispatch = useDispatch();
    const handleClick = (): void => {
        const arr: string[] = [...parcheminTab.parcheminTab];
        arr.splice(arr.indexOf(choiceCourant), 1);
        dispatch(changeTab(arr));
        navigate(navCourant, {
            state: {
                choiceMed: choiceCourant
            }
        } as any);
    };

    interface Objetbouton {
        id: number;
        nameMed: string;
        img: string;
    }

    const initObjectBouton = (): Objetbouton[] => {
        let i = 0;
        const res: Objetbouton[] = [];
        while (i < parcheminTab.parcheminTab.length) {
            res.push({
                id: i + 1,
                nameMed: parcheminTab.parcheminTab[i],
                img: Document_Hide
            });
            i += 1;
        }
        return res;
    }

    const [choix, setchoix] = useState<boolean>(true);
    const [Levodopa, setLevodopa] = useState<boolean>(false);
    const [dopa, setDopa] = useState<boolean>(false);
    const [inhibiteur, setinhibiteur] = useState<boolean>(false);
    const image: Objetbouton[] = initObjectBouton();
    const [pageNext, setPageNext] = useState<boolean>(false);
    const [choiceCourant, setChoiceCourant] = useState<string>("");
    const [navCourant, setNavCourant] = useState<string>("");
    const [popUp1, setPopUp1] = useState<boolean>(false);

    // to print the right capsule img
    const [capsuleID, setCapsuleID] = useState<number>(0);
    const capsules: string[] = [Gellule01Big, Gellule02Big, Gellule03Big];


    const handleChoix = (i: number) => {
        setchoix(false);
        if (parcheminTab.parcheminTab[i] === "Lévodopa") setLevodopa(true);
        else if (parcheminTab.parcheminTab[i] === "Dopaminergique") setDopa(true);
        else setinhibiteur(true);
        setChoiceCourant(parcheminTab.parcheminTab[i]);
        setNavCourant(getNav(parcheminTab.parcheminTab[i]));
    }

    const getNav = (med: string) => {
        if (med === "Lévodopa") return "/chapitre2/room5/level2";
        if (med === "Dopaminergique") return "/chapitre2/room5/level3";
        return "/chapitre2/room5/level4";
    }

    const firstDrug = t("room_five.level_one.first_drug.name");
    const secondDrug = t("room_five.level_one.second_drug.name");
    const thirdDrug = t("room_five.level_one.third_drug.name");
    const errorMssg = t("room_five.level_one.error");

    const getNameCompleteMed = (s: string): string => {
        // const res = (i: number) => myTextPopUp.room5[i].title.slice(0, 1).toUpperCase() + myTextPopUp.room5[i].title.slice(1).toLowerCase();

        let c;
        switch (s) {
            case "Lévodopa":
                // c = res(3);
                c = firstDrug;
                break;
            case "Dopaminergique":
                // c = res(4);
                c = secondDrug;
                break;
            case "Inhibiteur":
                // c = res(5);
                c = thirdDrug;
                break;
            default:
                c = errorMssg;
                break;
        }
        return c;
    }

    const textParchemin = t("room_five.level_parchemin.title");
    const btnNext = t("room_five.level_parchemin.btn_next");
    const btnClose = t("room_five.level_parchemin.btn_close");

    return (
        <div className="back_level_1_room_5">
            {
                choix ? (
                    <FocusTrap
                        focusTrapOptions={{
                            fallbackFocus: ".choix_med",
                        }}
                    >
                        <div className="choix_med" role="alertdialog" aria-modal="true" >
                            {
                                image.map((elt, i) => <div className={`parchemin-button${i + 1}`} key={elt.id}>
                                    <p>{parse(getNameCompleteMed(elt.nameMed))}</p>
                                    <button type="submit" className="inputmed" tabIndex={0} aria-label={elt.nameMed} onClick={(e) => {
                                        handleChoix(i);
                                    }}>
                                        <img src={elt.img} alt="" />
                                    </button>
                                </div>)
                            }
                            <p
                                style={{
                                    fontSize: "3rem",
                                }}
                            >{textParchemin}</p>
                        </div>
                    </FocusTrap>

                ) : (
                    <div className="inventory">
                        {
                            [...Array(parcheminTab.parcheminTab.length)].map((elt, i) => <img key={uuidv4()}
                                src={Document_Hide}
                                alt={`document ${i + 1}`}
                                aria-hidden
                                tabIndex={-1}
                                style={{ transform: 'rotate(35deg)' }}
                                className={`imgdoc${i + 1}`} />)
                        }
                    </div>
                )
            }

            {
                Levodopa && (
                    <FocusTrap
                        focusTrapOptions={{
                            fallbackFocus: ".popupmed",
                        }}
                    >
                        <div className="popupmed" role="alertdialog" aria-modal="true">
                            <h1>{myTextPopUp.room5[7].title}</h1>
                            {
                                !pageNext ?
                                    <p>{parse(myTextPopUp.room5[7].content)}</p> :
                                    <ul role="group">
                                        <p>{parse(myTextPopUp.room5[7].list)}</p>
                                        <li>{parse(myTextPopUp.room5[7].element1)}</li>
                                        <li>{parse(myTextPopUp.room5[7].element2)}</li>
                                        <li>{parse(myTextPopUp.room5[7].element3)}</li>
                                    </ul>
                            }
                            <button
                                type="submit"
                                onClick={() => {
                                    if (pageNext) {
                                        setLevodopa(false);
                                        setPopUp1(true);
                                    }
                                    setPageNext(true);
                                }}
                                className="basicButton"
                            >
                                {
                                    !pageNext ? (
                                        <span>{btnNext}</span>
                                    ) : (
                                        <span>{btnClose}</span>
                                    )
                                }
                            </button>
                        </div>
                    </FocusTrap>

                )
            }

            {
                dopa && (
                    <FocusTrap
                        focusTrapOptions={{
                            fallbackFocus: ".popupmed",
                        }}
                    >
                        <div className="popupmed" role="alertdialog" aria-modal="true">
                            <h1>{parse(myTextPopUp.room5[14].title)}</h1>
                            {
                                !pageNext ? (
                                    <div>
                                        <p>{parse(myTextPopUp.room5[14].content)}</p>
                                        <ul role="group">
                                            <p>{parse(myTextPopUp.room5[14].list)}</p>
                                            <li>{parse(myTextPopUp.room5[14].element1)}</li>
                                            <li>{parse(myTextPopUp.room5[14].element2)}</li>
                                            <li>{parse(myTextPopUp.room5[14].element3)}</li>
                                        </ul>
                                    </div>
                                ) : (
                                    <div>
                                        <p>{parse(myTextPopUp.room5[14].page2)}</p>
                                    </div>
                                )
                            }
                            <button
                                type="submit"
                                onClick={() => {
                                    if (pageNext) {
                                        setDopa(false);
                                        setCapsuleID(1);
                                        setPopUp1(true);
                                    }
                                    setPageNext(true);
                                }}
                                className="basicButton"
                            >
                                {
                                    !pageNext ? (
                                        <span>{btnNext}</span>
                                    ) : (
                                        <span>{btnClose}</span>
                                    )
                                }
                            </button>
                        </div>
                    </FocusTrap>

                )
            }

            {
                inhibiteur && (
                    <FocusTrap
                        focusTrapOptions={{
                            fallbackFocus: ".popupmed",
                        }}
                    >
                        <div className="popupmed" role="alertdialog" aria-modal="true">
                            <h1>{myTextPopUp.room5[17].title}</h1>

                            {
                                !pageNext ?
                                    <p>{myTextPopUp.room5[17].content}</p> :
                                    <div className="dual-list" role="group">
                                        <ul>
                                            <p>{myTextPopUp.room5[17].sublist1}</p>
                                            <li>{myTextPopUp.room5[17].element3}</li>
                                            <li>{myTextPopUp.room5[17].element4}</li>
                                        </ul>

                                        <ul>
                                            <p>{myTextPopUp.room5[17].sublist2}</p>
                                            <li>{myTextPopUp.room5[17].element1}</li>
                                            <li>{myTextPopUp.room5[17].element2}</li>
                                        </ul>
                                    </div>

                            }

                            <button
                                type="submit"
                                onClick={() => {
                                    if (pageNext) {
                                        setinhibiteur(false);
                                        setCapsuleID(2);
                                        setPopUp1(true);
                                    }
                                    setPageNext(true);
                                }}
                                className="basicButton"
                            >
                                {
                                    !pageNext ? (
                                        <span>{btnNext}</span>
                                    ) : (
                                        <span>{btnClose}</span>
                                    )
                                }
                            </button>
                        </div>
                    </FocusTrap>
                )

            }
            {
                popUp1 && (
                    // <div className="level-parchemin-popup">
                    <PopUpWithButton
                        title={myTextPopUp.room5[8].title}
                        content={myTextPopUp.room5[8].content}
                        img={capsules[capsuleID]}
                        button={myTextPopUp.room5[8].button}
                        click={(e) => {
                            handleClick();
                        }}
                        styleBtn={{
                            width: '70%',
                            padding: '2% 0 2% 0',
                            margin: '7% 0 0 0',
                        }}
                    />
                    // </div>
                )}
        </div >
    )
}

export default ChoixParchemin;
