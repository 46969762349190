import React from "react";
import { useTranslation } from "react-i18next";
import FocusTrap from "focus-trap-react";
import { useNavigate } from "react-router-dom";
import BasicButton from "../../../components/buttons/BasicButton";
import { styleButton1 } from "../../../helpers/stylesComponents";

function LevelThree() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleClick = (): void => {
        navigate("/chapitre1/room2/level4");
    };

    const nextBtn = t("room_one.level_four.title");

    return (
        <FocusTrap>
            <div className="back_level_3">
                <BasicButton text={nextBtn} styleB={{...styleButton1,  marginBottom : "2em"}} click={handleClick} />
            </div>
        </FocusTrap>
    )
}

export default LevelThree