import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import FocusTrap from "focus-trap-react";
import myTextPopUp from "../../../helpers/textPopUp";
import ButtonDopamine from "../../../components/room2/ButtonDopamine";
import Image from '../../../components/room2/Image';
import Perso from '../../../assets/images/room2/ClimbStairs_frame-frame.png'
import Icon_Dopamine from "../../../assets/images/room2/Tableau_Dopamine/Icon_Dopamine01_Ingame.png";
import Gauge from "../../../assets/images/room2/Room02_FrontView/Gauge.png";
import GaugeCursor from "../../../assets/images/room2/Room02_FrontView/Gauge_Cursor.png";
import { PopUpProps } from "../../../types/BasicPopUp-types";
import PopUpWithButton from "../../../components/popups/PopUpWithButton";
import { styleButton1 } from "../../../helpers/stylesComponents";


interface Bravo {
  id: number;
  x: number;
  y: number;
  visible: boolean;
}
type TypeGauge = HTMLImageElement | null;

function Levelfour() {

  const navigate = useNavigate();
  const handleClick = (): void => {
    navigate("/chapitre1/room2/level5");
  };

  const title1: string = myTextPopUp.room2[3].title;
  const text1: string = myTextPopUp.room2[3].content;

  const [popUp, setPopUp] = useState<PopUpProps>({
    title: title1,
    content: text1,
    show: true,
  });

  const [hGauge, setHGauge] = useState(0);
  const [count, setCount] = useState(0);
  const [btnHearts, setBtnHearts] = useState<Bravo[]>([
    { id: 0, x: 35, y: 70, visible: true },
    { id: 1, x: 80, y: 15, visible: true },
    { id: 2, x: 65, y: 35, visible: true },
    { id: 3, x: 50, y: 50, visible: true },
  ]);

  const [isHeartClickable, setHeartClickable] = useState(false);

  // REF
  const refDivGauge = useRef<TypeGauge>(null);

  useEffect(() => {
    if (refDivGauge.current) {
      setHGauge(refDivGauge.current.clientHeight);
    }
  }, [count])

  const clickHeart = (e: any, index: any) => {
    if (index < 4) {
      const newArr = btnHearts.map(obj => {
        if (obj.id === index) {
          setCount(count + 1)
          return { ...obj, visible: false };
        }
        return obj
      })

      setBtnHearts(newArr);
    }
  }

  const btnHeartAllHiden = () => {
    for (let i = 0; i < btnHearts.length; i += 1) {
      if (btnHearts[i].visible)
        return false;
    }
    return true;
  }

  return (
    <div className="backgroundfrontview">
      <FocusTrap>
        <div className="containerBtnHeart">
          {!btnHeartAllHiden() ? btnHearts.map((elt, index) => {
            return (
              <ButtonDopamine
                key={elt.id}
                nameClass={elt.visible ? "visible" : "hidden"}
                src={Icon_Dopamine}
                pos_x={elt.x}
                pos_y={elt.y}
                click={(e: Event) => {
                  if (isHeartClickable) {
                    clickHeart(e, elt.id);
                  }
                }}
                alt={isHeartClickable ? "Molécule de dopamine, appuyez sur entrer pour faire monter la jauge." : ""}
                hide={!isHeartClickable}
              />
            )
          }) : <div className="pop_up_level_4">
            <PopUpWithButton
              title={myTextPopUp.room2[4].title}
              content={myTextPopUp.room2[4].content}
              button={myTextPopUp.room2[4].button}
              click={handleClick}
              styleBtn={{...styleButton1, fontWeight:"bold"}}
            />
          </div>

          }
        </div>
      </FocusTrap>
      {popUp.show && (

        <FocusTrap>
          <div className="pop_up_level_4">
            <PopUpWithButton
              title={popUp.title}
              content={popUp.content}
              button={myTextPopUp.room2[3].button}
              click={(e) => {
                setPopUp({
                  show: false,
                });
                setHeartClickable(true);
              }}
              styleBtn={{...styleButton1, fontWeight:"bold"}}
            />
          </div>
        </FocusTrap>
      )}
      <img src={Perso} alt="" className="perso_img" />




      <div className='containerGauge'>

        <div ref={refDivGauge} className="sousDivGauge">
          {hGauge > 0 && <motion.div
            className="arrow_right"
            initial={{ y: 0 }}
            animate={{ y: -(1 / 5 * hGauge * count) + 20 }}>

            <Image src={GaugeCursor} description="" />
          </motion.div>}

          <Image src={Gauge} description="" />
        </div>

      </div>

    </div>
  );
};

export default Levelfour;