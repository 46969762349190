import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './store';


type TypeChapter = {
    "chap1": boolean,
    "chap2": boolean,
    "chap3": boolean,
    "chap4": boolean,
    "chap5": boolean,
}


const initialState : TypeChapter = {
  chap1: true,
  chap2: false,
  chap3: false,
  chap4: false,
  chap5: false,
};

export const chapitresSlice = createSlice({
  name: 'chapters',
  initialState,
  reducers: {

    unLockChap2: (state, action: PayloadAction<boolean>) => {
      state.chap2 = action.payload
    },
    unLockChap3: (state, action: PayloadAction<boolean>) => {
      state.chap3 = action.payload
    },
    unLockChap4: (state, action: PayloadAction<boolean>) => {
      state.chap4 = action.payload
    },
    unLockChap5: (state, action: PayloadAction<boolean>) => {
      state.chap5 = action.payload
    },
  },
});


export const { unLockChap2, unLockChap3, unLockChap4, unLockChap5 } = chapitresSlice.actions;
export const selectChap = (state : RootState) => state.chapters;
export default chapitresSlice.reducer;
