import React from "react";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styleButton1 } from "../../helpers/stylesComponents";
import { BasicButtonProps } from "../../types/BasicButton-type";

function BasicButton(props: BasicButtonProps): JSX.Element {
  const content: BasicButtonProps = props;

  const styleButton: object = {
    border: "none",
    padding: "10px 40px",
    borderRadius: "50px ",
    outline: "none",
    touchAction: "manipulation",
  };

  const arrowColor = content.arrowBlack ? "black" : "white";

  return (
    <button
      className="basicButton size3"
      style={{ ...styleButton1, ...styleButton, ...content.styleB }}
      type="submit"
      aria-label={content.text ? content.text : "passer à la suite"}
      onClick={content.click}>
      {
        content.icon ?
          (
            <FontAwesomeIcon icon={faArrowRight} className="size2" style={{ color: content.arrowV ? "#055c5c" : arrowColor }} />
          ) : (
            content.text
          )
      }
    </button>
  );
}
export default BasicButton;
