import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import FocusTrap from "focus-trap-react";
import BasicButton from "../../../components/buttons/BasicButton";
import myTextPopUp from "../../../helpers/textPopUp";
import { delay } from "../../../utils/funcUtils";


import StandardCard from "../../../assets/images/room6/Memory/Card_Back.png";
/* Text cards imports */
import TextCardConfusion from "../../../assets/images/room6/Memory/Card_Text_Confusion.png";
import TextCardHypotension from "../../../assets/images/room6/Memory/Card_Text_Hypotension.png";
import TextCardNausea from "../../../assets/images/room6/Memory/Card_Text_Nausees.png";
import TextCardEdema from "../../../assets/images/room6/Memory/Card_Text_Oedeme.png";
import TextCardDrowsiness from "../../../assets/images/room6/Memory/Card_Text_Somnolence.png";

import TextCardHypotensionEN from "../../../assets/images/room6/Memory/Card_Text_HypotensionEn.png";
import TextCardNauseaEN from "../../../assets/images/room6/Memory/Card_Text_NauseesEn.png";
import TextCardEdemaEN from "../../../assets/images/room6/Memory/Card_Text_OedemeEn.png";
import TextCardDrowsinessEN from "../../../assets/images/room6/Memory/Card_Text_SomnolenceEn.png";

/* Image cards imports */
import ImageCardConfusion from "../../../assets/images/room6/Memory/Card_Picture_Confusion.png";
import ImageCardHypotension from "../../../assets/images/room6/Memory/Card_Picture_Hypotension.png";
import ImageCardNausea from "../../../assets/images/room6/Memory/Card_Picture_Nausee.png";
import ImageCardEdema from "../../../assets/images/room6/Memory/Card_Picture_Oedeme.png";
import ImageCardDrowsiness from "../../../assets/images/room6/Memory/Card_Picture_Somnolence.png";




function Room6level2() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleClick = (): void => {
    navigate("/chapitre2/room6/level3");
  };

  interface Objetbouton {
    id: number;
    img: string;
    color: string;
    visible: boolean;
    order: number;
  }
  const getCurrentLanguage = i18next.language;

  const [currentOrder, setCurrentOrder] = useState<number>(1);
  const [done, setDone] = useState<boolean>(false);


  const [Description, setDescription] = useState<Objetbouton[]>([
    { id: 1, img: getCurrentLanguage === "fr" ? TextCardNausea : TextCardNauseaEN, color: "#F246F9", visible: false, order: 0 },
    { id: 2, img: getCurrentLanguage === "fr" ? TextCardHypotension : TextCardHypotensionEN, color: "#00000029", visible: false, order: 0 },
    { id: 3, img: getCurrentLanguage === "fr" ? TextCardEdema : TextCardEdemaEN, color: "#4668F9", visible: false, order: 0 },
    { id: 4, img: getCurrentLanguage === "fr" ? TextCardDrowsiness : TextCardDrowsinessEN, color: "#F9E446", visible: false, order: 0 },
    { id: 5, img: TextCardConfusion, color: "#F99746", visible: false, order: 0 },
  ]);

  const [Images, setImages] = useState<Objetbouton[]>([
    { id: 2, img: ImageCardHypotension, color: "#00000029", visible: false, order: 0 },
    { id: 4, img: ImageCardDrowsiness, color: "#F9E446", visible: false, order: 0 },
    { id: 5, img: ImageCardConfusion, color: "#F99746", visible: false, order: 0 },
    { id: 1, img: ImageCardNausea, color: "#F246F9", visible: false, order: 0 },
    { id: 3, img: ImageCardEdema, color: "#4668F9", visible: false, order: 0 },
  ]);

  const cardBackgroundStyle = {
    padding: "0",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundColor: "rgb(0,0,0,0)"
  }

  const [carte, setcarte] = useState<number>(0)
  const [count, setCount] = useState<number>(0);
  const [popup, setpopup] = useState<Boolean>(false);

  const [erreur, seterreur] = useState<Boolean>(false);

  const [tab, setTab] = useState({ ligne1: 0, ligne2: -1 });

  const Selectioned = (e: Event, index: any) => {
    const newArr = Description.map(elt => {
      if (elt.id === index) {
        if (!elt.visible && count === 0) {
          setCount(count + 1)
          setcarte(elt.id)
          const orderTmp = currentOrder;
          setCurrentOrder(currentOrder + 1);

          setTab({ ligne1: -1, ligne2: 0 });
          return { ...elt, visible: true, order: orderTmp };
        }
      }
      return elt;
    })
    setDescription(newArr);
  }

  const SelectionedImage = (e: Event, index: any) => {
    const newArr = Images.map(elt => {
      if (elt.id === index) {
        if (!elt.visible && count === 1 && elt.id === carte) {
          setCount(count - 1)
          return { ...elt, visible: true, order: currentOrder };
        }
        if (!elt.visible && count === 1 && elt.id !== carte) {
          delay(700).then(() => {seterreur(true); setCount(0);} );
          
          return { ...elt, visible: true, order: currentOrder };
        }
      }
      return elt;
    })
    setTab({ ligne1: 0, ligne2: -1 })
    setImages(newArr);
  }

  useEffect(() => {
    if (erreur) {
      seterreur(false);
      let newArr = [...Description];
      newArr.forEach(elt => {
        const current = elt;
        if (elt.order === currentOrder - 1) {
          current.visible = false;
          current.order = 0;
        }
        return current;
      });
      setDescription(newArr);

      newArr = [...Images];
      newArr.forEach(elt => {
        const current = elt;
        if (elt.order === currentOrder) {
          current.visible = false;
          current.order = 0;
        }
        return current;
      });
      setImages(newArr);

    }

  }, [erreur])

  useEffect(() => {
    const check = Images.map(elt => {
      if (!elt.visible) {
        return false
      }
      return true
    });
    setDone(check.every(elt => elt === true));
    if (done) {
      setpopup(true);
    }
  }, [done, Images]);

  const btnNext = t("room_six.level_two.btn_next");

  return (
    <div className="background-room6">
      <FocusTrap
        focusTrapOptions={{
          fallbackFocus: "#memory",
          clickOutsideDeactivates: true,
        }}
      >
        <div
          id="memory"
          role="button"
          tabIndex={0}
          aria-label="next"
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              handleClick();
            }
          }}
        />
      </FocusTrap>

      <>
        <div className="instruction" role="alertdialog">
          <h1>{myTextPopUp.room6[1].title}</h1>
          <p>{myTextPopUp.room6[1].content}</p>
        </div>
        <div className="btnSymptome">
          {Description.map((elt) => <input className="card-input" type="button"
            key={elt.id}
            style={elt.visible ? (
              {
                backgroundImage: `url(${elt.img})`,
              })
              : (
                {
                  backgroundImage: `url(${StandardCard})`,
                })}
            onClick={(e: any) => { Selectioned(e, elt.id) }}
            aria-label=
            {elt.visible ?
              ""
              :
              `carte non retournée ${Description.indexOf(elt) + 1} sur ${Description.length}`}
            tabIndex={tab.ligne1}
          />)}
        </div>
        <div className="btnIllustration">
          {Images.map((elt) => <input className="card-input" type="button"
            aria-hidden="true"
            key={elt.id}
            style={elt.visible ?
              {
                backgroundImage: `url(${elt.img})`,
              }
              :
              {
                backgroundImage: `url(${StandardCard})`,
              }}
            onClick={(e: any) => { SelectionedImage(e, elt.id) }}
            aria-label=
            {elt.visible ?
              "" :
              `carte non retournée ${Images.indexOf(elt) + 1} sur ${Images.length}`}
            tabIndex={tab.ligne2}
          />)}
        </div>
      </>
      {popup && (
        <BasicButton
          text={btnNext}
          click={handleClick}
          styleB={{
            fontSize: "3rem",
            backgroundColor: "#055c5c",
            color: "#ffffff",
            borderRadius: "50px ",
            position: "absolute",
            top: "90%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      )}
    </div>
  )
}

export default Room6level2;
