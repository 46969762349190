import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import FocusTrap from "focus-trap-react";
import Enzyme from "../../../assets/images/room5/TableauSalle05/Enzymes_copy.png";
import Dopamine from "../../../assets/images/room2/Tableau_Dopamine/Icon_Dopamine01_Ingame.png";
import { getPosition, getPourcentObj, sizeObj } from "../../../utils/funcUtils";
import ScreenSize from "../../../utils/ScreenSize";
import { placements, styleElement } from "../../../utils/utilsPlacement";
import SideKick from "../../../assets/images/room5/Gellules_Medicaments_Enzymes/SideKick_InBody.png";
import JonctionNeurone from "../../../components/animated/JonctionNeurone";
import { TypeObject } from "../../../utils/typesUtils";
import DraggableMultiPos from "../../../components/animated/DraggableMultiPos";
import myTextAria from "../../../helpers/textAria";
// Inhibiteur
// zone dopamines
// 6 dopamines

type TypePosDraggable = {
    x: number;
    y: number;
    width: number;
    height: number;
    passed: boolean;
};


const pathJsonBack = { "width": 1920, "height": 1080 };

function DisparitionEnzymes(props: any): JSX.Element {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const screenW: number = ScreenSize().width;
    const screenH: number = ScreenSize().height;
    const [pathJson, setPathJson] = useState<Array<TypePosDraggable>>([
        // dopamines
        { "x": 720, "y": 540, "width": 75, "height": 75, passed: true },
        { "x": 1015, "y": 580, "width": 75, "height": 75, passed: true },
        { "x": 845, "y": 650, "width": 75, "height": 75, passed: true },
        { "x": 1240, "y": 650, "width": 75, "height": 75, passed: true },
        { "x": 1125, "y": 470, "width": 75, "height": 75, passed: true },
        { "x": 930, "y": 400, "width": 75, "height": 75, passed: true },
        // enzymes
        { "x": 640, "y": 540, "width": 75, "height": 75, "passed": false },
        { "x": 935, "y": 580, "width": 75, "height": 75, "passed": false },
        { "x": 765, "y": 650, "width": 75, "height": 75, "passed": false },
        { "x": 1160, "y": 650, "width": 75, "height": 75, "passed": false },
        { "x": 1045, "y": 470, "width": 75, "height": 75, "passed": false },
        { "x": 850, "y": 400, "width": 75, "height": 75, "passed": false },
    ]);


    // Placement dopamine et enzyme
    const [pourcent, setPourcent] = useState<Array<number>>(getPourcentObj(screenW, screenH, pathJson));
    const [placement, setPlacement] = useState<Array<TypeObject>>(placements(screenW, screenH, pathJson, pathJsonBack))
    const style = styleElement(placement, pourcent);

    const [countEnzyme, setCountEnzyme] = useState<number>(0);


    // ---------------------- USE EFFECT ------------------------------

    // si la taille ou les json change => placement et taille des objets se recalcule
    useEffect(() => {
        setPourcent(getPourcentObj(screenW, screenH, pathJsonBack));
        setPlacement(current =>
            current.map((obj, index) => {
                return { ...obj, size: sizeObj(screenW, screenH, pathJsonBack, pathJson[index]) };
            })
        );
        setPlacement(current =>
            current.map((obj, index) => {
                return { ...obj, position: getPosition(screenW, screenH, pathJson[index]) };
            })
        );
    }, [screenW, screenH, pathJson]);

    // ---------------------- FUNCTIONS ------------------------------

    const changeDestination = (destination: TypePosDraggable) => {
        const i = pathJson.slice(6, pathJson.length).indexOf(destination);
        if (i > - 1 && !pathJson.slice(6, pathJson.length)[i].passed) {
            const arr2 = [...pathJson];
            arr2[6 + i].passed = true;
            setPathJson(arr2);
            setCountEnzyme(countEnzyme + 1);
        }
    }

    const getTextAria = (): string => {
        const [levodopa, agoniste, inhibiteur] = myTextAria.draggable;
        let r = "";
        if (myTextAria.draggable) {
            if (location.state.choiceMed === "Lévodopa") {
                r = levodopa;
            } else if (location.state.choiceMed === "Dopaminergique") {
                r = agoniste;
            }
            else {
                r = inhibiteur;
            }
        }
        return r;
    }

    const handleClick = () => {
        navigate("/chapitre2/room5/level4.3");
    }

    return (
        <FocusTrap>
            <div className="container-disparitionEnzymes">
                <div
                    role="button"
                    tabIndex={0}
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "20px",
                        height: "20px"
                    }}
                    onKeyDown={(e) => {
                        e.preventDefault();
                        if (e.key === "Enter" || e.key === " ") {
                            handleClick();
                        }
                    }}
                    onClick={() => handleClick()}
                    aria-label={getTextAria()}
                />
                <JonctionNeurone
                    paragraphe={`${t("room_five.level_three.second_title.first")} <strong style="color: #DC7517;"> ${t("room_five.level_three.second_title.second")} </strong>${t("room_five.level_three.second_title.third")} <strong style="color: #DC7517;"> ${t("room_five.level_three.second_title.fourth")} </strong> ${t("room_five.level_three.second_title.fifth")}`}
                    elementJonction={
                        <>
                            <div style={{ position: "absolute", height: "100vh", width: "100vw", zIndex: "1" }}>
                                <DraggableMultiPos
                                    dragConstraints={{ right: 1 / 2 * screenW, left: 0, top: -screenH / 2, bottom: screenH / 10 }}
                                    startX={74.07}
                                    startY={26.04}
                                    destinations={pathJson.slice(6, pathJson.length)}
                                    imagePath={SideKick}
                                    onChangeDestination={(dest) => changeDestination(dest)}
                                    onFinish={() => {
                                        if (countEnzyme === 5) {
                                            setTimeout(() => {
                                                handleClick()
                                            }, 500);
                                        }
                                    }}
                                />
                            </div>
                            {pathJson.slice(0, 6).map((elt, index) => {
                                return (
                                    <div key={uuidv4()} className="container-dopamine absolute" style={style[index]}>
                                        <img src={Dopamine} alt="dopamine molecule" />
                                    </div>
                                );
                            })}
                            {pathJson.slice(6, pathJson.length).map((elt, index) => {
                                return (
                                    !elt.passed && <div key={uuidv4()} className="container-dopamine absolute" style={style[index + 6]}>
                                        <img src={Enzyme} alt="enzyme molecule" />
                                    </div>
                                );
                            })}
                        </>
                    }
                    useBackground={false} />
                    <div className="legend-ingibiteurs">
                        <img src={SideKick} alt="" />
                        <p>Inhibiteurs de la dégration de la dopamine</p>
                    </div>
            </div>
        </FocusTrap>
    );
}

export default DisparitionEnzymes;
