import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { I18nextProvider } from 'react-i18next';
import { HashRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { debounce } from "debounce";
import i18n from "./i18n";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from './slices/store';
import { saveState } from "./slices/localStorage";


store.subscribe(
  debounce(() => {
    saveState(store.getState());
  }, 800)
);
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
  <Provider store={store}>
  <I18nextProvider i18n={i18n}>
    <HashRouter>
      <App />
    </HashRouter>
  </I18nextProvider>
  </Provider>
</React.StrictMode>,

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
