import React from "react";
import { useTranslation } from "react-i18next";
import {useNavigate} from 'react-router-dom';
import FocusTrap from "focus-trap-react";
import brain from "../../../assets/images/room3/Brain.png";
import brainConnexion3 from "../../../assets/images/room3/Brain-connexion/Web_Purple.png";
import BasicButton from "../../../components/buttons/BasicButton";
import { styleButton1 } from "../../../helpers/stylesComponents";

function Room3end(): JSX.Element {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleClick = (): void => {
        navigate("/chapitre1/room4/level1");
    };

    const nextBtn: string = t("room_three.level_one.sublevel_three.next_room_btn");

    return (
        <div className="room3Container"  id="background-template2">
            <div className="room3-sous-container">
                <div className="left-tableau-container">
                    <div className="brain-container" id="brain-container-ajust"  >
                        <img className="brain" id="brain-ajust"  src={brain} alt="Cerveau"/>
                        <img src={brainConnexion3} className="brainConnexionPurple" alt="Cerveau avec une connexion violette"/>
                    </div>
                </div>
                <div className="popupContainer2">
                    <FocusTrap>
                        <div className="container-bonne-rep">
                            <div className="bonne-reponse" role="alertdialog" aria-modal="true">
                                <h1 className="size2">{t("room_three.level_one.sublevel_three.good_answer")}</h1>
                                <p className="size4">{t("room_three.level_one.sublevel_three.description.first")}</p>
                                <ul className="size4">
                                    <li>{t("room_three.level_one.sublevel_three.description.second")}</li>
                                    <li>{t("room_three.level_one.sublevel_three.description.third")}</li>
                                    <li>{t("room_three.level_one.sublevel_three.description.fourth")}</li>
                                </ul>
                                <p className="size4">{t("room_three.level_one.sublevel_three.description.fifth")}</p>
                            </div>
                            <BasicButton
                                text={nextBtn}
                                styleB = {styleButton1}
                                click={handleClick} />
                        </div>
                    </FocusTrap>
                </div>
            </div>
        </div>
    );
}

export default Room3end;
