import React, {useState} from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import {useNavigate} from 'react-router-dom';
import { motion } from "framer-motion"
import winner from "../../../assets/images/room3/Winner.png";
import TableauFR from "../../../assets/images/room3/Tableau_Complet/tableau_3_voies.png";
import TableauEN from "../../../assets/images/room3/Tableau_Complet/tableau_3_voiesEn.png";
import PopUpWithButton from "../../../components/popups/PopUpWithButton";
import { stylePopUp } from "../../../helpers/stylesComponents";


function RoomBrainQuestion(): JSX.Element {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [purple, setPurple]=useState<boolean>(false);
    const [red, setRed]=useState<boolean>(false);
    const [blue, setBlue]=useState<boolean>(false);
    const [visiblePopup, setVisiblePopup] = useState<boolean>(false);

    const wrongAnswer = t("room_three.level_one.sublevel_two.wrong_answer");
    const btnTryAgain = t("room_three.level_one.sublevel_two.btn_try_again");

    const getCurrentLanguage = i18next.language;

    return (
        <div className="room3Container"  id="background-template2" >
            <div className="room3-sous-container" id="new-tab"  style={{ backgroundImage: getCurrentLanguage === "fr" ? `url(${TableauFR})` : `url(${TableauEN})` }}>

                <input
                    type="button"
                    aria-label="Voie Nigro-striatale"
                    className="divQuestionPurple"
                    onMouseOver={():void=>{setPurple(true)}}
                    onFocus={():void =>{setPurple(true)}}
                    onMouseOut={():void=>{setPurple(false)}}
                    onBlur={():void=>{setPurple(false)}}
                    onClick={():void=>{navigate("/chapitre1/room3/level1.4")}}
                />

                <input
                    type="button"
                    aria-label="Voie Méso-corticales"
                    className="divQuestionRed"
                    onMouseOver={():void=>{setRed(true) }}
                    onFocus={():void =>{setRed(true)}}
                    onMouseOut={():void=>{setRed(false)}}
                    onClick={() => setVisiblePopup(!visiblePopup)}
                    onBlur={():void=>{setRed(false)}}
                />
                <input
                    type="button"
                    aria-label="Voie Méso-lymbique"
                    className="divQuestionBlue"
                    onMouseOver={():void=>{setBlue(true)}}
                    onFocus={():void =>{setBlue(true)}}
                    onMouseOut={():void=>{setBlue(false)}}
                    onClick={() => setVisiblePopup(!visiblePopup)}
                    onBlur={():void=>{setBlue(false)}}
                />

                {purple && <motion.div
                        className="divQuestionPurple2"
                        id="purple"
                        role="alertdialog"
                        aria-modal="true"
                        style={{...stylePopUp, border: "3px solid #A41EF4"}}
                        animate={{
                            scale: [0.95, 1, 1.05],
                            borderRadius: ["50px", "50px", "50px"],
                        }}
                        transition={{ ease: "linear", duration: 0.4 }}
                    >
                        <p className="size3" style={{fontWeight:"bold"}}>
                            {t("room_three.level_one.sublevel_two.nigrostriatal_pathway.description")}
                        </p>
                </motion.div>}

                {red && <motion.div
                    className="divQuestionPurple2"
                    id="red"
                    role="alertdialog"
                    aria-modal="true"
                    style={{...stylePopUp, border: "3px solid #D62D6A"}}
                    animate={{
                        scale: [0.95, 1, 1.05],
                        borderRadius: ["50px", "50px", "50px"],
                    }}
                    transition={{ ease: "linear", duration: 0.4 }}
                    >
                        <p className="size3" style={{fontWeight:"bold"}}>
                            {t("room_three.level_one.sublevel_two.mesocortical_pathway.description")}
                        </p>
                </motion.div>}

                {blue && <motion.div
                    className="divQuestionPurple2"
                    id="blue"
                    role="alertdialog"
                    aria-modal="true"
                    style={{...stylePopUp, border: "3px solid #1A81D5"}}
                    animate={{
                        scale: [0.95, 1, 1.05],
                        borderRadius: ["50px", "50px", "50px"],
                    }}
                    transition={{ ease: "linear", duration: 0.4 }}
                    >
                        <p className="size3" style={{fontWeight:"bold"}}>
                            {t("room_three.level_one.sublevel_two.mesolimbic_pathway.description")}
                        </p>
                </motion.div>}

                {visiblePopup && <div className="result">
                    <PopUpWithButton title={wrongAnswer}
                        button={btnTryAgain}
                        click={(e) => setVisiblePopup(!visiblePopup)}
            />
        </div>}
            </div>
            <img src={winner} tabIndex={-1} id="perso-winner" alt="Des personnes qui viennent de gagner un trophée" />
        </div>
    );
}

export default RoomBrainQuestion;
