import React, { useState } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from "react-router-dom";
import { selectChap, unLockChap2} from '../../slices/chapitresSlice';
import BasicButton from "../../components/buttons/BasicButton";
import { styleButton1 } from "../../helpers/stylesComponents";
import PopUpWithButton from "../../components/popups/PopUpWithButton";
import TextPopUp from "../../helpers/textPopUp";
import InfographieFR from "../../assets/images/room4/Infographie/Infographie.png"
import InfographieEN from "../../assets/images/room4/Infographie/InfographieEn.png"

function Room4level3(): JSX.Element {
  const { t } = useTranslation();
  const chapUnlock = useSelector(selectChap);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [next, setNext] = useState<boolean>(false);
  const handleClickClose = (): void => {
    if(!chapUnlock.chap2) dispatch(unLockChap2(true));
    navigate("/home");
  }

  const getCurrentLanguage = i18next.language;

  const altText = `D'autres signes cliniques sont possibles, mais retenez bien que les patients atteints de la maladie
                  de Parkinson ne présenteront pas l'ensemble des symptômes. Il existe une grande variabilité entre
                  individus.
                  Exemples de symptomes :
                  - troubles de l'humeur (hyperémotivité, anxiété, dépression, apathie),
                  - troubles du sommeil (insomnie, sommeil fragmenté, somnolence diurne) et fatigue,
                  - troubles de la concentration et de l'attention,
                  - perte de l'odorat,
                  - trouble de la parole, baisse du niveau sonore de la voix (hypophonie), lenteur d'élocution (ou réduction
                    de la fluence verbale)
                  - hypotension artérielle orthostatique,
                  - troubles digestifs,
                  - troubles urinaires ou sexuels,
                  - troubles de la thermorégulation (sueurs),
                  - douleurs de type rhumatismales `;

  const btnNext = t("room_four.level_three.btn");

  return (
    <div className="room4Container" id="room4Container-ajust" >
      {next ?
      <PopUpWithButton
        title={TextPopUp.room4[4].title}
        content={TextPopUp.room4[4].content}
        button={TextPopUp.room4[4].button}
        click={handleClickClose}
        styleBtn={{
          ...styleButton1,
          borderRadius:"50px",
          margin:"2% auto"
        }}
        />
        : <div className="div-condition">
        <div className="room4-level3-container" style={{ backgroundImage: getCurrentLanguage === "fr" ? `url(${InfographieFR})` : `url(${InfographieEN})` }} role="alertdialog" aria-label={altText}/>
        <BasicButton
            click={() => {setNext(true)}}
            text={btnNext}
            styleB={{
              ...styleButton1,
              borderRadius:"50px",
              margin:"2% auto",
              padding:"10px 15px",
            }}
            />
        </div>

      }

    </div>
  );
}

export default Room4level3;
