import React from "react";
import { CardPopUpProps } from "../../types/CardPopUp-type";

export default function CardPopUp(props: CardPopUpProps): JSX.Element {
    const cardPopUpProps = props;

    const cardStyle = {
        backgroundColor: "white",
        borderRadius: "50px",
        padding: "2% 2% 2% 2%",
        width: "20%",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
        cursor: cardPopUpProps.clickable ? "pointer" : "default",
        textAlign: 'center' as const,
        color: "black",
        fontSize: "1.3rem",
    }

    const imageStyle = {
        width: "90%",
        height: "auto"
    }

    return (
        <div className="card-popup">
            {
                cardPopUpProps.clickable ? (
                    <div
                        style={cardStyle}
                        className={cardPopUpProps.class}
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => {

                        }}
                        onClick={cardPopUpProps.onClick}>
                        <h1 style={{ marginBottom: "20%" }}>{cardPopUpProps.title}</h1>
                        <h2 style={{ marginBottom: "10%" }}>{cardPopUpProps.description}</h2>
                        {
                            cardPopUpProps.image && <img src={cardPopUpProps.image} style={imageStyle} alt="card" />
                        }
                    </div>
                ) : (
                    <div className={cardPopUpProps.class} style={cardStyle}>
                        <h1 style={{ marginBottom: "20%" }}>{cardPopUpProps.title}</h1>
                        <h2 style={{ marginBottom: "10%" }}>{cardPopUpProps.description}</h2>
                        {
                            cardPopUpProps.image && <img src={cardPopUpProps.image} style={imageStyle} alt="card" />
                        }
                    </div>
                )
            }
        </div>
    );
}
