import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import myTextPopUp from "../../../helpers/textPopUp";
import Gelule03_Small from "../../../assets/images/room5/Gellules_Medicaments_Enzymes/Gelule03_Small.png";
import SideKick from "../../../assets/images/room5/Gellules_Medicaments_Enzymes/SideKick_InBody.png"
import BodyRoom from "../../../components/animated/BodyRoom";


const pathJson = [
    { "x": 1050, "y": 230, "width": 28, "height": 13 },
    { "x": 970, "y": 240, "width": 28, "height": 13 },
    {"x": 980, "y": 830, "width": 28, "height": 13 },
    { "x": 1000, "y": 920, "width": 28, "height": 13 },
    { "x": 890, "y": 675, "width": 48, "height": 48 },
    { "x": 840, "y": 250, "width": 60, "height": 60 },
]
function Room5level4() {
    const { t } = useTranslation();
    const location = useLocation();
    const reachBrain = t("room_five.level_three.mode_action.step_three")
    return <BodyRoom
        pathJson={pathJson}
        pathGelule={Gelule03_Small}
        pathObj={SideKick}
        textBody={[myTextPopUp.room5[9].content,
        myTextPopUp.room5[10].content,
            reachBrain
        ]}
        nextPage="/chapitre2/room5/level4.2"
        nameMed={location.state.choiceMed }/>
}

export default Room5level4
