import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import PopUpWithButton from "../../components/popups/PopUpWithButton";
import { styleButton1 } from "../../helpers/stylesComponents";


function Room4level1(): JSX.Element {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleClick = (): void => {
        navigate("/chapitre1/room4/level2");
    };

    const titleRoom = t("room_four.level_one.title");
    const description = t("room_four.level_one.description");
    const boldText = t("room_four.level_one.bold_text");
    const btn = t("room_four.level_one.btn");

    return (
        <div className="room4Container">
            <PopUpWithButton
                title={titleRoom}
                contents={[description,
                            `<strong>${boldText}</strong>`]}
                click={handleClick}
                button={btn}
                styleBtn={styleButton1}

            />
        </div>
    );
}

export default Room4level1;
