import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import BasicButton from "../../../components/buttons/BasicButton";
import NervousSystem from "../../../assets/images/room1/NervousSystem.png";
import ImagePopUp from "../../../components/popups/ImagePopUp";
import ScreenSize from "../../../utils/ScreenSize";
import { getPourcent, getSize } from "../../../utils/funcUtils";

function QuizzResult(props: any): JSX.Element {
  const { t } = useTranslation();
  const location = useLocation();
  const screenW: number = ScreenSize().width;
  const screenH: number = ScreenSize().height;
  const navigate = useNavigate();
  const [redirection, setRedirection] = useState<boolean>(false);
  const [pourcent, setPourcent] = useState<Array<number>>(getPourcent(screenW, screenH, location.state.wScreenBased, location.state.yScreenBased))
  const [sizePopUp, setSizePopUp] = useState<Array<number>>(getSize(screenW, screenH, location.state.wScreenBased, location.state.yScreenBased, location.state.w, location.state.h))

  const [initPos] = useState<Array<number>>([
    location.state.posX - (location.state.w * pourcent[0] / 200),
    location.state.posY - (location.state.h * pourcent[1] / 200)
  ])

  // If redirect is true, redirect to next room
  useEffect(() => {
    if (redirection) navigate("/chapitre1/room1/level4");
  }, [navigate, redirection]);

  // Change the pourcent if a parameter change
  useEffect(() => {
    setPourcent(getPourcent(screenW, screenH, location.state.wScreenBased, location.state.yScreenBased));
  }, [screenW, screenH, location.state.wScreenBased, location.state.yScreenBased]);

  // Change the popUp size if a parameter change
  useEffect(() => {

    setSizePopUp(getSize(screenW, screenH, location.state.wScreenBased, location.state.yScreenBased, location.state.w, location.state.h));
  }, [location.state.h, location.state.w, location.state.wScreenBased, location.state.yScreenBased, screenH, screenW]);

  const handleClick = (): void => {
    setRedirection(true);
  };

  return (
    <div className="container-quizzRes bg-room1">

      <motion.div
        style={{ position: "absolute"}}
        initial={{
          x: initPos[0],
          y: initPos[1],
        }}
        animate={{
          width: `${sizePopUp[0]}px`, height: `${sizePopUp[1]}px`,
          scale: 1.5,
          x: `calc(${screenW / 2}px + ${location.state.w * pourcent[0] / 100}px)`,
          y: `calc(${screenH / 2}px - ${location.state.h * pourcent[1] / 200}px)`,
          transition: { type: "spring", duration: 1 },
        }}
      >
        <ImagePopUp path={NervousSystem} imageName={t("room_one.level_one.human_body_system.nervous_system")}  ariaDisabled alternative={false} style={{ width: `calc(${location.state.w}px`, height: `${location.state.h}px` }} />
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { type: "spring", duration: 1 } }}
        className="container_anim"
      >

        <div className="container_text">
          <p role="alertdialog" className="size3">
            {t("room_one.level_three.nervous_system_desc")}
          </p>
        </div>
        <BasicButton icon click={handleClick} styleB={{marginTop: "6%"}}/>
      </motion.div>

    </div>
  );
}
export default QuizzResult;
