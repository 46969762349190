import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from "uuid";
import FocusTrap from "focus-trap-react";
import Icon_Dopamine from "../../../assets/images/room2/Tableau_Dopamine/Icon_Dopamine01_Ingame.png";
import Arrow from "../../../assets/images/room2/Tableau_Dopamine/Arrow 9 1.png";
import JonctionNeurone from "../../../components/animated/JonctionNeurone";
import DraggableGeneric from "../../../components/animated/DraggableGeneric";
import { TypePosDraggable } from "../../../types/Draggable-type";
import ScreenSize from "../../../utils/ScreenSize";
import myTextAria from "../../../helpers/textAria";
import { sizeObj } from "../../../utils/funcUtils";


function Room5level2v2() {
    const { t } = useTranslation();
    const location = useLocation();
    const screenW: number = ScreenSize().width;
    const screenH: number = ScreenSize().height;
    const navigate = useNavigate();
    const [drag, setDrag] = useState<boolean>(false);
    const [leftArrow, setLeftArrow] = useState<number>(20);
    const [compte, setCompte] = useState<number>(0);
    const arrowRef = useRef<HTMLDivElement>(null);

    const [destinations, setDestinations] = useState<TypePosDraggable[]>([
        { x: 57.9, y: 35.4, occuped: false },
        { x: 56.6, y: 40.4, occuped: false },
        { x: 56, y: 45.5, occuped: false },
        { x: 55.7, y: 50.6, occuped: false },
        { x: 56, y: 55.7, occuped: false },
        { x: 57.6, y: 60.9, occuped: false },
    ]);

    const [sizeDestination, setSizeDestination] = useState<number[]>(
        sizeObj(screenW, screenH,{width : 1920, height : 1080}, {width : 100, height : 50})
    );

    const start = ([
        { x: 39.5, y: 38 },
        { x: 40.5, y: 39.5 },
        { x: 40.5, y: 47.5 },
        { x: 41, y: 49 },
        { x: 39.5, y: 58 },
        { x: 39.8, y: 59.5 },
    ])


    const handleClick = useCallback(() => {
        navigate("/chapitre2/room5/level2.3");
    }, [navigate])

    const changeDestination = (destination: TypePosDraggable) => {
        const i = destinations.indexOf(destination);
        if (i > - 1 && !destinations[i].occuped) {
            const arr = [...destinations];
            arr[i].occuped = true;
            setDestinations(arr);
        }
    }

    useEffect(() => {
        if (compte === 6) {
            setLeftArrow(70);
            if (arrowRef.current) {
                arrowRef.current.focus();
                arrowRef.current.addEventListener("click", handleClick);
                arrowRef.current.addEventListener("keydown", (e: KeyboardEventInit): void => {
                    if (e.key === "Enter") { handleClick(); }
                });
            }
        }
    }, [compte, handleClick]);

    const getTextAria = (): string => {
        const [levodopa, agoniste, inhibiteur] = myTextAria.draggable;
        let r = "";
        if (myTextAria.draggable) {
            if (location.state.choiceMed === "Lévodopa") {
                r = levodopa;
            } else if (location.state.choiceMed === "Dopaminergique") {
                r = agoniste;
            }
            else {
                r = inhibiteur;
            }
        }
        return r;
    }

    useEffect(() => {
        setSizeDestination(sizeObj(screenW, screenH,{width : 1920, height : 1080}, {width : 100, height : 50}));
    }, [screenH, screenW])


    const textOne = t("room_five.level_three.title_one");
    const textTwo = t("room_five.level_three.title_two");


    return (
        <FocusTrap>
            <div className="room2-container">

                <div
                    role="button"
                    tabIndex={0}
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "20px",
                        height: "20px"
                    }}
                    onKeyDown={() => { }}
                    onClick={() => handleClick()}
                    aria-label={getTextAria()}
                />

                <JonctionNeurone
                    paragraphe={`${textOne}${" "}<strong style = "color:#EE7D95">dopamine </strong>${" "}${textTwo}`}
                    elementJonction={
                        <>
                            {
                                [...Array(3)].map((x, i) =>
                                    <div id={`dopamine-container${i + 1}`}
                                        key={uuidv4()}
                                        role="button"
                                        tabIndex={-1}
                                        onClick={() => setDrag(true)}
                                        aria-hidden
                                    >
                                        <img src={Icon_Dopamine} className="dopamine" alt="dopamine" />
                                        <img src={Icon_Dopamine} className="dopamine" alt="dopamine" />
                                    </div>)
                            }

                            <div
                                role="button"
                                ref={arrowRef}
                                tabIndex={-1}
                                aria-hidden
                            >
                                <img src={Arrow} id="arrowBis"
                                    style={{ left: `${leftArrow}%` }}
                                    alt="arrow" />
                            </div>
                            {drag &&
                                <div style={{
                                    zIndex: "3"
                                }}>

                                    {
                                        start.map((elt, i) =>
                                            <DraggableGeneric
                                                dragConstraints={{ right: screenW/2, left: -screenW/3, top: -screenH/3, bottom: screenH/2}}
                                                startX={elt.x}
                                                startY={elt.y}
                                                destinations={destinations}
                                                sizeDestinationX = {sizeDestination[0]}
                                                sizeDestinationY = {sizeDestination[1]}
                                                heightImg="4.3%"
                                                imagePath={Icon_Dopamine}
                                                onChangeDestination={(dest) => changeDestination(dest)}
                                                onFinish={() => setCompte(compte + 1)} />
                                        )
                                    }

                                </div>
                            }
                        </>
                    }
                    useBackground
                />

            </div>
        </FocusTrap>
    )
}

export default Room5level2v2;
