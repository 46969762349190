import React from "react";
import i18next from "i18next";
import FocusTrap from "focus-trap-react";
import { useNavigate } from "react-router-dom";
import BasicButton from "../../components/buttons/BasicButton";
import { styleButton2 } from "../../helpers/stylesComponents";
import BackgroundFR from "../../assets/images/room8/BG.png";
import BackgroundEN from "../../assets/images/room8/BGEn.png";

function Flight(): JSX.Element {
    const navigate = useNavigate();
    const getCurrentLanguage = i18next.language;
    return (
        <FocusTrap>
            <div className="container-flight" style={{ background: getCurrentLanguage === "fr" ? `url(${BackgroundFR})` : `url(${BackgroundEN})`   }}>
                <BasicButton icon arrowV styleB={styleButton2}
                    click={() => {
                        navigate("/chapitre3/room8/level2");
                    }} />
            </div>
        </FocusTrap>

    )
}

export default Flight;
