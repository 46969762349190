import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import FocusTrap from "focus-trap-react";
import Poterie from "./poterie"
import Accrobranche from "./Accrobranche"
import Caligraphie from "./caligraphie"

function Room4level2(props: any): JSX.Element {
    const navigate = useNavigate();

    const [poterie, setPoterie] = useState<boolean>(false);
    const [accro, setAccro] = useState<boolean>(false);
    const [cali, setCali] = useState<boolean>(false);

    const [poterieDone, setPoterieDone] = useState<boolean>(false);
    const [accroDone, setAccroDone] = useState<boolean>(false);
    const [caliDone, setCaliDone] = useState<boolean>(false);
    const [flower, setFlower] = useState<boolean>(false);

    const handleClickPoterie = (): void => {
        setPoterie(false);
        setPoterieDone(true);
        setFlower(true);
    }

    const handleClickAccro = (): void => {
        setAccro(false);
        setAccroDone(true);
    }

    const handleClickCali = (): void => {
        setCali(false);
        setCaliDone(true);
    }

    useEffect(() => {
        if (flower) {
            const div: any = document.querySelector(".sous-div-side");
            div.classList.add("flower-back");
        }
    }, [flower]);

    useEffect(() => {
        if (poterieDone && accroDone && caliDone) {
            navigate("/chapitre1/room4/level3");
        }
    }, [poterieDone, accroDone, caliDone, navigate]);



    return (
        <FocusTrap>
            <div className="room4Container" id='row'>
                {poterie && <div className="poteriePopup-contain"><Poterie handleClickPoterie={handleClickPoterie} /></div>}
                {accro && <div className="poteriePopup-contain"><Accrobranche handleClickCloseAccro={handleClickAccro} /></div>}
                {cali && <div className="poteriePopup-contain"><Caligraphie handleClose={handleClickCali} /></div>}
                <div className="div-side">
                    <input type="button" id="accrobranche-left-button" tabIndex={0} className="room4-bouton-div" aria-label="aller vers l'exercice d'accrobranche"
                        onClick={(): void => {
                            setAccro(true);
                        }} />
                    <div className="sous-div-side">
                        <input type="button" id="poterie-button" className="room4-bouton-div" aria-label="aller vers l'exercice de poterie"
                            onClick={(): void => {
                                setPoterie(true);
                            }} />
                    </div>
                </div>

                <div className="div-middle">
                    <div id="div-middle-top" />
                    <div id="div-middle-bottom">
                        <input type="button" id="accrobranche-mid-button" tabIndex={0} className="room4-bouton-div" aria-label="aller vers l'exercice d'accrobranche"
                            onClick={(): void => {
                                setAccro(true);
                            }} />
                    </div>
                </div>

                <div className="div-side">
                    <input type="button" id="accrobranche-right-button" tabIndex={0} className="room4-bouton-div" aria-label="aller vers l'exercice d'accrobranche"
                        onClick={(): void => {
                            setAccro(true);
                        }} />
                    <div className="sous-div-side" id="sous-div-side-right">
                        <div id="sous-sous-div-side-right">
                            <input type="button" id="calligraphie-button" tabIndex={0} className="room4-bouton-div" aria-label="aller vers l'exercice de calligraphie"
                                onClick={(): void => {
                                    setCali(true);
                                }} />
                        </div>
                    </div>
                </div>
            </div>
        </FocusTrap>
    );
}

export default Room4level2;
