import React, { useEffect, useState } from "react";
import GradientFill from "../../assets/images/room2/Tableau_Dopamine/Gradient Fill 1 copy 2.png";
import VectorSmart from "../../assets/images/room2/Tableau_Dopamine/Vector Smart Object.png";
import { getPosition, getPourcentObj, sizeObj } from "../../utils/funcUtils";
import { placements, styleElement } from "../../utils/utilsPlacement";
import ScreenSize from "../../utils/ScreenSize";
import { JonctionProps } from "../../types/Jonction-type"
import { stylePopUp } from "../../helpers/stylesComponents"

const parse = require('html-react-parser');

type TypeObject = {
    position: Array<number>;
    size: Array<number>;
};

type TypeObjectPathJson = {
    x: number;
    y: number;
    width: number;
    height: number;
};

const pathJson: Array<TypeObjectPathJson> = ([
    { "x": 355, "y": 540, "width": 504, "height": 395 },
    { "x": 1565, "y": 540, "width": 508, "height": 402 },
]);
const pathJsonBack = { "width": 1920, "height": 1080 };

function JonctionNeurone(props: JonctionProps): JSX.Element {
    const content: JonctionProps = props;
    const screenW: number = ScreenSize().width;
    const screenH: number = ScreenSize().height;

    const [pourcent, setPourcent] = useState<Array<number>>(getPourcentObj(screenW, screenH, pathJsonBack));
    const [placement, setPlacement] = useState<Array<TypeObject>>(placements(screenW, screenH, pathJson, pathJsonBack))
    const style = styleElement(placement, pourcent);

    useEffect(() => {
        if (!content.useBackground) {
            setPourcent(getPourcentObj(screenW, screenH, pathJsonBack));
            setPlacement(current =>
                current.map((obj, index) => {
                    return { ...obj, size: sizeObj(screenW, screenH, pathJsonBack, pathJson[index]) };
                })
            );
            setPlacement(current =>
                current.map((obj, index) => {
                    return { ...obj, position: getPosition(screenW, screenH, pathJson[index]) };
                })
            );
        }
    }, [screenW, screenH, content.useBackground]);

    return (
        <>
            <p className="pJonction size2" aria-hidden style={stylePopUp}>{parse(content.paragraphe)}</p>
            {
                !content.useBackground &&
                <>
                    <div className="container-GradientFill absolute" style={style[0]}>
                        <img src={GradientFill} alt="" />
                    </div>
                    <div className="container-VectorSmart absolute" style={style[1]}>
                        <img src={VectorSmart} alt="" />
                    </div>
                </>
            }
            {content.elementJonction}
        </>
    );
}

export default JonctionNeurone;
