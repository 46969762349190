import React from "react";
import FocusTrap from "focus-trap-react";
import {useNavigate} from 'react-router-dom';
import { styleButton2 } from "../../../helpers/stylesComponents";
import PopUpWithButton from "../../../components/popups/PopUpWithButton";
import TextPopup from "../../../helpers/textPopUp"

function Room3Start(): JSX.Element {
    const navigate = useNavigate();

    const handleClick = (): void => {
        navigate("/chapitre1/room3/level1.2");
    };

    return (
        <FocusTrap>
            <div className="room3Container" id="background-template" role="alertdialog" aria-modal="true">
                <PopUpWithButton
                    title={TextPopup.room3[0].title}
                    contents={TextPopup.room3[0].contents}
                    button={TextPopup.room3[0].button}
                    styleBtn={{...styleButton2 }}
                    click={handleClick}
                />

            </div>
        </FocusTrap>
    );
}

export default Room3Start;
