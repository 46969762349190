import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import FocusTrap from "focus-trap-react";
import Flower from "../../assets/images/room4/Flower_Step01.png";
import Flower2 from "../../assets/images/room4/Flower_step02.png";
import Flower3 from "../../assets/images/room4/Flower_Step03.png";
import Flower4 from "../../assets/images/room4/Flower_step04.png";
import Flower5 from "../../assets/images/room4/Flower_Step05.png";
import Flower6 from "../../assets/images/room4/Flower_Step06.png";
import Flower7 from "../../assets/images/room4/Flower_Step07.png";
import Flower8 from "../../assets/images/room4/Flower_Step08.png";
import Flower9 from "../../assets/images/room4/Flower_Step09.png";

import { PoterieProps } from "../../types/Poterie-type";
import BasicButton from "../../components/buttons/BasicButton";
import { stylePopUp, styleButton1 } from "../../helpers/stylesComponents";

function Poterie(props: PoterieProps): JSX.Element {

    const { t } = useTranslation();
    const poterieProps = props;


    interface Text {
        h1: string,
        p: string,
    }

    const [compte, setCompte] = useState<number>(0);

    const [bouton, setBouton] = useState<boolean>(false);

    const [content, setContent] = useState<Text>({
        h1: t("room_four.level_two.exercice_pottery.title"),
        p: t("room_four.level_two.exercice_pottery.subtitle")
    });


    const [next, setNext] = useState<boolean>(false);

    const altFleur = `${compte} pétales sculptés`;

    const redirect = (): void => {
        poterieProps.handleClickPoterie();
    }

    
    useEffect(() => {
        if (compte >= 8) {
            setBouton(true);
            setNext(true);
        }
    }, [compte])
    
    useEffect(() => {
        const changeText = (): void => {
            setContent({
                h1: t("room_four.level_two.exercice_pottery.description"),
                p: ""
            })
        }
        
        if (next) {
            changeText();
        }
    }, [next, t])

    const styleNext = next && {
        top : "50%",
        transform: "translate(-50%,-50%)",
    };

    const btnReturn = t("room_four.level_two.exercice_pottery.btn");

    return (
        <FocusTrap>
            <div className="poterie-container" >
                <div className="poterie-sous-container-top">
                    <div className="divRadius" id="divRadius-ajust" style={{...stylePopUp, ...styleNext}}role="alert">
                        <h1 className={`size${next ? 3 : 2}` }>{content.h1}</h1>
                        <p className="size4">{content.p}</p>
                    </div>
                </div>
                <div className="poterie-sous-container-middle">
                    <button
                        className="flower-container"
                        type="button"
                        tabIndex={0}
                        onClick={(): void => {
                            setCompte(compte + 1);
                        }}
                    >
                        {compte === 0 && <img src={Flower} id="flower" alt="fleur à sculpter"/>
                        }
                        {compte === 1 && <img src={Flower2} id="flower" alt={altFleur}/>
                        }
                        {compte === 2 && <img src={Flower3} id="flower" alt={altFleur}/>
                        }
                        {compte === 3 && <img src={Flower4} id="flower" alt={altFleur}/>
                        }
                        {compte === 4 && <img src={Flower5} id="flower" alt={altFleur} />
                        }
                        {compte === 5 && <img src={Flower6} id="flower" alt={altFleur} />
                        }
                        {compte === 6 && <img src={Flower7} id="flower" alt={altFleur} />
                        }
                        {compte === 7 && <img src={Flower8} id="flower" alt={altFleur} />
                        }
                        {compte >= 8 && <img src={Flower9} id="flower" alt={altFleur} />
                        }
                    </button>

                </div>

                <div className="poterie-sous-container-bottom">
                    {bouton &&
                        <FocusTrap>
                            <div className="btn-container" aria-label="revenir">
                                <BasicButton
                                click={redirect}
                                text={btnReturn}
                                styleB={{...styleButton1,
                                position : "absolute",
                                top : "72%",
                                left : "50%",
                                transform : "translate(-50%, -70%)"

                                }} />
                            </div>
                        </FocusTrap>
                    }
                </div>

            </div>
        </FocusTrap>
    );
}

export default Poterie;
