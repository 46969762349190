import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FocusTrap from "focus-trap-react";
import PopUpWithButton from "../../components/popups/PopUpWithButton";
import BasicPopUp from "../../components/popups/BasicPopUp";
import CardPopUp from "../../components/room9/CardPopUp"
import textPopUp from "../../helpers/textPopUp";
import image1 from "../../assets/images/room9/Riziere.png";
import image2 from "../../assets/images/room9/Elephant.png";

function Vacation(): JSX.Element {

    const navigate = useNavigate();

    // This number is used to determine which pop-up to show
    const [viewIndex, setViewIndex] = useState<number>(0);
    const [clickedCardIndex, setClickedCardIndex] = useState<number>(-1);
    
    // Going to the next pop-up
    const switchView = () => {
        setViewIndex(viewIndex + 1);
    };

    const saveVacation = (option: string) => {
        // Remove the vacation option from the local storage
        localStorage.removeItem("_vacation");
        
        // Save the vacation option in the local storage
        localStorage.setItem("_vacation", option);  
    }

    useEffect(() => {
        if (viewIndex === 3) {
            navigate("/chapitre4/room9/level2");
        }
    }, [navigate, viewIndex]);

    return (
        <FocusTrap>
            <div className="container-vacation" id="hotel">
                {
                    viewIndex === 0 && <PopUpWithButton
                        title={textPopUp.room9[0].title}
                        button={textPopUp.room9[0].button}
                        click={switchView}
                        styleBtn={{
                            padding: "1% 0 1% 0",
                            width: "20%",
                        }}
                    />
                }
                {
                    viewIndex === 1 &&
                    <>
                        <BasicPopUp
                            title={textPopUp.room9[1].title}
                            content={textPopUp.room9[1].content}
                        />
                        <CardPopUp
                            title={textPopUp.room9[2].title}
                            description={textPopUp.room9[2].content}
                            clickable
                            onClick={() => {
                                setClickedCardIndex(0);
                                switchView();
                                saveVacation("plantation");
                            }}
                            image={image1}
                            class="card-left"
                            />
                        <CardPopUp
                            title={textPopUp.room9[3].title}
                            description={textPopUp.room9[3].content}
                            clickable
                            onClick={() => {
                                setClickedCardIndex(1);
                                switchView();
                                saveVacation("elephants");
                            }}
                            image={image2}
                            class="card-right"
                        />
                    </>
                }
                {
                    viewIndex === 2 && (
                        <div className="tea-activity">
                            <CardPopUp
                                title={clickedCardIndex === 0 ? textPopUp.room9[2].title : textPopUp.room9[3].title}
                                description={clickedCardIndex === 0 ? textPopUp.room9[2].content : textPopUp.room9[3].content}
                                clickable={false}
                                image={clickedCardIndex === 0 ? image1 : image2}
                                class="card-left"
                            />
                            <PopUpWithButton
                                title={clickedCardIndex === 0 ? textPopUp.room9[4].title : textPopUp.room9[21].title}
                                button={textPopUp.room9[4].button}
                                click={switchView}
                                styleBtn={{
                                    padding: "1% 0 1% 0",
                                    width: "20%",
                                }}
                            />
                        </div>
                    )
                }
            </div>
        </FocusTrap>
    );
}

export default Vacation;
