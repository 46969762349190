import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FocusTrap from "focus-trap-react";
import Paint_dopamine from "../../../assets/images/room2/Paint_dopamine.png";
import { PopUpProps } from "../../../types/BasicPopUp-types";
import myTextPopUp from "../../../helpers/textPopUp";
import PopUpWithButton from "../../../components/popups/PopUpWithButton";

export default function LevelOne() {

  const titre: string = myTextPopUp.room2[0].title;
  const text: string = myTextPopUp.room2[0].content;

  const [popUp, setPopUp] = useState<PopUpProps>({
    title: titre,
    content: text,
    show: true,
  });

  const [tablau, settablau] = useState<PopUpProps>({
    show: false,
  });

  const navigate = useNavigate();
  const handleClick = (): void => {
    navigate("/chapitre1/room2/level2");
  };
  const handleKeyDown = (e: any) => {
    e.preventDefault();
    if (e.key === " " || e.key === "Enter") {
      navigate("/chapitre1/room2/level2");
    }
  };

  return (
    <div className="back_level_1">
      {popUp.show && (
        <FocusTrap>
          <PopUpWithButton
          style={{zIndex : 2}}
          title={popUp.title}
          content={popUp.content}
          button={myTextPopUp.room2[0].button}
          click={(e) => {
            setPopUp({
              show: false,
            });
            settablau({
              show: true,
            });
          }}
          />
        </FocusTrap>
      )}

      <div>
        {
          tablau.show ? (
            <FocusTrap
              focusTrapOptions={{
                fallbackFocus: "#paint_dopamine",
              }}
            >
              <div
                role="button"
                tabIndex={0}
                aria-hidden="true"
                onClick={handleClick}
                onKeyDown={handleKeyDown}
                style={{ cursor: "pointer" }}
                aria-label="Tableau sur la dopamine"
              >
                <img src={Paint_dopamine} id="paint_dopamine" tabIndex={-1} className="tableudopamine" alt="Tableau sur la dopamine" />
              </div>
            </FocusTrap>
          ) : (
            <img src={Paint_dopamine} className="tableudopamine" alt="Tableau sur la dopamine" />
          )
        }
      </div>
    </div>
  );
}
