import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import { useNavigate } from "react-router-dom";
import FocusTrap from "focus-trap-react";
import { Player } from 'video-react';
import BasicButton from "../../components/buttons/BasicButton";
import PopUpWithButton from "../../components/popups/PopUpWithButton";

import textPopUp from "../../helpers/textPopUp";
import Certificat from "../../assets/images/room9/Certificat.png";
import CertificatEn from "../../assets/images/room9/CertificatEn.png";
import BasicPopUp from "../../components/popups/BasicPopUp";

import DigiparkLogo from "../../assets/images/room9/logoDigipark.png";
import CreditsLogo from "../../assets/images/room9/credits.jpeg";

import "video-react/dist/video-react.css";
import styles from "./End.module.css"

function End() {
    const { t } = useTranslation();

    const navigate = useNavigate();

    // This number is used to determine which pop-up to show
    const [viewIndex, setViewIndex] = useState<number>(0);

    const [divId, setDivId] = useState<string>("riziere");

    // Going to the next pop-up
    const switchView = () => {
        setViewIndex(viewIndex + 1);
    };

    const getCurrentLanguage = i18next.language;

    const thanks = t("room_nine.level_three.thanks");
    const credits = [
        t("room_nine.level_three.credits.title"),
        t("room_nine.level_three.credits.one"),
        t("room_nine.level_three.credits.two"),
    ]

    const btnEnd = [
        t("room_nine.level_three.btn_click"),
        t("room_nine.level_three.btn_return"),
    ]
    return (
        <FocusTrap>
            <div className="container-vacation" id={divId}>
                {
                    viewIndex === 0 &&
                    <PopUpWithButton
                        icon
                        arrowBlack
                        styleBtn={{
                            backgroundColor: "white",
                        }}
                        title={textPopUp.room9[12].title}
                        button={textPopUp.room9[12].button}
                        click={() => {
                            setDivId("end");
                            switchView();
                        }}
                    />

                }
                {
                    viewIndex === 1 && (
                        <div className="blur-background">
                            <PopUpWithButton
                                icon
                                arrowBlack
                                styleBtn={{
                                    backgroundColor: "white",
                                }}
                                title={textPopUp.room9[14].title}
                                button={textPopUp.room9[14].button}
                                click={switchView}
                            />
                        </div>
                    )}
                {
                    viewIndex === 2 && (
                        <div className="blur-background">
                            <div className="certificat">
                                <img src={getCurrentLanguage === "fr" ? Certificat : CertificatEn} alt="Certificat"
                                    style={{
                                        position: "absolute",
                                        top: "35%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        width: "40%",
                                        height: "auto",
                                    }}
                                />
                                <PopUpWithButton
                                    title={textPopUp.room9[15].title}
                                    button={textPopUp.room9[17].button}
                                    click={switchView}
                                    icon
                                    arrowBlack
                                    styleBtn={{
                                        backgroundColor: "white",
                                    }}
                                />
                            </div>
                        </div>
                    )}

                {
                    viewIndex === 3 && (
                        <div className="blur-background" >
                            <div className="end-top">
                                <BasicPopUp title={textPopUp.room9[17].title}/>
                            </div>
                            <div className="end-middle">
                                <BasicPopUp
                                    title={textPopUp.room9[18].title}
                                />
                            </div>
                            <div className={styles.viewIndex3}>
                                <div className={styles.cardIndex3} >
                                    <img src={DigiparkLogo} alt="logo de digipark" className={styles.cardImgIndex3}/>
                                    <div className={styles.cardTextIndex3}>
                                        <h3>DigiPark App</h3>
                                        <a href="https://digipark.fr/" target="blank">{btnEnd[0]}</a>
                                    </div>
                                </div>
                                <div className={styles.creditsCardIndex3} >
                                    <img src={CreditsLogo} alt="Credits" className={styles.cardImg2Index3}/>
                                  <p style={{ textAlign: "center"}}>{credits[0]}</p>
                                    <span>{credits[1]}</span>
                                    <span>{credits[2]}</span>
                                </div>
                                <div className={styles.cardIndex3} >
                                    <img src={DigiparkLogo} alt="logo de digipark" className={styles.cardImgIndex3}/>
                                    <div className={styles.cardTextIndex3}>
                                        <h3>DigiWatch App</h3>
                                        <a href="https://play.google.com/store/apps/details?id=com.diamwatch.lite&hl=ln&gl=US" target="blank">{btnEnd[0]}</a>
                                    </div>
                                </div>
                            </div>
                            <div className="end-bottom">
                               <BasicButton
                                click={switchView}
                                icon
                                styleB={{
                                    position: "absolute",
                                    top: "90%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    padding: "2rem 4rem",
                                }}
                            />
                            </div>
                            <div className={styles.creditsIndex3}>                            
                                <span>{thanks}</span>
                            </div>
                        </div>
                    )
                }
                {
                    viewIndex === 4 && (
                        <div className="blur-background" >
                            <div className="end-top-video">
                                <BasicPopUp title="Motiv’Park Game: Sri Lanka"/>
                            </div>
                            <div className={styles.viewIndex4} aria-label="video de danse">
                                 <Player
                                    playsInline
                                    poster="/assets/poster.png"
                                    src="https://res.cloudinary.com/dig8acstk/video/upload/v1684930994/motiv_park_game__sri_lanka_540p_mgwpj1.mp4"
                                />
                            </div>
                            <div className="end-bottom">
                                <BasicButton
                                    text={btnEnd[1]}
                                    click={() => {
                                        navigate("/*");
                                    }}
                                    styleB={{
                                        position: "absolute",
                                        top: "90%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        padding: "2rem 4rem",
                                        marginTop: "2rem",
                                    }}
                                />
                            </div>
                        </div>
                    )
                }
            </div>
        </FocusTrap>
    )
}

export default End;
