import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import FocusTrap from "focus-trap-react";
import PopUpWithButton from "../../components/popups/PopUpWithButton";
import ScreenSize from "../../utils/ScreenSize";
import { getPosition, getPourcentObj, sizeObj } from "../../utils/funcUtils";
import { TypeJsonRoom7, TypeObject } from "../../utils/typesUtils";
import { selectChap, unLockChap3 } from '../../slices/chapitresSlice';
import { generationPathJson, getObjectJson, placements, styleElement } from "../../utils/utilsPlacement";
import Draggable from "../../components/animated/DraggableGeneric";
import { TypePosDraggable } from "../../types/Draggable-type";
import JsonRoom7 from "../../assets/images/room7/Room_07_FullHD.json";

const pathEltJson = JsonRoom7.skins.default;
const pathJsonBack: TypeJsonRoom7 = pathEltJson.Background.Background;

const pathJson = generationPathJson();

function DepartureVacation(): JSX.Element {
  const { t } = useTranslation();
  const screenW: number = ScreenSize().width;
  const screenH: number = ScreenSize().height;
  const chapUnlock = useSelector(selectChap);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [pourcent, setPourcent] = useState<Array<number>>(getPourcentObj(screenW, screenH, pathJsonBack));
  const [placement, setPlacement] = useState<Array<TypeObject>>(placements(screenW, screenH, pathJson, pathJsonBack))
  const style = styleElement(placement, pourcent);

  // Visibilités des popup
  const [visibleFirstPopUp, setVisibleFirstPopUp] = useState<boolean>(true);
  const [visibleConseilPopUp, setVisibleConseilPopUp] = useState<boolean>(false);
  const [visibleLastPopUp, setVisibleLastPopUp] = useState<boolean>(false);
  const [conseilCourant, setConseilCourant] = useState<string>("");
  const [destinations, setDestinations] = useState<TypePosDraggable[]>(
    pathJson.slice(0, 10).map((elt) => {
      return (elt.destValise1) ?
        { x: placement[10].position[0] * pourcent[0] / 100, y: placement[10].position[1] * pourcent[1] / 100, occuped: false } :
        { x: placement[11].position[0] * pourcent[0] / 100, y: placement[11].position[1] * pourcent[1] / 100, occuped: false }
    })
  );


  useEffect(() => {
    setPourcent(getPourcentObj(screenW, screenH, pathJsonBack));
    setPlacement(current =>
      current.map((obj, index) => {
        return { ...obj, position: getPosition(screenW, screenH, pathJson[index]) };
      })
    );
    setPlacement(current =>
      current.map((obj, index) => {
        return { ...obj, size: sizeObj(screenW, screenH, pathJsonBack, pathJson[index]) };
      })
    );

  }, [screenH, screenW])

  useEffect(() => {
    pathJson.slice(0, 10).map((elt) => {
      return (elt.destValise1) ?
        { x: placement[10].position[0] * pourcent[0] / 100, y: placement[10].position[1] * pourcent[1] / 100, occuped: false } :
        { x: placement[11].position[0] * pourcent[0] / 100, y: placement[11].position[1] * pourcent[1] / 100, occuped: false }
    })
  }, [placement, pourcent])

  useEffect(() => {
    setVisibleLastPopUp(destinations.filter((elt) => !elt.occuped).length === 0)

  }, [destinations, placement])


  // Ferme la première pop up
  const closeFirstPopUp = () => {
    setVisibleFirstPopUp(false);

  };


  // Ferme la pop up conseil
  const closeConseilPopUp = () => {
    setVisibleConseilPopUp(false);
  }

  // Retourne une popUp conseil selon le message transcrit
  const generatePopUpConseil = (message: string) => {
    return (
      <PopUpWithButton
        title={message}
        button="OK !"
        click={closeConseilPopUp}
      />
    );
  }

  const tipsText = [
    t("room_seven.level_one.popup_tip_one.text"),
    t("room_seven.level_one.popup_tip_two.text"),
    t("room_seven.level_one.popup_tip_three.text"),
    t("room_seven.level_one.popup_tip_four.text"),
  ]

  const changeDestination = (destination: TypePosDraggable) => {
    const i = destinations.indexOf(destination);
    if (i > - 1 && !destinations[i].occuped) {
      const arr = [...destinations];
      arr[i].occuped = true;
      setDestinations(arr);
      if (["Prescription", "Smartphone", "Pilulier", "BoitesMedoc"].find(e => e === pathJson[i].name)) {
        setVisibleConseilPopUp(true);
        switch (pathJson[i].name) {
          case "Prescription":
            setConseilCourant(tipsText[1]);
            break;
          case "Smartphone":
            setConseilCourant(tipsText[3]);
            break;
          case "Pilulier":
            setConseilCourant(tipsText[2]);
            break;
          case "BoitesMedoc":
            setConseilCourant(tipsText[0]);
            break;
          default:
            break;

        }
      }
    }
  }

  const firstText = t("room_seven.level_one.title");
  const secondText = t("room_seven.level_one.subtitle");
  const btnText = t("room_seven.level_one.btn");
  const readyText = t("room_seven.level_one.ready");

  return (
    <FocusTrap>
      <div className="container-DepartureVacation">
        {/* Gestion pop up */}
        {(visibleFirstPopUp || visibleConseilPopUp || visibleLastPopUp) && <div className="result">
          {visibleFirstPopUp &&
            <PopUpWithButton
              title={firstText}
              content={secondText}
              button={btnText}
              click={closeFirstPopUp} />}

          {visibleConseilPopUp &&
            generatePopUpConseil(conseilCourant)}

          {visibleLastPopUp && !visibleConseilPopUp &&
            <PopUpWithButton
              title={readyText}
              button={btnText}
              click={() => {
                if (!chapUnlock.chap3) dispatch(unLockChap3(true));
                navigate("/home");
              }}
            />}
        </div>}

        {
          !visibleFirstPopUp && !visibleConseilPopUp && !visibleLastPopUp && (
            <div
              role="button"
              tabIndex={0}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
              }}
              onKeyDown={(e) => {
                e.preventDefault();
                if (e.key === "Enter" || e.key === " ") {
                  setVisibleLastPopUp(true);
                }
              }}
              onClick={() => setVisibleLastPopUp(true)}
              aria-label="N'oubliez pas de préparer vos valises"
            />
          )
        }

        {
          pathJson.slice(0, 10).map((elt, i) => {
            return <Draggable dragConstraints={{ right: screenW / 2, left: -screenW / 3, top: -screenH / 3, bottom: screenH / 2 }}
              startX={((elt.x / screenW) * 100)}
              startY={((elt.y / screenH) * 100)}
              destinations={[destinations[i]]}
              sizeDestinationX={elt.destValise1 ? parseFloat(style[10].width) : parseFloat(style[11].width)}
              sizeDestinationY={elt.destValise1 ? parseFloat(style[10].height) : parseFloat(style[11].height)}
              pJson={elt}
              onChangeDestination={(dest: TypePosDraggable) => changeDestination(dest)}
              imagePath={elt.img} />
          })
        }


        <img className="valises" aria-hidden="true" style={style[10]} src={getObjectJson("Valise", pathJson)?.img} alt="Valise gauche" />
        <img className="valises" aria-hidden="true" style={style[11]} src={getObjectJson("Valise2.0", pathJson)?.img} alt="Valise droit" />
      </div>
    </FocusTrap>
  );
}

export default DepartureVacation;

