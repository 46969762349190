const myTextAria = {
    animations : [
        {
            levodopa : {
                p1 : "Lorsque la gélule est ingérée, elle arrive dans le système digestif, où elle libère la Lévodopa.",
                p2 : "Ensuite, elle passe dans le système sanguin où elle est transportée jusqu'au cerveau.",
                p3 : "Par la suite, elle passe dans le tissu cérébral et se transforme en dopamine.",
            }
        },{
            agoniste : {
                p1 : "Lorsque la gélule est ingérée, elle arrive dans le système digestif, où elle libère les agonistes dopaminergiques.",
                p2 : "Ensuite, ceux-ci passent dans le système sanguin où ils sont transportés jusqu'au cerveau.",
                p3 : "Par la suite, elle passe dans le tissu cérébral et se transforme en dopamine.",
            }
        },{
            inhibiteur : {
                p1 : "Lorsque la gélule est ingérée, elle arrive dans le système digestif, où elle libère les inhibiteurs de la dégradation de la dopamine.",
                p2 : "Ensuite, ils passent dans le système sanguin ",
                p3 : "où ils sont transportés jusqu'au cerveau.",
            }
        },
    ],
    draggable : [
        "Les molécules de dopamine sortent du neurone émetteur et viennent se fixer sur les récepteurs du neurone récepteur pour transmettre le message nerveux.",
        "Les molécules d’agonistes dopaminergiques sont dans l’espace entre le neurone émetteur et le neurone récepteur. Ils viennent se fixer sur les récepteurs de la dopamine pour la dopamine.",
        "Naturellement, il existe des agents nommés enzymes qui détruisent la dopamine. Les inhibiteurs de la dégradation de la dopamine neutralisent les enzymes qui détruisent la dopamine. ",
    ]
}

export default myTextAria;