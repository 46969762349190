import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

export default function PhoneBlocked() {
    return (
        <div id="phone-container">
            <h1>L&apos;application n&apos;est pas disponible pour les téléphones, préférez une tablette ou un ordinateur.</h1>
            <FontAwesomeIcon icon={faCircleExclamation} />
        </div>
    );
}
