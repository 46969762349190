import React from "react";
import { motion } from "framer-motion";
import { PadButtonData } from "../../types/PadButtonData-types";

function PadButton(props: any): JSX.Element {
  const buttonData: PadButtonData = props;

  const style = {
    borderRadius: "10%",
    border: "1px solid #19372b00",
    color: "#19372b",
  }

  return (
    <motion.input
      style={{ ...style, ...buttonData.styleB }}
      type="submit"
      value=""
      aria-label={buttonData.letter}
      className="pad-button"
      onClick={(e) => {
        buttonData.typing(buttonData.letter);
      }}
      whileTap={{ scale: 0.99 }}
      transition={{ type: "spring", stiffness: 400, damping: 10 }}
      tabIndex={-1}
    />
  );
}

export default PadButton;
