export const stylePopUp : object = {
    background: "rgba(255,255,255, 0.5) 0% 0% no-repeat padding-box",
    border: "3px solid rgb(255,255,255, 1)",
    textAlign: "center",
    borderRadius: "60px",
    backdropFilter: "blur(50px)",
    boxShadow: "2px 2px 3px rgba(0, 0, 0, 0.6)",
    color:"black",
    marginBottom: "15px",

};

export const styleButton1 : object = {
    color: "white",
    backgroundColor:"#055C5C",
    cursor:"pointer",
    fontWeight: "bold",
};

export const styleButton2 : object = {
    color: "#055C5C",
    backgroundColor:"white",
    cursor:"pointer",
    fontWeight: "bold",
};