import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import Analytics from "./Analytics";

import ScreenSize from "./utils/ScreenSize";

import Home from "./containers/home";
import Pad from "./containers/room1/level5/Pad";
import Level1 from "./containers/room1/level1/Level1";
import Quizz from "./containers/room1/level2/Quizz";
import QuizzResult from "./containers/room1/level3/QuizzResult";
import PadAccess from "./containers/room1/level4/PadAccess";
import Levelone from "./containers/room2/level1/level1.1";
import Leveltwo from "./containers/room2/level2/level2.1";
import Level2Suite from "./containers/room2/level2/level2.2";
import LevelThree from "./containers/room2/level3/level3.1";
import LevelFour from "./containers/room2/level4/level4.1";
import LevelFive from "./containers/room2/level5/level5.1";
import Room3Start from "./containers/room3/level1/level1.1";
import Room3Brain from "./containers/room3/level1/level1.2";
import Room3BrainQuestion from "./containers/room3/level1/level1.3";
import Room3end from "./containers/room3/level1/level1.4";
import Room4level1 from "./containers/room4/level1";
import Room4level2 from "./containers/room4/level2";
import Room4level3 from "./containers/room4/level3";
import Room5Level1 from "./containers/room5/level1/level1.1";
import Room5Level2 from "./containers/room5/level2/level2.1";
import Room5Level2v2 from "./containers/room5/level2/level2.2";
import Room5Level2v3 from "./containers/room5/level2/level2.3";
import Room5Level3 from "./containers/room5/level3/level3.1";
import Room5Level3v2 from "./containers/room5/level3/level3.2";
import Room5Level3v3 from "./containers/room5/level3/level3.3";
import Room5Level4 from "./containers/room5/level4/level4.1";
import DisparitionEnzymes from "./containers/room5/level4/level4.2";
import Room5Level4v3 from "./containers/room5/level4/level4.3";
import Room6Level1 from "./containers/room6/level1/level1.1";
import Room6level2 from "./containers/room6/level2/level2.1";
import Room6level3 from "./containers/room6/level3/level3.1";
import Room6level4 from "./containers/room6/level4/level4.1";
import Room6level5 from "./containers/room6/level5/level5.1";

import RoomParchemin from "./containers/room5/RoomParchemin";

import DepartureVacation from "./containers/room7/DepartureVacation";
import Flight from "./containers/room8/Flight";
import VideoTherapie from "./containers/room8/VideoTherapie";
import Vacation from "./containers/room9/Vacation";
import OptionsPrescriptions from "./containers/room9/OptionsPrescriptions";
import End from "./containers/room9/End";
import PhoneBlocked from "./containers/PhoneBlocked";

import { selectChap } from './slices/chapitresSlice';

function App() {
  const screenSizeW: number = ScreenSize().width;
  const screenSizeH: number = ScreenSize().height;

  const chapterUnlock = useSelector(selectChap);

  return (
    <div className="App">
      <Analytics />
      {
        screenSizeW <= 450 || screenSizeH <= 450 ? (
          <Routes>
            <Route path="/" element={<PhoneBlocked />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/chapitre1/room1/level1" element={<Level1 />} />
            <Route path="/chapitre1/room1/level2" element={<Quizz />} />
            <Route path="/chapitre1/room1/level3" element={<QuizzResult />} />
            <Route path="/chapitre1/room1/level4" element={<PadAccess />} />
            <Route path="/chapitre1/room1/level5" element={<Pad />} />
            <Route path="/chapitre1/room2/level1" element={<Levelone />} />
            <Route path="/chapitre1/room2/level2" element={<Leveltwo />} />
            <Route path="/chapitre1/room2/level2.2" element={<Level2Suite />} />
            <Route path="/chapitre1/room2/level3" element={<LevelThree />} />
            <Route path="/chapitre1/room2/level4" element={<LevelFour />} />
            <Route path="/chapitre1/room2/level5" element={<LevelFive />} />
            <Route path="/chapitre1/room3/level1" element={<Room3Start />} />
            <Route path="/chapitre1/room3/level1.2" element={<Room3Brain />} />
            <Route path="/chapitre1/room3/level1.3" element={<Room3BrainQuestion />} />
            <Route path="/chapitre1/room3/level1.4" element={<Room3end />} />
            <Route path="/chapitre1/room4/level1" element={<Room4level1 />} />
            <Route path="/chapitre1/room4/level2" element={<Room4level2 />} />
            <Route path="/chapitre1/room4/level3" element={<Room4level3 />} />

            {chapterUnlock.chap2 &&
              <>
                <Route path="/chapitre2/room5/level1" element={<Room5Level1 />} />
                <Route path="/chapitre2/room5/level2" element={<Room5Level2 />} />
                <Route path="/chapitre2/room5/level2.2" element={<Room5Level2v2 />} />
                <Route path="/chapitre2/room5/level2.3" element={<Room5Level2v3 />} />
                <Route path="/chapitre2/room5/level3" element={<Room5Level3 />} />
                <Route path="/chapitre2/room5/level3.2" element={<Room5Level3v2 />} />
                <Route path="/chapitre2/room5/level3.3" element={<Room5Level3v3 />} />
                <Route path="/chapitre2/room5/level4" element={<Room5Level4 />} />
                <Route path="/chapitre2/room5/level4.2" element={<DisparitionEnzymes />} />
                <Route path="/chapitre2/room5/level4.3" element={<Room5Level4v3 />} />

                <Route path="/chapitre2/room5/levelParchemin" element={<RoomParchemin />} />
                <Route path="/chapitre2/room6/level1" element={<Room6Level1 />} />
                <Route path="/chapitre2/room6/level2" element={<Room6level2 />} />
                <Route path="/chapitre2/room6/level3" element={<Room6level3 />} />
                <Route path="/chapitre2/room6/level4" element={<Room6level4 />} />
                <Route path="/chapitre2/room6/level5" element={<Room6level5 />} />
                <Route path="/chapitre2/room7/level1" element={<DepartureVacation />} />
              </>
            }

            {chapterUnlock.chap3 &&
              <>
                <Route path="/chapitre3/room8/level1" element={<Flight />} />
                <Route path="/chapitre3/room8/level2" element={<VideoTherapie />} />
              </>
            }
            {chapterUnlock.chap4 &&
              <>
                <Route path="/chapitre4/room9/level1" element={<Vacation />} />
                <Route path="/chapitre4/room9/level2" element={<OptionsPrescriptions />} />
                <Route path="/chapitre4/room9/level3" element={<End />} />
              </>
            }
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        )}

    </div>
  );
}

export default App;
