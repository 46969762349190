import React from "react";
import { useNavigate } from "react-router-dom";
import FocusTrap from "focus-trap-react";
import myTextPopUp from "../../../helpers/textPopUp";
import BasicButton from "../../../components/buttons/BasicButton";

function Room6Level1() {


    const navigate = useNavigate();
    const handleClick = (): void => {
        navigate("/chapitre2/room6/level2");
    };

    return (
        <FocusTrap>
            <div className="background-room6">
                <div className="pop-up-room6">
                    <div className="message-room6" role="alertdialog">
                        <p>{myTextPopUp.room6[0].p1}</p>
                        <p>{myTextPopUp.room6[0].p2}</p>
                        <p>{myTextPopUp.room6[0].p3}</p>
                    </div>
                    <BasicButton text={myTextPopUp.room6[0].button} styleB={{
                        backgroundColor: "#055C5C",
                        color: "#fff",
                        border: "none",
                        padding: "15px 50px",
                        borderRadius: "50px",
                        fontSize: "3rem"
                    }}
                        click={handleClick} />
                </div>
            </div>
        </FocusTrap>
    )
}

export default Room6Level1
