import React, { ReactElement, useEffect } from "react";
import i18n, { setLanguage } from "../i18n";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { selectChap } from '../slices/chapitresSlice';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import path from "../assets/images/room3/Path.png";
import { useTranslation } from 'react-i18next';

const Home = (): ReactElement => {

  const { t } = useTranslation();

  const changeLanguageApp = (lng: string) => {
    window.location.reload()
    setLanguage(lng); // Enregistre la langue sélectionnée
    i18n.changeLanguage(lng);
  };

  useEffect(() => {

  }, [changeLanguageApp]);


  const welcomeText = {
    title: t("general.welcome.title"),
    firstParagraph: t("general.welcome.welcome_motivpark.first_paragraph"),
    secondParagraph: t("general.welcome.welcome_motivpark.second_paragraph"),
    thirdParagraph: t("general.welcome.welcome_motivpark.third_paragraph"),
    fourthParagraph: t("general.welcome.welcome_motivpark.fourth_paragraph"),
    fifthParagraph: t("general.welcome.welcome_motivpark.fifth_paragraph"),
  }
  const roomTitle = {
    room1: t("general.room_title.room_title_one"),
    room2: t("general.room_title.room_title_two"),
    room3: t("general.room_title.room_title_three"),
    room4: t("general.room_title.room_title_four"),
  }

  const chapterUnLock = useSelector(selectChap);
  return (
    <div className="home">
      <div>
        <button className="changeLng" onClick={() => changeLanguageApp("fr")}>FR</button>
        <button className="changeLng" onClick={() => changeLanguageApp("en")}>EN</button>
      </div>
      <div className="heading">
        <h1 className="size1">{welcomeText.title}</h1>
        <h2 className="subtitle size4">{welcomeText.firstParagraph}</h2>
        <h2 className="text size4">{welcomeText.secondParagraph}</h2>
        <h3 className="subtitle size4">{welcomeText.thirdParagraph}</h3>
        <h3 className="subtitle size4">{welcomeText.fourthParagraph}</h3>
        <h3 className="subtitle size4">{welcomeText.fifthParagraph}</h3>
      </div>

      <div className="flex">
        <Link to="/chapitre1/room1/level1" className="link size3" id="room1">
          1. {roomTitle.room1}
        </Link>
        <Link to={chapterUnLock.chap2 ? "/chapitre2/room5/level1" : ""} className="link size3" id="room2">
          2. {roomTitle.room2}
          {!chapterUnLock.chap2 && <FontAwesomeIcon icon={faLock} />}
        </Link>
        <Link to={chapterUnLock.chap3 ? "/chapitre3/room8/level1" : ""} className="link size3" id="room3">
          3. {roomTitle.room3}
          {!chapterUnLock.chap3 && <FontAwesomeIcon icon={faLock} />}
        </Link>
        <Link to={chapterUnLock.chap4 ? "/chapitre4/room9/level1" : ""} className="link size3" id="room4">
          4. {roomTitle.room4}
          {!chapterUnLock.chap4 && <FontAwesomeIcon icon={faLock} />}
        </Link>
        {/* <Link to={chapterUnLock.chap5 ?"/chapitre4/room9/level1": ""} className="link size3" id="room5">
          5. La rééducation orthophonique et kinésithérapique
          {!chapterUnLock.chap5 && <FontAwesomeIcon icon={faLock} />}
        </Link> */}
      </div>
      <div className="containerPath" >
        <img src={path} tabIndex={-1} alt="" />
      </div>
    </div>
  );
};
export default Home;
