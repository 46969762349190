import React, { } from "react";
import { useLocation } from "react-router-dom";
import BodyRoom from "../../../components/animated/BodyRoom";
import Gelule from "../../../assets/images/room5/Gellules_Medicaments_Enzymes/Gelule01_Small.png";
import Dopamine from "../../../assets/images/room2/Tableau_Dopamine/Icon_Dopamine01_Ingame.png";
import myTextPopUp from "../../../helpers/textPopUp";

// Position gélules digestif de départ et 1
// Position dopamine digestif 2 et 3

const pathJson = [
    { "x": 1050, "y": 230, "width": 28, "height": 13 },
    { "x": 970, "y": 240, "width": 28, "height": 13 },
    {"x": 980, "y": 830, "width": 28, "height": 13 },
    { "x": 1000, "y": 920, "width": 28, "height": 13 },
    { "x": 890, "y": 675, "width": 48, "height": 48 },
    { "x": 840, "y": 250, "width": 60, "height": 60 },
]

function Room5level2() {
    const location = useLocation();
    return <BodyRoom
        pathJson={pathJson}
        pathGelule={Gelule}
        pathObj={Dopamine}
        nextPage="/chapitre2/room5/level2.2"
        textBody={[myTextPopUp.room5[9].content,
        myTextPopUp.room5[10].content,
        myTextPopUp.room5[11].content
        ]}
        nameMed={location.state.choiceMed }/>;
}

export default Room5level2;
