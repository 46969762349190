import { configureStore } from "@reduxjs/toolkit";
import chapterReducer from "./chapitresSlice";
import parcheminReducer from "./parcheminSlice";
import { loadState } from "./localStorage";

export const store = configureStore({
  reducer: {
    chapters: chapterReducer,
    parchemins: parcheminReducer,
  },
  preloadedState: loadState(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
