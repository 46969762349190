import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import FocusTrap from "focus-trap-react";
import Prescription from "../../components/room9/Prescription";
import OptionPopUp from "../../components/room9/OptionPopUp";
import GoldenRules from "../../components/room9/GoldenRules";
import BasicButton from "../../components/buttons/BasicButton";
import BasicPopUp from "../../components/popups/BasicPopUp";
import textPopUp from "../../helpers/textPopUp";

function OptionsPrescriptions() {

    const navigate = useNavigate();

    // This number is used to determine which pop-up to show
    const [viewIndex, setViewIndex] = useState<number>(0);
    const [optionClicked, setOptionClicked] = useState<boolean>(false);
    const [vacation, setVacation] = useState<string | null>("");

    // Going to the next pop-up
    const switchView = () => {
        setViewIndex(viewIndex + 1);
    };

    const checkOptionClicked = (clicked: boolean) => {
        setOptionClicked(clicked);
    };

    const createOptions = () => {
        const options: JSX.Element[] = [];
        for (let i = 1; i < 6; i += 1) {
            options.push(
                <OptionPopUp
                    key={i}
                    imageNumber={i}
                    click={() => {
                        checkOptionClicked(!optionClicked);
                    }}
                    clickable={!optionClicked}
                    validate={switchView}
                />
            );
        }
        return options;
    }

    useEffect(() => {
        if (viewIndex === 2) {
            navigate("/chapitre4/room9/level3");
        }
    }, [viewIndex, navigate]);

    useEffect(() => {
       // get "_vacation" from local storage
        const option = localStorage.getItem("_vacation");
        setVacation(option);
    }, []);

    return (
        <FocusTrap>
            <div className="container-vacation" id="hotel">
                {
                    viewIndex === 0 && (
                        <div className="prescription">
                            <BasicPopUp
                                title={vacation === "elephants" ? textPopUp.room9[22].title : textPopUp.room9[20].title}
                            />
                            <div
                                className="options-list"
                            >
                                <Prescription />
                                <div>
                                    {createOptions()}
                                </div>
                            </div>
                        </div>
                    )
                }

                {
                    viewIndex === 1 && (
                        <div className="golden-rules" >
                            <GoldenRules />
                            <BasicButton
                                click={switchView}
                                icon
                                arrowBlack
                                styleB={{
                                    backgroundColor: "white",
                                }}
                            />
                        </div>
                    )
                }
            </div>
        </FocusTrap>
    )
}

export default OptionsPrescriptions;
