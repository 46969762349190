import { useEffect, useRef } from "react";

// Return position of an object contained in json file relative to screen size
export const getPosition = (w_ecran: number, y_ecran: number, pathJson: any): Array<number> => {
  return [(pathJson.x * 100 / w_ecran), (pathJson.y * 100 / y_ecran)];
};

// Returns a percentage increase or decrease in x,y of an object relative to the screen size of the background
export const getPourcentObj = (w_ecran: number, y_ecran: number, pathJsonBack: any): Array<number> => {
  return getPourcent(w_ecran, y_ecran, pathJsonBack.width, pathJsonBack.height);
}

// Returns a percentage increase or decrease in x,y of a screen relative to the reference screen
export const getPourcent = (w_ecran: number, y_ecran: number, w_ref: number, y_ref: number): Array<number> => {
  return [(w_ecran / w_ref) * 100, (y_ecran / y_ref) * 100];
}

// Returns the size of an object in a screen relative to the reference screen
export const sizeObj = (w_ecran: number, y_ecran: number, pathJsonBack: any, pathJsonObj: any): Array<number> => {
  const percent = getPourcentObj(w_ecran, y_ecran, pathJsonBack);
  return [(percent[0] / 100) * pathJsonObj.width, (percent[1] / 100) * pathJsonObj.height]
}

export const getSize = (w_ecran: number, y_ecran: number, w_ref: number, y_ref: number, w_obj: number, y_obj: number): Array<number> => {
  const percent = getPourcent(w_ecran, y_ecran, w_ref, y_ref);
  return [(percent[0] / 100) * w_obj, (percent[1] / 100) * y_obj];
}

export function usePrevious<T>(value: T): T {
  const ref: any = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

/**
 * Use delay(n).then(() => { ... })
 * @param n number of ms
 * @returns delay in ms.
 */
export function delay(n: number): Promise<void> {
  const ms = n || 2000;
  return new Promise(done => {
    setTimeout(() => {
      done();
    }, ms);
  });
}

