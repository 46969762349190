import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import FocusTrap from "focus-trap-react";
import { useSelector, useDispatch } from 'react-redux';
import myTextPopUp from "../../../helpers/textPopUp";
import Paracetamol from "../../../assets/images/room5/Paracetamol.png";
import box from "../../../assets/images/room5/Box.png"
import Document_Hide from "../../../assets/images/room5/Document_Hide.png"
import PopUpWithButton from "../../../components/popups/PopUpWithButton";
import ScreenSize from "../../../utils/ScreenSize";
import { getPosition, getPourcentObj, sizeObj } from "../../../utils/funcUtils";
import { placements, styleElement } from "../../../utils/utilsPlacement";
import { TypeObject } from "../../../utils/typesUtils";
import { selectParchemin, changeTab } from "../../../slices/parcheminSlice";
import BasicButton from "../../../components/buttons/BasicButton";

const parse = require('html-react-parser');

type TypeObjectMedic = {
  nameMedic: string,
  x: number,
  y: number,
  width: number,
  height: number,
  selected: boolean,
}
const pathJsonBack = { "width": 1920, "height": 1080 };

function Room5level1() {
  const screenW: number = ScreenSize().width;
  const screenH: number = ScreenSize().height;

  const { t } = useTranslation();

  const [pathJson, setPathJson] = useState<Array<TypeObjectMedic>>([
    { "nameMedic": "Paracétamol", "x": 605, "y": 805, "width": 80, "height": 125, "selected": false },
    { "nameMedic": "Lévodopa", "x": 750, "y": 805, "width": 80, "height": 125, "selected": false },
    { "nameMedic": "Dopaminergique", "x": 895, "y": 805, "width": 80, "height": 125, "selected": false },
    { "nameMedic": "Paracétamol", "x": 1040, "y": 805, "width": 80, "height": 125, "selected": false },
    { "nameMedic": "Inhibiteur", "x": 1180, "y": 805, "width": 80, "height": 125, "selected": false },
    { "nameMedic": "Paracétamol", "x": 1320, "y": 805, "width": 80, "height": 125, "selected": false },
  ]);
  const [pourcent, setPourcent] = useState<Array<number>>(getPourcentObj(screenW, screenH, pathJsonBack));
  const [placementObj, setPlacementObj] = useState<Array<TypeObject>>(placements(screenW, screenH, pathJson, pathJsonBack));
  const style = styleElement(placementObj, pourcent);

  const [popUp1, setPopUp1] = useState<boolean>(true);

  const [popUp2, setPopUp2] = useState<boolean>(false);

  const [popUpFinal, setPopUpFinal] = useState<boolean>(false);

  const [med, setmed] = useState<boolean>(false);
  const [popParacetamol, setPopParacetamol] = useState<boolean>(false);
  const [popLevodopa, setPopLevodopa] = useState<boolean>(false);
  const [popDopaminergiques, setPopDopaminergiques] = useState<boolean>(false);
  const [popInhibiteurs, setPopInhibiteurs] = useState<boolean>(false);
  const [count, setcount] = useState<number>(0);
  const navigate = useNavigate();
  const parcheminTab = useSelector(selectParchemin);
  const dispatch = useDispatch();

  const updateParcheminsTab = (nameMed: string) => {
    const newarr: string[] = [...parcheminTab.parcheminTab];
    newarr[count] = nameMed;
    dispatch(changeTab(newarr));
  }

  const findFirstMed = t("room_five.level_one.first_drug.content");
  const findSecondMed = t("room_five.level_one.second_drug.content");
  const findThirdMed = t("room_five.level_one.third_drug.content");
  const errorMssg = t("room_five.level_one.error");

  const getTextPopUpMedFound = (elt: string): string => {
    switch (count) {
      case 1:
        return `${findFirstMed} : ${elt}`;
      case 2:
        return `${findSecondMed} : ${elt}`;
      case 3:
        return `${findThirdMed} : ${elt}`;
      default:
        return errorMssg;
    }
  }

  const handleClick = (e: React.MouseEvent<HTMLInputElement>, elt: TypeObjectMedic, index: number): void => {
    if (count < 3) {
      if (!elt.selected) {
        const arr: TypeObjectMedic[] = [...pathJson];
        arr[index].selected = true;
        setPathJson(arr);
        if (elt.nameMedic !== "Paracétamol") setcount(count + 1);
        if (elt.nameMedic === "Paracétamol") setPopParacetamol(!popParacetamol);
        else if (elt.nameMedic === "Lévodopa") {
          setPopLevodopa(!popLevodopa);
          updateParcheminsTab("Lévodopa");
        }
        else if (elt.nameMedic === "Dopaminergique") {
          setPopDopaminergiques(!popDopaminergiques);
          updateParcheminsTab("Dopaminergique");
        }
        else if (elt.nameMedic === "Inhibiteur") {
          setPopInhibiteurs(!popInhibiteurs);
          updateParcheminsTab("Inhibiteur");
        }

      }
    }
  };

  // Change the pourcent if a parameter change
  // Change size objects if a parameter change
  // Change position objects if a parameter change
  useEffect(() => {
    setPourcent(getPourcentObj(screenW, screenH, pathJsonBack));
    setPlacementObj(current =>
      current.map((obj, index) => {
        return { ...obj, size: sizeObj(screenW, screenH, pathJsonBack, pathJson[index]) };
      })
    );
    setPlacementObj(current =>
      current.map((obj, index) => {
        return { ...obj, position: getPosition(screenW, screenH, pathJson[index]) };
      })
    );
  }, [screenW, screenH, pathJson]);


  const title = t("room_five.level_one.title");
  const nextBtn = t("room_five.level_one.btn_next");

  return (
    <FocusTrap>
      <div className="back_level_1_room_5">

        {count > 0 &&
          (
            <div className="inventory">
              {
                [...Array(count)].map((elt, i) => (

                  <img style={{ transform: 'rotate(35deg)' }} key={uuidv4()} src={Document_Hide} aria-hidden alt={`document ${i + 1}`} className={`imgdoc${i + 1}`} />

                ))
              }
            </div>
          )
        }

        {popUp1 &&
          <PopUpWithButton
            title={title}
            contents={myTextPopUp.room5[0].contents}
            button={myTextPopUp.room5[0].button}
            click={(e) => {
              setPopUp1(false);
              setPopUp2(true);
            }}
          />
        }
        {popUp2 &&
          <PopUpWithButton
            contents={myTextPopUp.room5[1].contents}
            button={myTextPopUp.room5[1].button}
            click={(e) => {
              setPopUp2(false);
              setmed(true);
            }} />
        }

        {popUpFinal &&
          <FocusTrap
            focusTrapOptions={{
              initialFocus: '.popup-button',
              escapeDeactivates: false,
            }}>
            <div className="popup-button"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: "transparent"
              }}
              role="alertdialog"
              aria-modal="true"
            >
              <div className="container-text"
                style={{
                  background: "rgba(255,255,255, 0.5) 0% 0% no-repeat padding-box",
                  border: "3px solid rgb(255,255,255, 1)",
                  textAlign: "center",
                  backdropFilter: "blur(50px)",
                  boxShadow: "2px 2px 3px rgba(0, 0, 0, 0.6)",
                  color: "black",
                  marginBottom: "15px",
                  padding: "4% 5% 4% 5%",
                  width: "120%",
                  justifyContent: "center",
                }}
                role="group"
              >
                <h1
                  style={{
                    margin: 0,
                    padding: 0,
                    fontSize: "2.5rem",
                    fontWeight: "bold",
                  }}
                >{myTextPopUp.room5[22].title}</h1>
                <div
                  style={{
                    position: "relative",
                    width: "70%",
                    margin: '0 auto',
                  }}
                >
                  <ul
                    style={{
                      fontSize: "1.9rem",
                      textAlign: "left",
                    }}
                  >
                    {
                      myTextPopUp.room5[22].list && (
                        <>
                          <li>{parse(myTextPopUp.room5[22].list[0])}</li>
                          <li>{parse(myTextPopUp.room5[22].list[1])}</li>
                          <li>{parse(myTextPopUp.room5[22].list[2])}</li>
                        </>
                      )
                    }
                  </ul>
                </div>
                <p
                  style={{
                    fontSize: "2rem",
                    fontWeight: "bold",
                  }}
                >
                  {myTextPopUp.room5[22].bottomText}
                </p>
              </div>
              <BasicButton
                text="Continuer"
                click={(e) => {
                  setPopUpFinal(false);
                  navigate("/chapitre2/room5/levelParchemin")
                }} />
            </div>
          </FocusTrap>
        }
        {med &&
          <>
            {pathJson.map((elt, i) => {
              return !elt.selected && <input key={uuidv4()}
                type="button"
                className="medicaments"
                tabIndex={0}
                aria-label={`Box${i + 1}`}
                style={style[i]}
                onClick={(e) => handleClick(e, elt, i)} />
            })}
          </>
        }

        {popParacetamol && (

          <div className="pop_up_level_1_room_5">
            <div className="imagemed">
              <div className="container-img">
                <img src={box} alt="boite" />
              </div>
              <div className="container-img">
                <img src={Paracetamol} alt="boite Paracetamol" />
              </div>
            </div>
            <PopUpWithButton
              content={myTextPopUp.room5[2].content}
              button={myTextPopUp.room5[2].button}
              click={(e) => {
                setPopParacetamol(!popParacetamol);
              }}
            />
          </div>
        )}

        {
          popLevodopa &&
          <div className="pop_up_level_1_room_5">
            <div className="imagemed">
              <div className="container-img">
                <img src={box} alt="boite" />
              </div>
              <div className="container-img">
                <img src={Document_Hide} alt="Document" className="document" />
              </div>
            </div>
            <PopUpWithButton
              content={getTextPopUpMedFound(myTextPopUp.room5[3].title)}
              button={(count < 3) ? myTextPopUp.room5[3].button : nextBtn}
              click={(e) => {
                if (count === 3) navigate("/chapitre2/room5/levelParchemin");
                setPopLevodopa(!popLevodopa);
              }}
            />
          </div>
        }

        {
          popDopaminergiques &&
          <div className="pop_up_level_1_room_5">
            <div className="imagemed">
              <div className="container-img">
                <img src={box} alt="boite" />
              </div>
              <div className="container-img">
                <img src={Document_Hide} alt="Document" className="document" />
              </div>
            </div>
            <PopUpWithButton
              content={getTextPopUpMedFound(myTextPopUp.room5[4].title)}
              button={(count < 3) ? myTextPopUp.room5[4].button : nextBtn}
              click={(e) => {
                if (count === 3) navigate("/chapitre2/room5/levelParchemin");
                setPopDopaminergiques(!popDopaminergiques);
              }}
            />
          </div>
        }

        {
          popInhibiteurs &&
          <div className="pop_up_level_1_room_5">
            <div className="imagemed">
              <div className="container-img">
                <img src={box} alt="boite" />
              </div>
              <div className="container-img">
                <img src={Document_Hide} alt="Document" className="document" />
              </div>
            </div>
            <PopUpWithButton
              content={getTextPopUpMedFound(myTextPopUp.room5[5].title)}
              button={(count < 3) ? myTextPopUp.room5[5].button : nextBtn}
              click={(e) => {
                setPopInhibiteurs(!popInhibiteurs);
                if (count === 3) {
                  setPopInhibiteurs(false);
                  setPopUpFinal(true);
                };

              }}
            />
          </div>
        }
      </div>
    </FocusTrap>
  );
}

export default Room5level1
