import React, { useEffect, useState, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from "uuid";
import FocusTrap from "focus-trap-react";
import Icon_Dopamine from "../../../assets/images/room2/Tableau_Dopamine/Icon_Dopamine01_Ingame.png";
import Arrow from "../../../assets/images/room2/Tableau_Dopamine/Arrow 9 1.png";
import Arrow2 from "../../../assets/images/room2/Tableau_Dopamine/Arrow.png";
import JonctionNeurone from "../../../components/animated/JonctionNeurone";
import DraggableGeneric from "../../../components/animated/DraggableGeneric";
import { TypePosDraggable } from "../../../types/Draggable-type";
import ScreenSize from "../../../utils/ScreenSize";
import { stylePopUp } from "../../../helpers/stylesComponents"
import { sizeObj } from "../../../utils/funcUtils";


export default function Level2() {
    const { t } = useTranslation();
    const screenW: number = ScreenSize().width;
    const screenH: number = ScreenSize().height;
    const navigate = useNavigate();
    const [drag, setDrag] = useState<boolean>(false);
    const [pinkMessage, setPinkMessage] = useState<boolean>(true);
    const arrowRef = useRef<HTMLDivElement>(null);

    const [destinations, setDestinations] = useState<TypePosDraggable[]>([
        { x: 57.9, y: 35.4, occuped: false },
        { x: 56.6, y: 40.4, occuped: false },
        { x: 56, y: 45.5, occuped: false },
        { x: 55.7, y: 50.6, occuped: false },
        { x: 56, y: 55.7, occuped: false },
        { x: 57.6, y: 60.9, occuped: false },
    ]);

    const [sizeDestination, setSizeDestination] = useState<number[]>(
        sizeObj(screenW, screenH, { width: 1920, height: 1080 }, { width: 100, height: 50 })
    );

    const start = ([
        { x: 39.5, y: 38 },
        { x: 40.5, y: 39.5 },
        { x: 40.5, y: 47.5 },
        { x: 41, y: 49 },
        { x: 39.5, y: 58 },
        { x: 39.8, y: 59.5 },
    ])

    useEffect(() => {
        if (!pinkMessage) {
            // const divMessage: any = document.querySelector("#text-2");
            // divMessage.classList.add("hide");
            const divMessage: any = document.querySelector("#pink-message");
            divMessage.classList.add("hide");
        }
    }, [pinkMessage]);

    useEffect(() => {
        setSizeDestination(sizeObj(screenW, screenH, { width: 1920, height: 1080 }, { width: 100, height: 50 }));
    }, [screenH, screenW])

    const handleClick = useCallback(() => {
        navigate("/chapitre1/room2/level2.2");
    }, [navigate])

    const [leftArrow, setLeftArrow] = useState<number>(20);

    const [compte, setCompte] = useState<number>(0);

    const dragElement = () => {
        setDrag(true);
        setPinkMessage(false);
    }

    const changeDestination = (destination: TypePosDraggable) => {
        const i = destinations.indexOf(destination);
        if (i > - 1 && !destinations[i].occuped) {
            const arr = [...destinations];
            arr[i].occuped = true;
            setDestinations(arr);
        }
    }

    const handleKeyDown = (e: any): void => {
        if (e.key === " " || e.key === "Enter") {
            handleClick();
        }
    }

    useEffect(() => {
        if (compte === 6) {
            setLeftArrow(70);
            if (arrowRef.current) {
                arrowRef.current.focus();
                arrowRef.current.addEventListener("click", handleClick);
                arrowRef.current.addEventListener("keydown", (e: KeyboardEventInit): void => {
                    if (e.key === "Enter") { handleClick(); }
                });
            }

        }
    }, [compte, handleClick]);


    const titleLevel2 = t("room_two.level_two.first_text");
    const subtitleLevel2 = t("room_two.level_two.sub_text");
    const secondText = t("room_two.level_two.second_text");

    return (
        <div className="room2-container">
            <FocusTrap
                focusTrapOptions={{
                    fallbackFocus: "#hidden-container",
                    allowOutsideClick: true,
                }}
            >
                <div
                    role="button"
                    tabIndex={0}
                    style={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        width: "100%",
                        height: "100vh",
                        fontSize: "0em"
                    }}
                    onKeyDown={handleKeyDown}
                    aria-labelledby="Les molécules de dopamine sortent du neurone émetteur et viennent se fixer sur les récepteurs du neurone récepteur pour transmettre le message nerveux. "
                >
                    <span id="hidden-container" tabIndex={-1}>{titleLevel2}<br/>{subtitleLevel2} </span>
                </div>
            </FocusTrap>
            <JonctionNeurone
                paragraphe={`${titleLevel2}<br/>${subtitleLevel2}`}
                elementJonction={
                    <>


                        {
                            [...Array(3)].map((x, i) =>
                                <div id={`dopamine-container${i + 1}`}
                                    key={uuidv4()}
                                    role="button"
                                    tabIndex={
                                        i === 0 ? 0 : -1
                                    }
                                    onClick={dragElement}
                                    onKeyDown={handleKeyDown}
                                    aria-label="Les molécules de dopamine traversent la jonction nerveuse"
                                    aria-hidden="true"
                                    style={{
                                        cursor: "pointer"
                                    }}
                                >
                                    <img src={Icon_Dopamine} className="dopamine" alt={`dopamine ${i + 1}`} />
                                    <img src={Icon_Dopamine} className="dopamine" alt={`dopamine ${i + 1}`} />
                                </div>)
                        }
                        <div
                            role="button"
                            aria-hidden="true"
                            ref={arrowRef}
                            tabIndex={0}
                            id="next-step"
                            style={{
                                cursor: "pointer"
                            }}
                        >
                            <img src={Arrow} id="arrowBis"
                                aria-label="niveau suivant"
                                style={{ left: `${leftArrow}%` }}
                                alt="fleche" />
                        </div>

                        <div className="bottom-container" id="pink-message">
                            <img
                                src={Arrow2}
                                alt=""
                                style={{
                                    position: "absolute",
                                    top: "65%",
                                    left: "45%",
                                    transform: "translate(-50%, -50%) scaleY(-1)",
                                    width: "13%",
                                    height: "auto",
                                }}
                            />
                            <p className="text-container size2" style={{ ...stylePopUp, border: "3px solid #EE5D87" }}>{secondText}</p>
                        </div>
                        {drag &&
                            <div
                                style={{
                                    zIndex: "3",
                                    cursor: "pointer"
                                }}
                                role="button"
                                tabIndex={0}
                                aria-hidden="true"
                            >
                                {
                                    start.map((elt, i) =>
                                        <DraggableGeneric
                                            dragConstraints={{ right: screenW / 2, left: -screenW / 3, top: -screenH / 3, bottom: screenH / 2 }}
                                            startX={elt.x}
                                            startY={elt.y}
                                            destinations={destinations}
                                            sizeDestinationX={sizeDestination[0]}
                                            sizeDestinationY={sizeDestination[1]}
                                            heightImg="4.3%"
                                            imagePath={Icon_Dopamine}
                                            onChangeDestination={(dest) => changeDestination(dest)}
                                            onFinish={() => setCompte(compte + 1)} />
                                    )
                                }



                            </div >
                        }
                    </>
                }
                useBackground
            />

        </div >
    );
}
