import React from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import PrescriptionImage from "../../assets/images/room9/TimeLapsLevopoda.png";
import PrescriptionImageEn from "../../assets/images/room9/TimeLapsLevopodaEn.png";
import DopamineIcon from "../../assets/images/room9/dopamine.png";
import AgonisteIcon from "../../assets/images/room9/agoniste_dopaminergique.png";
import DegradationIcon from "../../assets/images/room9/degradation_dopaminergiques.png";


export default function Prescription(): JSX.Element {
    const { t } = useTranslation();
    const getCurrentLanguage = i18next.language;

    // const screenH: number = ScreenSize().height;

    const drName = t("room_nine.level_two.options.doctor.name");
    const drSpeciality = t("room_nine.level_two.options.doctor.speciality");
    const prescription = t("room_nine.level_two.options.prescription_med");
    const legends = [
        t("room_nine.level_two.options.legends.one"),
        t("room_nine.level_two.options.legends.two"),
        t("room_nine.level_two.options.legends.three"),
        t("room_nine.level_two.options.legends.title")
    ]


    return (
        <div style={{ width: "36%", backgroundColor: "#fff", borderRadius: "50px", display: "flex", flexDirection: "column", justifyContent: "space-between", padding: "1.6rem 4rem", color: "#111111", marginLeft: "5rem"}}>
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <h3 style={{fontSize: "1.6rem"}}><span style={{fontSize: "2.7rem"}}>{drName}</span><br/>{drSpeciality}</h3>
                <span style={{fontSize: "1.8rem"}}>15/04/2022</span>
            </div>
            <div>
                <h2 style={{textAlign: "center"}}>{prescription}</h2>
                <img src={getCurrentLanguage === "fr" ? PrescriptionImage : PrescriptionImageEn} alt="Prescription médical" style={{width: "100%", padding: "0 3rem"}} />
            </div>
            <div>
                <span style={{fontSize: "1.8rem"}}>{legends[3]}</span>
                <ul style={{listStyle: "none", padding: "0"}}>
                    <li style={{display: "flex", alignItems: "center", gap: "1rem", marginBottom: "0.6rem"}}><img src={AgonisteIcon} alt="Prescription médical" style={{width: "3rem"}}/>: {legends[0]} </li>
                    <li style={{display: "flex", alignItems: "center", gap: "1rem", marginBottom: "0.6rem"}}><img src={DegradationIcon} alt="Prescription médical" style={{width: "3rem"}}/>: {legends[1]}</li>
                    <li style={{display: "flex", alignItems: "center", gap: "1rem", marginBottom: "0.6rem"}}><img src={DopamineIcon} alt="Prescription médical" style={{width: "3rem"}}/>: {legends[2]}</li>
                </ul>
            </div>
        </div>
    );
}
