import React, {useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';
import { motion, Variants } from "framer-motion";
import { useNavigate } from "react-router-dom";
import PortraitPicture  from "../../../assets/images/room1/PortraitPicture.png"
import Plante  from "../../../assets/images/room1/Plante.png"
import Medecin  from "../../../assets/images/room1/Medecin.png"
import ScreenSize from "../../../utils/ScreenSize";
import SALLE1ExportScriptTest from "../../../assets/images/room1/SALLE1_FullHD.json";
import { getPosition, getPourcentObj, sizeObj } from "../../../utils/funcUtils";
import { pathJsonPadAccess, placements, styleElement } from "../../../utils/utilsPlacement";
import { TypeObject } from "../../../utils/typesUtils";
import BasicButton from "../../../components/buttons/BasicButton";


const pathJson : Array<object> = pathJsonPadAccess;

function PadAccess (props : any) : JSX.Element{
    const { t } = useTranslation();
    const screenW : number = ScreenSize().width;
    const screenH : number = ScreenSize().height;
    const navigate = useNavigate();
    const pathJsonBack : any = SALLE1ExportScriptTest.skins.default.Salle01_Background.Salle01_Background;
    const [redirection, setRedirection] = useState<boolean>(false);
    const [pourcent, setPourcent] =useState<Array<number>>(getPourcentObj(screenW, screenH, pathJsonBack));
    const [translatePortrait, setTranslatePortrait] = useState<boolean>(false);
    const [placementObj, setPlacementObj] = useState<Array<TypeObject>>(placements(screenW, screenH, pathJson, pathJsonBack));

    // Position and size objects

    const style = styleElement(placementObj, pourcent);

    // Translation animation for portrait object
    const variantsPortrait : Variants = {
        translation : {x:`-${placementObj[0].size[0]}px`}
    }

    // Change the pourcent if a parameter change
    // Change size objects if a parameter change
    // Change position objects if a parameter change
    useEffect(() => {
        setPourcent(getPourcentObj(screenW, screenH, pathJsonBack));
        setPlacementObj(current =>
            current.map((obj, index) => {
                return {...obj, size : sizeObj(screenW, screenH, pathJsonBack, pathJson[index])};
            })
        );
        setPlacementObj(current =>
            current.map((obj, index) => {
                return {...obj, position : getPosition(screenW, screenH, pathJson[index])};
            })
        );
    }, [screenW, screenH, pathJsonBack]);

    useEffect(() => {
        if(!translatePortrait){
            setTimeout(() => {
                setTranslatePortrait(true);
            }, 1000);
        }

    }, [translatePortrait])


    useEffect(() => {
        redirection && navigate("/chapitre1/room1/level5");
      }, [navigate, redirection])

    // Function for redirection
    const handleClickRedirection = () => {
        setRedirection(true)
    }

    const nextRoom: string = t("room_one.level_four.title");

    return (
        <div className="container-padAccess bg-room1">
            <motion.div
                id="portrait"
                className="absolute-contain"
                initial={false}
                style={style[0]}
                animate={translatePortrait ? "translation" : false}
                variants={variantsPortrait}
            >

                 <img src={PortraitPicture} alt=""/>
            </motion.div>

            <img className="absolute-contain plante" src={Plante} style={style[1]} alt="" />
            <img className="absolute-contain" src={Medecin} style={style[2]} alt="" />

            {translatePortrait &&
                <BasicButton text={nextRoom}
                click={handleClickRedirection}/>
            }
        </div>
    );
}


export default PadAccess;